
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import SubscribeButtonIOS from "../subscribe/SubscribeButtonIOS";

export default function IosPaywall({navigation, creatorName, currencySymbol, priceAmount, creatorUid, price}) {
    const [isIAR, setIsIAR] = useState(true);

    useEffect(() => {

        const getFFs = async() => {
            try {
                // initialize Firestore
                const db = getFirestore();

                // specify the collection and document ID
                const docRef = doc(db, "featureFlags", "is_IAR");

                // attach snapshot listener
                onSnapshot(docRef, (doc) => {
                if (doc.exists()) {
                    console.log("Document data:", doc.data());
                    const data = doc.data()
                    setIsIAR(data.active)
                } else {
                    console.log("No such document!");
                }
                }, (error) => {
                console.log("Error getting document:", error);
                });
            } catch (error) {
                console.error(error)
            }
        }

        getFFs();
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Image
                    style={{ width: 128, height: 128 }}
                    source={require('../../assets/lock.png')}
                /> 
            </View>
            <Title style={styles.content}>
                Premium content
            </Title>
            <SubscribeButtonIOS 
                navigation={navigation}
                creatorName={creatorName}
                // currencySymbol={currencySymbol}
                // priceAmount={priceAmount}
                price={price}
                creatorUid={creatorUid}
                isIAR={isIAR}
            />
        </View>
        // <View>
        //     {isIAR ? (
        //         <View></View>
        //     ):(
        //         <View style={styles.container}>
        //             <View style={styles.content}>
        //                 <Image
        //                     style={{ width: 128, height: 128, opacity: "50%" }}
        //                     source={require('../../assets/lock.png')}
        //                 /> 
        //             </View>
        //             <Title style={styles.content}>
        //                 Premium content
        //             </Title>
        //         </View>
        //     )}
        // </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        backgroundColor: 'white',
        margin: 10,
        padding: 10
    },
    content: {
        marginBottom: 15
    }
})