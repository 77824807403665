import React from 'react';
import { StyleSheet, Switch, View } from 'react-native';
import { Text, Title } from 'react-native-paper';

export default function CreateChallengePaid({ paid, onTogglePaid}) {
    return (
        <View>
            <Title style={styles.title}>Paid challenge?</Title>
            <Text style={styles.explainerText}>Toggle on to make challenge viewable only to users with subscription to you</Text>
            <Switch value={paid} onValueChange={onTogglePaid} />
        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        marginBottom: 30
    },
    explainerText: {
        marginTop: 5,
        marginBottom: 10,
        color: "#9e9e9e",
    },
});