import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from "react";
import NotFound from '../components/404';
import LandingScreen from '../components/auth/Landing';
import LoginScreen from '../components/auth/Login';
import RegisterScreen from '../components/auth/Register';
import ChallengeScreen from '../components/challenges/Challenge';
import CreateChallenge from '../components/challenges/CreateChallenge/CreateChallenge';
import Leaderboard from '../components/challenges/Leaderboard';
import ExercisesScreen from '../components/exercises/Exercises';
import EditExerciseScreen from '../components/exercises/EditExercise';
import SessionScreen from '../components/exercises/Session';
import Gdpr from '../components/legals/gdpr';
import Legal from '../components/legals/legals';
import Privacy from '../components/legals/privacy';
import Terms from '../components/legals/terms';
import MainScreen from '../components/Main';
import EditProfile from '../components/main/EditProfile';
import CreateOndemandWorkoutScreen from '../components/Ondemand/CreateOnDemand';
import OnDemandWorkoutScreen from '../components/Ondemand/OnDemandWorkout';
import CreateStripeAccount from '../components/stripe/CreateStripeAccount';
import CreateStripeAccSuccess from '../components/stripe/CreateStripeAccSuccess';
import CreateStripeAccWebView from '../components/stripe/CreateStripeAccWebView';
import Subscribe from '../components/subscribe/subscribe';
import CreateWorkout from '../components/workouts/CreateWorkout/CreateWorkout';
import WorkoutScreen from '../components/workouts/Workout';
import WorkoutCommentsScreen from '../components/workouts/WorkoutComments';
import DiscoverCreators from '../components/CreatorDirectory/DiscoverCreators';
import IndividualCreators from '../components/CreatorDirectory/IndividualCreator';
import CategoryCreators from '../components/CreatorDirectory/CategoryCreators';
import Blog from '../components/Blog/Blog'
import Blogs from '../components/Blog/Blogs';
import NewCreatorDirectory from '../components/CreatorDirectory/NewCreatorDirectory';
import DeleteAccount from '../components/profile/DeleteAccount';
import IOSSubscribeScreen from '../components/subscribe/IOSSubscribeScreen.native';
import BuyCoinsIOS from '../components/InAppPurchasesComponents/BuyCoinsIOS.native';
import WhyDoIHaveToBuyCoins from '../components/InAppPurchasesComponents/WhyDoIHaveToBuyCoins.native';

const Stack = createNativeStackNavigator();

const screenOptionStyle = {
  headerStyle: {
    backgroundColor: "#9AC4F8",
  },
  headerTintColor: "white",
  headerBackTitle: "Back",
};

const MainStackNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="Main">
      <Stack.Screen name="Main" component={MainScreen} uid={props.uid} options={{ headerShown: false }}  />
      <Stack.Screen name="Landing" component={LandingScreen} options={{ headerShown: false }} navigation={props.navigation} />
      <Stack.Screen name="Register" component={RegisterScreen} navigation={props.navigation} />
      <Stack.Screen name="Login" component={LoginScreen} navigation={props.navigation} />
      <Stack.Screen name="Edit Profile" component={EditProfile} navigation={props.navigation} />
      <Stack.Screen name="Delete Account" component={DeleteAccount} navigation={props.navigation} />
      <Stack.Screen name="Payment" component={CreateStripeAccount} navigation={props.navigation} />
      <Stack.Screen name="Create Stripe" component={CreateStripeAccWebView} navigation={props.navigation} />
      <Stack.Screen name="StripeSuccess" component={CreateStripeAccSuccess} navigation={props.navigation} />
      <Stack.Screen name="Subscribe" component={Subscribe} navigation={props.navigation} />
      {/* <Stack.Screen name="SubscribeIOS" component={IOSSubscribeScreen} navigation={props.navigation} options={{ headerShown: false }} /> */}
      <Stack.Screen name="NotFound" component={NotFound} options={{ headerShown: false }} />
      <Stack.Screen
        name="IOSSubscribe"
        component={IOSSubscribeNavigator}
        options={{ headerShown: false }}
      />

  </Stack.Navigator>
  );
};

const ContentStackNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="Challenge">
      <Stack.Screen name="Challenge" component={ChallengeScreen} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="New Challenge" component={CreateChallenge} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="Leaderboard" component={Leaderboard} navigation={props.navigation} />
      <Stack.Screen name="New Workout" component={CreateWorkout} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="Workout" component={WorkoutScreen} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="Workout Comments" component={WorkoutCommentsScreen} navigation={props.navigation} />
      <Stack.Screen name="New Ondemand" component={CreateOndemandWorkoutScreen} navigation={props.navigation} options={{ headerShown: false }}/>
      <Stack.Screen name="OnDemandWorkout" component={OnDemandWorkoutScreen} navigation={props.navigation} options={{ headerShown: false }} />

      <Stack.Screen name="Exercises" component={ExercisesScreen} navigation={props.navigation} />
      <Stack.Screen name="Edit Exercise" component={EditExerciseScreen} navigation={props.navigation} />
      <Stack.Screen name="Session" component={SessionScreen} navigation={props.navigation} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

const IOSSubscribeNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="SubscribeIOSScreen">
      <Stack.Screen name="SubscribeIOSScreen" component={IOSSubscribeScreen} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="BuyCoinsIOS" component={BuyCoinsIOS} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="WhyDoIHaveToBuyCoins" component={WhyDoIHaveToBuyCoins} navigation={props.navigation} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};



const LegalStackNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="legals">
      <Stack.Screen name="legals" component={Legal} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="privacy" component={Privacy} navigation={props.navigation}  />
      <Stack.Screen name="terms" component={Terms} navigation={props.navigation}  />
      <Stack.Screen name="gdpr" component={Gdpr} navigation={props.navigation}  />
    </Stack.Navigator>
  );
};

const DiscoverCreatorStackNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="DiscoverInstructors">
      <Stack.Screen name="DiscoverInstructors" component={DiscoverCreators} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="CategoryInstructors" component={CategoryCreators} navigation={props.navigation} options={{ headerShown: false }}   />
      <Stack.Screen name="IndividualInstructors" component={IndividualCreators} navigation={props.navigation} options={{ headerShown: false }}  />
      <Stack.Screen name="NewInstructor" component={NewCreatorDirectory} navigation={props.navigation} options={{ headerShown: false }}  />
    </Stack.Navigator>
  );
};

const BlogStackNavigator = (props) => {
  return (
    <Stack.Navigator initialRouteName="Blogs">
      <Stack.Screen name="Blogs" component={Blogs} navigation={props.navigation} options={{ headerShown: false }} />
      <Stack.Screen name="Blog" component={Blog} navigation={props.navigation} options={{ headerShown: false }} />
    </Stack.Navigator>
  );
};

export { MainStackNavigator, ContentStackNavigator, LegalStackNavigator, DiscoverCreatorStackNavigator, BlogStackNavigator, IOSSubscribeNavigator };
