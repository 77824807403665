import React from 'react'
import{ View, StyleSheet } from 'react-native'
import { Button  } from 'react-native-paper';


export default function Landing({ navigation }) {
    return (
        <View style={styles.container}>
            <View style={styles.landing}>
                <Button
                    style={styles.btn} 
                    mode="contained"
                    onPress={() => navigation.navigate("Login")}
                >Login</Button>
                <Button 
                    style={styles.btn} 
                    mode="contained"
                    onPress={() => navigation.navigate("Register")}
                >Sign up</Button>
                <Button
                    style={styles.btn} 
                    icon="home"
                    mode="outlined"
                    onPress={() => 
                        navigation.navigate('Home', {
                            screen: 'Main',
                            params: {
                                screen: 'Feed',
                            },
                        })
                    }
                >Home</Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    landing: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    btn: {
        margin: 15,
    }

})
