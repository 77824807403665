
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Clipboard } from 'react-native';
import { IconButton } from 'react-native-paper';

export default function WebShare() {
    const [url, setUrl] = useState(null)
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const webUrl = window.location.href
        setUrl(webUrl)
    }, [])

    const copyToClipboard = () => {
        Clipboard.setString(url)
        onToggleCopied()
    }

    const onToggleCopied = () => setCopied(!copied);


    return (
        <View>
            {
                !copied ? (
                    <IconButton
                        icon="share"
                        size={30}
                        color="#008080"
                        onPress={copyToClipboard}
                    />
                ):(
                    <IconButton
                        icon="content-copy"
                        size={30}
                        color="#008080"
                        onPress={copyToClipboard}
                    />
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({

});