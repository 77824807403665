import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Text } from 'react-native-paper';

const SubscribeButtonIOS = ({navigation, creatorName, currencySymbol, priceAmount, creatorUid, isIAR, price}) => {
  const [subscribeCostCoins, setSubscribeCostCoins] = useState(0);

  useEffect(() => {

    calculateSubCostInCoins();

  }, [price])

    /**
   * Calculates the total coins needed to start a subscription
   * Adds 30% to cover cost of apple tearing us a new one
   * Adds 5% more to cover errors and some currency exchange stuff
   * This is disgusting a needs a major rework to factor in the cost 
   * of coins relative to currency exchange rates
   */
    const calculateSubCostInCoins = () => {

      if (price) {
        try {
          const subPrice = price.price;
          const plusAppleDoesntGiveAShtAboutDevelopersTax = subPrice * 1.35;
          const costInCoins = Math.round(plusAppleDoesntGiveAShtAboutDevelopersTax * 100);
          setSubscribeCostCoins(costInCoins);
        } catch (error) {
          console.error("Error getting price of sub");
        }
      }
    };
  
  return (
    <View style={styles.container}>
        <Text style={styles.title}>Subscribe to Premium</Text>
        {price &&  (
            isIAR ? (
              <Text style={styles.subscriptionAmount}>{subscribeCostCoins.toLocaleString()} Coins per month</Text>
            ) : (
                <Text style={styles.subscriptionAmount}>{price.currency_symbol}{price.price} per month</Text>
            )
        )}
        <Button
          mode="contained"
          onPress={() =>
            navigation.navigate("IOSSubscribe", {
              screen: "SubscribeIOSScreen",
              params: {
                creatorName,
                currencySymbol,
                priceAmount,
                creatorUid,
                isIAR,
                price,
              },
            })
          }
        >
          Subscribe Now
        </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center'
  },
  subscriptionAmount: {
    fontSize: 18,
    marginBottom: 20,
  },
});

export default SubscribeButtonIOS;
