import { useNavigation } from '@react-navigation/native'
import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import 'firebase/compat/firestore'
import React, { useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { ActivityIndicator, Snackbar, Text } from 'react-native-paper'
import { connect } from 'react-redux'
import OtherUserProfile from './OtherUserProfile'
import UserProfile from './UserProfile'

function Profile(props) {
    const navigation = useNavigation();
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [noUser, setNoUser] = useState(false);
    const [uid, setUid] = useState(null);
    const [userWorkouts, setUserWorkouts] = useState([])
    const [userOnDemandWorkouts, setUserOnDemandWorkouts] = useState([])
    const [usersChallenges, setUserChallenges] = useState([])
    const [usersOnDemandWorkouts, setUsersOnDemandWorkouts] = useState([])
    const [following, setFollowing] = useState(false)
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    useEffect(() => {
        setLoading(true)
        if (props.route.params) {
            if (props.route.params.uid === null) {
                navigation.navigate("Home", {
                    screen: 'Landing'
                })
            } else {
                determineProfileType(props.route.params, props.currentUser)
            }
        }
    }, [props.route, props.following, props.subscriptions, props.workouts, props.challenges, props.challengesJoined, props.workoutsUserFollowing, props.onDemandWorkouts])

    const determineProfileType = (params, currentUser) => {


        if (firebase.auth().currentUser) {
            setUid(firebase.auth().currentUser.uid)
            if (params.uid === firebase.auth().currentUser.uid) {
                setUserProfile(true)
                setLoading(false)
            } else {
                setUserProfile(false)
                setLoading(false)
            }
            
        } else {
            firebase.auth().onAuthStateChanged((user) => {
                if(user){
                    setUid(firebase.auth().currentUser.uid)
                    if (params.uid === firebase.auth().currentUser.uid) {
                        setUserProfile(true)
                        setLoading(false)
                    } else {
                        setUserProfile(false)
                        setLoading(false)
                    }
                } else {
                    console.log("no user yet")
                    setUserProfile(false)
                    setLoading(false)
                }
            })
        }
    }



    const generateWorkouts = (followingWorkouts, usersWorkouts) => {
        const concatedWorkouts = followingWorkouts.concat(usersWorkouts)
        setUserWorkouts(concatedWorkouts)
    }

    const generateChallenges = (followingChallenges, usersChallenges) => {
        const concatedChallenges = followingChallenges.concat(usersChallenges)
        setUserChallenges(concatedChallenges)
    }

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const onFollow = () => {
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })
        } else {
            firebase.firestore()
            .collection("following")
            .doc(firebase.auth().currentUser.uid)
            .collection("userFollowing")
            .doc(props.route.params.uid)
            .set({})
        }
    }
    const onUnfollow = () => {
        firebase.firestore()
        .collection("following")
        .doc(firebase.auth().currentUser.uid)
        .collection("userFollowing")
        .doc(props.route.params.uid)
        .delete()
    }
    const onLogout = () => {
        firebase.auth().signOut();
        navigation.navigate("Login")
    }

    const navigateWorkout = (id) => {
        navigation.navigate(
            'Content', {
                screen: 'Workout',
                params: {workoutId: id},
            }
        )
    }

    const navigateOnDemand = (id) => {
        navigation.navigate(
            'Content', {
                screen: 'OnDemandWorkout',
                params: {workoutId: id},
            }
        )
    }



    const navigateChallenge = (c) => {
        if (c.challenge_id) {
            navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.challenge_id},
                }
            )
        } else {
            navigation.navigate(
                'Content', {
                    screen: 'Challenge',
                    params: {challengeId: c.id},
                }
            )
        }
    }

    const navigateSubscribe = () => {
        if (!props.currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })            
        } else {
            navigation.navigate('Subscribe', {customer: firebase.auth().currentUser.uid, creator: user, creator_uid: props.route.params.uid})
        }
    }

    const getOtherUserData = () => {
        firebase.firestore()
        .collection("users")
        .doc(props.route.params.uid)
        .get()
        .then((snapshot) => {
            if (snapshot.exists) {
                setUser(snapshot.data())
            }
            else {
                setSnackbarVisible(true)
                setSnackbarMessage("User does not exist")
                setNoUser(true)
            }
        })

        firebase.firestore()
            .collection("challenges")
            .where("user_id", "==", props.route.params.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let challengesData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserChallenges(challengesData)
            })
        firebase.firestore()
            .collection("workouts")
            .where("user_id", "==", props.route.params.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let workoutData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserWorkouts(workoutData)
            })
        firebase.firestore()
            .collection("onDemandWorkout")
            .where("user_id", "==", props.route.params.uid)
            .where("public", "==", true)
            .orderBy("creation", "desc")
            .get()
            .then((snapshot) => {
                let workoutData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setUserOnDemandWorkouts(workoutData)
            })
    }

    return (
        <View style={styles.container}>
            <ScrollView style={styles.scrollContent}>
                { loading ? (
                    <View style={styles.userLoading}>
                        <ActivityIndicator size="large" color="#008080" />
                        <Text>Loading User info</Text>
                    </View>
                ): (
                    null
                )}

                {
                    userProfile && !loading ? (
                        <UserProfile navigation={props.navigation} uid={props.route.params.uid} />
                    ):(
                        <OtherUserProfile navigation={props.navigation} uid={props.route.params.uid} />
                    )
                }

                <Snackbar
                    visible={snackbarVisible}
                    onDismiss={onDismissSnackBar}
                    action={{
                    label: 'Undo',
                    onPress: () => {
                        // Do something
                    },
                    }}>
                    {snackbarMessage}
                </Snackbar>

            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    scrollContent: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    section: {
        marginBottom: 30
    },
    listCtn:{
        marginBottom: 20
    },
    title: {
        marginBottom: 15
    },
    userLoading: {
        textAlign: 'center'
    },
    editButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
        marginBottom: 15
    },
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    workoutsUserFollowing: store.userState.workoutsUserFollowing,
    challenges: store.userState.challenges,
    challengesJoined: store.userState.challengesJoined,
    subscriptions: store.userState.subscriptions,
    onDemandWorkouts: store.userState.onDemandWorkouts
})

export default connect(mapStateToProps, null)(Profile);