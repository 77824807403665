import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import NativeShare from './NativeShare'
import WebShare from './WebShare'

export default function Share({ params, message, title, image }) {
    const [platform, setPlatform] = useState(null)

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'web') {
                setPlatform('web')
            } else {
                setPlatform('native')
            }
        })();
    }, [])


    return (
        <View>
            {platform === 'native' ? (
                <NativeShare 
                    params={params}
                    title={title}
                    message={message}
                    image={image}
                />
            ) : null}

            {platform === 'web' ? (
                <WebShare 
                    params={params} 
                />
            ) : null}
        </View>
    )
}

