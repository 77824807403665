import { REVENUECAT_API_KEY_IOS } from "@env";
import * as Analytics from 'expo-firebase-analytics';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, increment, serverTimestamp, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform, View, StyleSheet, ScrollView } from 'react-native';
import { Button, Card, Snackbar, Text, Title } from 'react-native-paper';

// import Purchases, { PurchasesOffering } from 'react-native-purchases';

import Constants from 'expo-constants';
/**
 * Expo Go does not support react-native-purchases. Conditionally render
 * Fkn annoying.
 */
const isExpoGo = Constants.appOwnership === 'expo';
let Purchases;
const RC_API_KEY = REVENUECAT_API_KEY_IOS;
if (!isExpoGo) {
  Purchases = require('react-native-purchases').default;
}

// const RC_API_KEY = REVENUECAT_API_KEY_IOS;

const APIKeys = {
  apple: "your_revenuecat_apple_api_key",
  google: "your_revenuecat_google_api_key",
};


export default function BuyCoinsIOS(props) {
  const [currentOffering, setCurrentOffering] = useState(null);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isIAR, setIsIAR] = useState(true);

  useEffect(() => {
    (async() => {
      await Analytics.logEvent('[BuyCoinsIOS]: Viewed');
    })();

    const auth = getAuth();
    if (auth) {
      setCurrentUser(auth.currentUser)
    }

    if (props.route.params.isIAR) {
      setIsIAR(setIsIAR)
    }

    if (!isExpoGo) {
      fetchData()
    }

    // fetchData()
  }, []);

  /**
   * Get IAP products from RevenueCat
   */
  const fetchData = async () => {

    try {
      Purchases.setDebugLogsEnabled(true);
      if (Platform.OS === "ios") {
        await Purchases.configure({ apiKey: RC_API_KEY });
      } else {
        await Purchases.configure({ apiKey: APIKeys.google });
      }
      const offerings = await Purchases.getOfferings();
      setCurrentOffering(offerings.current);
      
    } catch (error) {
      console.error("await Purchases.setup(RC_API_KEY)", error);
      setError("Error fetch data error  ", error);
    }

  };

  const updateUserCoins = async (coinsToAdd) => {
    if (!currentUser) {
      setError('No signed-in user found.');
      setSnackbarVisible(true);
      return;
    }

    const db = getFirestore()
  
    const userCoinsRef = doc(db, 'userCoins', currentUser.uid);
    try {
      await setDoc(userCoinsRef, {
        coins: increment(coinsToAdd),
        lastUpdated: serverTimestamp(),
        uid: currentUser.uid,
      }, { merge: true });
      setError('Coins successfully added.'); // Set the success message
      props.navigation.goBack()
    } catch (e) {
      setError('Error updating coins: ' + e.message); // Set the error message
    }
    setSnackbarVisible(true);
  };

  /**
   * Purchase coins with RevenueCat
   * @param { RevenueCat & IOS IAP productId} productId 
   * @returns 
   */
  const purchaseItem = async (pack) => {
    if (!currentUser && !isIAR) {
      setError('You must sign in before purchasing coins.');
      setSnackbarVisible(true);
      return;
    }

    setLoading(true); // Start loading

    try {
      const {customerInfo, productIdentifier} = await Purchases.purchasePackage(pack);
      // if (typeof customerInfo.entitlements.active.my_entitlement_identifier !== "undefined") {
        // POC name might need to change the entitlement
      if (typeof customerInfo.entitlements.active["Pro access to creator's content"] !== "undefined") {
        
        // Update firebase
        const coinsToAdd = getCoinsFromProductId(pack.product.identifier);
        // If not in app review :|
        if (!isIAR) {
          await updateUserCoins(coinsToAdd);
        } else {
          // Lol
          setError('Purchase Successful!');
          setSnackbarVisible(true);
        }
      }
    } catch (e) {
      if (!e.userCancelled) {
        setError('Issue buying coins. Please contact support');
        setSnackbarVisible(true);
        setLoading(false); // Stop loading
        return;
      } else {
        // Other Errors
        setError('Issue buying coins. Please contact support');
        setSnackbarVisible(true);
        setLoading(false); // Stop loading
        return;
      }
    }

    setLoading(false); // Stop loading
  };

  const getCoinsFromProductId = (productId) => {
    switch (productId) {
      case 'coins_100_v1':
        return 100;
      case 'coins_500_v1':
        return 500;
      case 'coins_1000_v1':
        return 1000;
      case 'coins_2000_v1':
        return 2000;
      case 'coins_5000_v1':
        return 5000;
      case 'coins_10000_v1':
        return 10000;
      default:
        return 0;
    }
  };


  if (!currentOffering) {
    return (
      <View>
        <ActivityIndicator size="large" color="#008080" />
        <Text style={{textAlign: 'center', marginTop: 20}}>Fetching products</Text>
      </View>
    );
  } else {
    return (
      <ScrollView>

        <Snackbar
          visible={snackbarVisible}
          onDismiss={() => setError(null)}
          duration={Snackbar.DURATION_SHORT}
        >
        {error}
        </Snackbar>

        <Button onPress={() => props.navigation.goBack()}>Back</Button>

        
        {/* <Text>Current Offering: {currentOffering.identifier}</Text>
        <Text>Package Count: {currentOffering.availablePackages.length}</Text> */}

        <View>

          <Title style={{marginBottom: 10, textAlign: 'center'}}>Buy Coins</Title>
          { error && 
            <Text style={{color: 'red', marginBottom: 10, textAlign: 'center'}}>{error}</Text>
          }

          {currentOffering.availablePackages.map(pkg => (

            <View style={{margin: 20, padding: 20, backgroundColor: 'white', borderRadius: 10}} key={pkg.product.identifier} >

              {
                pkg.product.identifier && 
                  <Title style={{textAlign: 'center', marginBottom: 10}}>{getCoinsFromProductId(pkg.product.identifier)} coins</Title>
              }


              { pkg.product.priceString && 
                <Button 
                  onPress={() => purchaseItem(pkg)}
                  mode="contained"
                  style={{marginBottom: 30}}
                  loading={loading}
                  disabled={loading}
                >
                    {pkg.product.priceString}
                </Button>

              }

            </View>

          ))}

        </View>



        {/* {
          currentOffering.availablePackages.map((pkg) => {
            return (
              <View>
                <Text>{ pkg.product.identifier }</Text>

                <Text>
                  {JSON.stringify(pkg, null, 2)}
                </Text>
                <Text></Text>
              </View>
          )})
        } */}

      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    margin: 20,
    alignItems: 'center',
  },
  subscriptionAmount: {
    fontSize: 18,
    marginRight: 10,
  },
});