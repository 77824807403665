import * as Analytics from 'expo-firebase-analytics';
import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Dimensions, FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Divider, Subheading, Title } from 'react-native-paper';
import AppStoreBadges from '../appstoreBadge';
import SocialMediaLinks from '../Socials/SocialMediaLinks';
import CreatorCard from './CreatorCard';

export default function CategoryCreators(props) {
  const [creators, setCreators] = useState(null);

  useEffect(() => {
    (async() => {
      await Analytics.logEvent(`[Directory]: Cat ${ props.route.params.category }`);
    })();
    getCreators()
  }, []);

  const getCreators = async() => {
    const db = getFirestore();
    const q = query(collection(db, "creatorDirectory"), where("categories", "array-contains", `${props.route.params.category}`));
    const querySnapshot = await getDocs(q);
    let resp = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const id = doc.id;
      return{id, ...data}
    })
    setCreators(resp)

  }

  return (

    <View style={styles.root}>
      <View style={styles.setCtnWidth}>
        <Button 
          onPress={() => props.navigation.navigate("DiscoverInstructors")}
          icon="arrow-left"
        >
            ALL INSTRUCTORS
        </Button>
        <View style={styles.headingCtn}>
          <Title style={{marginBottom: 20, fontSize: "x-large", textAlign: "center"}}>Best online {props.route.params.category} Instructors</Title>
        </View>
        <View style={styles.headingCtn}>
          <FlatList
              numColumns={1}
              horizontal={true}
              data={creators}
              renderItem={({ item }) => (
                  <TouchableOpacity
                      style={styles.containerImage}
                  >
                      <CreatorCard creator={item} />
                  </TouchableOpacity>
              )}
          />
        </View>

        <Divider style={styles.divider} />

        <View style={styles.marketing}>
          
          <Title style={{marginBottom: 30, fontSize: 'x-large', textAlign: 'center'}}>Looking to make more money selling fitness content online?</Title>
          <Subheading style={{marginBottom: 30, textAlign: 'center'}} >Move Fitness is a free online fitness content marketplace for fitness creators. Earn more online and grow your audience with Move</Subheading>
          <Button 
            onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
            mode="contained"
            style={{marginBottom: 30}}
          >
              Find out more
          </Button>
        </View>
        <View style={styles.socials}>
          <SocialMediaLinks />
        </View>

        <Divider style={styles.divider} />

        <View style={styles.footer}>
          <View style={styles.footerCtn}>
              <View style={{marginBottom: 30, marginTop: 30}}>
                  <Title style={{textAlign: 'center'}}>Made with ❤️ by 
                      <Title> </Title>
                      <Title
                          onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                          style={{textDecorationLine: 'underline', textAlign: 'center'}}
                      >Move Fitness App</Title>
                  </Title>
              </View>
              <View style={{marginBottom: 15}}>
                  <Subheading style={{textAlign: 'center'}}>Easiest subscription platform for fitness creators and businesses</Subheading>
              </View>
              <View style={{marginBottom: 15}}>
                  <Subheading style={{textAlign: 'center'}}>Workouts • Challenges • On Demand videos</Subheading>
              </View>
              <View style={{marginBottom: 30, marginTop: 30}}>
                  <AppStoreBadges />
              </View>
          </View>
        </View>

      </View>
  </View>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  setCtnWidth: {
      maxWidth: 800,
      width: "100%",
      padding: 20,
  },
  headingCtn: {
    marginBottom: 30,
    marginTop: 30
  },
  titleCtn: {
    backgroundColor: 'white',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15
  },
  divider: {
    marginTop: 100,
    marginBottom: 100
  },
  footerCtn: {
    maxWidth: Dimensions.get('window').width * 0.75,
    flexDirection: 'column',
    alignItems: 'center',
  },
  marketing: {
    marginTop: 30,
    marginBottom: 30,
  },
  socials: {
    marginTop: 30,
    marginBottom: 30,
  },
  containerImage: {
    marginRight: 10,
  }

})
