import React from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { IconButton, Text } from 'react-native-paper';

export default function SelectWeek({ weeks, addWeek, selectedWeek, setSelectedWeek }) {

    return (
        <View style={styles.container}>
            <ScrollView
                horizontal
                style={styles.scrollCtn}
            >
                {
                    weeks.map((w, idx) => (
                        idx == selectedWeek ? (
                            <TouchableOpacity
                                onPress={() => setSelectedWeek(idx)}
                                style={styles.selectedWeek}
                            >
                                <Text style={{color: 'white'}}>Week</Text>
                                <Text style={{color: 'white'}}>{idx + 1}</Text>
                            </TouchableOpacity>
                        ):(
                            <TouchableOpacity
                                onPress={() => setSelectedWeek(idx)}
                                style={styles.unselectedWeek}
                            >
                                <Text style={{color: '#bdbdbd'}}>Week</Text>
                                <Text style={{color: '#bdbdbd'}}>{idx + 1}</Text>
                            </TouchableOpacity>
                        )
                    )
                    )
                }
            </ScrollView>
            <TouchableOpacity 
                style={styles.addWeek}
                onPress={addWeek}
            >
                <Text style={{color: '#bdbdbd'}}>Week</Text>
                <IconButton
                    color="#bdbdbd"
                    icon="plus"
                    size={15}
                />
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    scrollCtn: {
        marginRight: 10
    },
    selectedWeek: {
        height: 65,
        width: 65,
        fontSize: 6,
        backgroundColor: "#008080",
        borderRadius: 10,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    },
    unselectedWeek: {
        height: 65,
        width: 65,
        fontSize: 6,
        borderColor: '#bdbdbd',
        backgroundColor: '#eeeeee',
        borderRadius: 10,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10
    },
    addWeek: {
        height: 65,
        width: 65,
        borderStyle: 'dotted',
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#bdbdbd',
        backgroundColor: '#eeeeee',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

