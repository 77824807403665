import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { Button, Subheading, Title } from 'react-native-paper';
import IosPaywall from '../Paywall/IosPaywall';

export default function Paywall(props) {
    const navigation = useNavigation();

    const [loading, setLoading] = useState(false);

    const handleSubscribe = () => {
        navigation.navigate('Home', {
            screen: 'Main',
            params: {
                screen: 'Profile',
                params: { uid: props.uid },
            },
        });
    }

    return (
        <View>
            {
                Platform.OS === 'ios' ? (
                    <IosPaywall 
                        navigation={props.navigation}
                        creatorName={props.creatorName} 
                        // currencySymbol={props.workoutPrice.currency_symbol} 
                        // priceAmount={props.workoutPrice.currency_symbol} 
                        price={props.workoutPrice}
                        creatorUid={props.uid}
                    />
                ):(
                    <View style={styles.container}>
                    <View style={styles.content}>
                        <Image
                            style={{ width: 128, height: 128, opacity: "50%" }}
                            source={require('../../assets/lock.png')}
                        /> 
                    </View>
                    <Title style={styles.content}>
                        Subscribe to access all of {props.creatorName} content
                    </Title>
                    <Subheading style={styles.content}>
                        Click subscribe below to access all of {props.creatorName} workouts and challenges
                    </Subheading>
                    <View style={styles.content}>
                        {props.workoutPrice ? (
                            <Button
                                mode="contained"
                                onPress={() => handleSubscribe()}
                                loading={loading}
                                disabled={loading}
                            >
                                Subscribe {props.workoutPrice.currency_symbol} {props.workoutPrice.price} per month
                            </Button>
                        ):(
                            <Button
                                mode="contained"
                                loading={true}
                                disabled={true}
                            >
                                Subscribe
                            </Button>
                        )}
                    </View>
                </View>
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        backgroundColor: 'white',
        margin: "7%",
        padding: "4%"
    },
    content: {
        marginBottom: 15
    }
})