import React from 'react';
import { StyleSheet, Switch, View } from 'react-native';
import { Title, Text } from 'react-native-paper';

export default function CreateWorkoutPublic({ publicWorkout, onTogglePublic}) {
    return (
        <View>
            <Title style={styles.title}>Public workout?</Title>
            <Text style={styles.explainerText}>Turn off to make private only to you</Text>
            <Switch value={publicWorkout} onValueChange={onTogglePublic} />

        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        marginBottom: 30
    },
    explainerText: {
        marginTop: 5,
        marginBottom: 10,
        color: "#9e9e9e",
    },
});