import { MOVE_URL } from "@env";
import { useNavigation } from '@react-navigation/native';
import * as Analytics from 'expo-firebase-analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { Button, IconButton, List, Paragraph, Subheading, Text, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersData } from '../../redux/actions';
import ExerciseCard from "../exercises/ExerciseListCard";
import Paywall from '../main/Paywall';
import Media from '../media/index';
import Share from '../share/index';

function Challenge(props) {
    const navigation = useNavigation();
    const [challenge, setChallenge] = useState(null)
    const [usersChallenge, setUsersChallenge] = useState(false)
    const [userChallenge, setUserChallenge] = useState()
    const [postId, setPostId] = useState("")
    const [following, setFollowing] = useState(false)
    const [liked, setLiked] = useState(false)
    const [imageLoading, setImageLoading] = useState(true)
    const [subscribedChallenge, setSubscribedChallenge] = useState(true)
    const [paidData, setPaidData] = useState(null)
    const [url, setUrl] = useState()
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [paidMessage, setPaidMessage] = useState(null);

    useEffect(() => {

        (async () => {
            if (Platform.OS === 'web') {
                const webUrl = window.location.href
                setUrl(webUrl)
            } else {
                // If mobile, create web URL
                // TODO: In future needs to create IOS URI
                const webUrl = `${MOVE_URL}/challenge?challengeId=${route.params.challengeId}`
                setUrl(webUrl)
            }
        })();
        // Bug this crashes app now
        (async() => {
            await Analytics.logEvent('[ChallengeScreen]: Viewed');
        })();

        if (props.route.params) {
            // Needed for routing bug. 
            // Checks if the route has changed
            const joinedChallenge = props.challengesJoined.map((c) => c.challenge_id)
            if(joinedChallenge.indexOf(props.route.params.challengeId) > -1 ){
                const foundUserChallenge = props.challengesJoined.find( c => c.challenge_id === props.route.params.challengeId)
                setFollowing(true)
                setUserChallenge(foundUserChallenge)
            } else {
                setFollowing(false)
            }
    
            firebase.firestore()
                .collection("challenges")
                .doc(props.route.params.challengeId)
                .get()
                .then((snapshot) => {
                    const challengeResponse = snapshot.data()
                    checkIfPremiumContent(challengeResponse)
                    setChallenge(challengeResponse)
                })
        }

    }, [props.challengesJoined, props.route])


    const checkIfPremiumContent = (challengeData) => {
        // 1. Check if user exsists
        if (props.currentUser) {
            // 2. Check if users workout
            if (challengeData.user_id === firebase.auth().currentUser.uid) {
                setUsersChallenge(true)
            } else {
                // Not users workout
                // 3. Check if paid workout
                if (challengeData.paid) {
                    // 4. Check if subscribed
                    if (props.subscriptions.indexOf(challengeData.user_id) > -1 ) {
                        // The current user is subscribed to this premium content
                        setSubscribedChallenge(true)
                    } else {
                        // the user is not subscribed
                        // Get the subscription data
                        setSubscribedChallenge(false)
                        getPaidData(challengeData.user_id)
                    }
                } 
            }
        } else {
            // 5. Check if paid workout
            if (challengeData.paid) {
                setSubscribedChallenge(false)
                getPaidData(challengeData.user_id)
            }
        }
    }

    const getPaidData = (user_id) => {
        firebase.firestore()
            .collection("userStripeAccount")
            .doc(user_id)
            .get()
            .then((snapshot) => {
                setPaidData(snapshot.data())
            })
    }

    const onFollow = () => {
        // Calculate number of days
        const totalDays = challenge.weeks.reduce((count, current) => count + current.days.length, 0);
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })
        } else {
            firebase.firestore()
            .collection('userChallenges')
            .doc(firebase.auth().currentUser.uid)
            .collection("challenges")
            .add({
                user_id: firebase.auth().currentUser.uid,
                name: challenge.name,
                username: currentUser.name,
                weeks: challenge.weeks,
                completed: false,
                creation: firebase.firestore.FieldValue.serverTimestamp(),
                challenge_id: props.route.params.challengeId,
                complete_percent: 0,
                complete_unit: 0,
                numDays: totalDays,
            })
            .then((docRef) => {
            })
            .catch((error) => {
            });
        }
    }

    const onUnfollow = () => {
        let query = firebase.firestore().collection("userChallenges").doc(firebase.auth().currentUser.uid).collection("challenges").where("challenge_id", "==", props.route.params.challengeId)
        query.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                doc.ref.delete();
            });
        });
    }

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const navigateExercises = (exercises, weekIndex, dayIndex) => {
        if (following) {
            navigation.navigate("Exercises", {
                exercises: exercises, 
                following: following, 
                challenge: true,
                challengeData: userChallenge,
                challengeId: props.route.params.challengeId,
                weekIndex: weekIndex,
                dayIndex: dayIndex
            })
        } else {
            setSnackbarVisible(true)
            setSnackbarMessage('Must join challenge first')
            setPaidMessage("Must join challenge first")
        }
    }

    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            { challenge !== null ? (
                <ScrollView style={styles.container}>
                    <View>
                        { challenge.image && challenge.image.url.length > 0 ? (
                            <View>
                                <Media contentType={challenge.image.metadata.type} url={challenge.image.url} component='cover' />
                            </View>
                        ):(
                            <View style={{ marginBottom: 10}}>
                                <Image
                                    style={{ width: "100%", height: "auto", minHeight: 100, minWidth: 100, margin: 10 }}
                                    resizeMode="contain"
                                    source={require('../../assets/dumbell.png')}
                                /> 
                            </View>
                        )}
                    </View>
                    <View style={styles.infoContainer}>
                        <Title style={styles.item}>{challenge.name}</Title>
                        <TouchableOpacity
                            style={styles.item} 
                            onPress={() => navigation.navigate('Home', {
                                screen: 'Main',
                                params: {
                                    screen: 'Profile',
                                    params: {
                                        uid: challenge.user_id,
                                    },
                                },
                            })}
                            
                        >
                            <Subheading style={{color: "#9e9e9e"}}>Created by {challenge.by}</Subheading>
                        </TouchableOpacity>
                        <View style={styles.statsCtn}>
                            <View>
                                <Text>{challenge.followingCount}</Text>
                                { following ? (
                                        <Button
                                            onPress={() => onUnfollow()}
                                        >Leave challenge</Button>
                                    ) : (
                                        <Button
                                            mode="contained"  
                                            onPress={() => onFollow()}
                                        >Join</Button>
                                )}
                            </View>
                            <View>
                                <Button
                                    icon="trophy-variant"
                                    onPress={()=> 
                                        navigation.navigate('Leaderboard', 
                                        {challengeId: props.route.params.challengeId}
                                        )}
                                >
                                    Community Progress
                                </Button>
                            </View>
                            <View style={{flexDirection: 'column', alignItems: 'center'}}>
                                <Share
                                    params={`/challenge?challengeId=${props.route.params.challengeId}`}
                                    message={"Check out this Challenge"}
                                    title={`${challenge.name} by ${challenge.by}`}
                                    image={null}   
                                />
                            </View>
                        </View>
                        <View style={styles.description}>
                            <Paragraph>{challenge.description}</Paragraph>
                        </View>
                    </View>

                    { following ? (
                        <View>
                            <View style={styles.progressCtn}>
                                <View>
                                    <AnimatedCircularProgress
                                        size={120}
                                        width={15}
                                        fill={userChallenge.complete_percent}
                                        tintColor="#008080"
                                        backgroundColor="#E8ECF2">
                                        {
                                            (fill) => (
                                            <Title>
                                                { userChallenge.complete_percent}
                                            </Title>
                                            )
                                        }
                                    </AnimatedCircularProgress>
                                </View>
                                <View style={styles.progressText}>
                                    <Title>My Progress</Title>
                                    <Subheading>{ userChallenge.complete_unit} / {userChallenge.numDays} days</Subheading>
                                </View>
                            </View>
                            <View style={styles.section}>
                                <Title style={{marginBottom: 15}}>Exercises</Title>
                                { subscribedChallenge ? (
                                    <View>
                                        {
                                        userChallenge.weeks.map((week, idx) => (
                                            <View>
                                                <Title style={{marginTop: 20, marginBottom: 20}}>Week {idx + 1}</Title>
                                                {
                                                    week.days.map((day, ix) => (
                                                        <View>
                                                            <List.Section>
                                                                { day.completed ? (
                                                                    <List.Accordion
                                                                        title={`Day ${ix + 1}: ${day.name}`}
                                                                        left={props => <List.Icon {...props} icon="checkbox-marked-circle" color="#008080" />}
                                                                    >
                                                                        <List.Item 
                                                                            title="Start workout" 
                                                                            right={props => (<IconButton size={30} color="#008080" icon="play-circle" onPress={() => navigateExercises(day, idx, ix)} />)}
                                                                        />
                                                                        { paidMessage && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Text>}
                                                                        {
                                                                            day.exercises.map(ex => (
                                                                                <View style={styles.exerciseCtn}>
                                                                                    <ExerciseCard exercise={ex} />
                                                                                </View>
                                                                            ))
                                                                        }
                                                                    </List.Accordion>
                                                                ):(
                                                                    <List.Accordion
                                                                        title={`Day ${ix + 1}: ${day.name}`}
                                                                        left={props => <List.Icon {...props} icon="checkbox-blank-circle-outline" />}
                                                                    >
                                                                        <List.Item title="Start workout" right={props => (<IconButton size={30} color="#008080" icon="play-circle" onPress={() => navigateExercises(day, idx, ix)} />)}/>
                                                                        { paidMessage && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Text>}
                                                                        {
                                                                            day.exercises.map(ex => (
                                                                                <View style={styles.exerciseCtn}>
                                                                                    <ExerciseCard exercise={ex} />
                                                                                </View>
                                                                            ))
                                                                        }
                                                                    </List.Accordion>
                                                                )}
                                                            </List.Section>
                                                        </View>
                                                    ))
                                                }
                                            </View>
                                            )
                                        )
                                    }
                                    </View>
                                ):(
                                    <View>
                                        <Paywall creatorName={challenge.by} workoutPrice={paidData} uid={challenge.user_id} navigation={navigation} />
                                    </View>
                                )}
                            </View>             
                        </View>
                    ):(
                        <View>
                            <View style={styles.progressCtn}>
                                <View>
                                    <AnimatedCircularProgress
                                        size={120}
                                        width={15}
                                        fill={0}
                                        tintColor="#008080"
                                        backgroundColor="#E8ECF2">
                                        {
                                            (fill) => (
                                            <Title>
                                                0
                                            </Title>
                                            )
                                        }
                                    </AnimatedCircularProgress>
                                </View>
                                <View style={styles.progressText}>
                                    <Title>My Progress</Title>
                                    <Subheading>0 / {challenge.numDays} days</Subheading>
                                </View>

                            </View>
                            <View style={styles.section}>
                                <Title style={{marginBottom: 15}}>Exercises</Title>
                                { subscribedChallenge ? (
                                    <View>
                                        {
                                        challenge.weeks.map((week, idx) => (
                                            <View>
                                                <Title style={{marginTop: 20, marginBottom: 20}}>Week {idx + 1}</Title>
                                                {
                                                    week.days.map((day, ix) => (
                                                        <View>
                                                            <List.Section>
                                                                { day.completed ? (
                                                                    <List.Accordion
                                                                        title={`Day ${ix + 1}: ${day.name}`}
                                                                        left={props => <List.Icon {...props} icon="checkbox-marked-circle" color="#008080" />}
                                                                    >
                                                                        <List.Item 
                                                                            title="Start workout" 
                                                                            right={props => (<IconButton size={30} color="#008080" icon="play-circle" onPress={() => navigateExercises(day, idx, ix)} />)}
                                                                        />
                                                                        { paidMessage && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Text>}
                                                                        {
                                                                            day.exercises.map(ex => (
                                                                                <View style={styles.exerciseCtn}>
                                                                                    <ExerciseCard exercise={ex} />
                                                                                </View>
                                                                            ))
                                                                        }
                                                                    </List.Accordion>
                                                                ):(
                                                                    <List.Accordion
                                                                        title={`Day ${ix + 1}: ${day.name}`}
                                                                        left={props => <List.Icon {...props} icon="checkbox-blank-circle-outline" />}
                                                                    >
                                                                        <List.Item title="Start workout" right={props => (<IconButton size={30} color="#008080" icon="play-circle" onPress={() => navigateExercises(day, idx, ix)} />)}/>
                                                                        { paidMessage && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Text>}
                                                                        {
                                                                            day.exercises.map(ex => (
                                                                                <View style={styles.exerciseCtn}>
                                                                                    <ExerciseCard exercise={ex} />
                                                                                </View>
                                                                            ))
                                                                        }
                                                                    </List.Accordion>
                                                                )}
                                                            </List.Section>
                                                        </View>
                                                    ))
                                                }
                                            </View>
                                            )
                                        )
                                    }
                                    </View>
                                ):(
                                    <View>
                                        <Paywall creatorName={challenge.by} workoutPrice={paidData} uid={challenge.user_id} navigation={navigation} />
                                    </View>
                                )}

                            </View>

                        </View>
                    )}

                </ScrollView>
            ):(
                <ActivityIndicator size="large" color="#008080" />
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    progressCtn: {
        backgroundColor: 'white',
        marginTop: 15,
        borderRadius: 15,
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    progressText: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    infoContainer: {
        backgroundColor: 'white',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        padding: 20
    },
    statsCtn: {
        marginBottom: 15
    },
    section: {
        marginTop: 15,
        padding: 20 
    },
    item: {
        marginBottom: 15
    },
    description: {
        marginTop: 15
    },
    exerciseCtn: {
        marginBottom: 15,
        paddingLeft: 0 // needed for formatting with List
    },
    dayTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    loadingCtn: {
        width: '90%',
        height: '90%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    exerciseSection: {
        marginTop: 15,
        padding: 20
    },
    dayCtn: {
        marginBottom: 15
    }
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    challengesJoined: store.userState.challengesJoined,
    subscriptions: store.userState.subscriptions,
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUsersData }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(Challenge);
