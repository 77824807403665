import * as Analytics from 'expo-firebase-analytics';
import * as ImagePicker from 'expo-image-picker';
import firebase from 'firebase/compat/app';
import "firebase/compat/functions";
import 'firebase/compat/storage';
import { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, IconButton, Modal, Paragraph, Portal, Snackbar, Subheading, Text, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import AppStoreBadges from '../appstoreBadge';

const initialWorkoutData = {
    "weeks": [
        {
            "week": "1",
            "completed": false,
            "days": [
                {
                    "completed": false,
                    "exercises": [],
                    "name": "",
                    "openAccordion": true
                },
            ]
        }
    ]
}

const demoResponse2 = `Reapitable\nWEEKLY WORKOUT\nAn at home body-weight routine for every day of the week\n"THE ROUTINE"\n5 tricep dips\n10 push ups\n15 sit-ups/ab exercise\nDAY 1-LEGS\n• Routine x2\n• 25 squats\n• 25 sumo squats\n• Routine x2\n• REPEAT SQUAT EXERCISES*\n• Routine x2\n• March in place 20 seconds\n. Stretch all muscle groups and\nrelax\nDAY 3 - ARMS\nROUTINE x2\n20 Push ups\n. 10 wall tricep pushes\n• ROUTINE x2\n• REPEAT AB EXERCISES\n• "Routine x2\n• March in place 20 seconds\n. Stretch and relax\nDAY 5\n• ROUTINE x2\n-\nCOMBO\n• 20 squats/20 sumo squats\n. 30 standing oblique twists\n• ROUTINE x2\n• 20 push-ups\n• 50 jumping jacks\n• Routine x2\n• March in place 20 seconds\n. Stretch and relax\n• 10 lunges (each leg)\n10 squats\n20 jumping jacks\n●\nDAY 2- ABS\n• ROUTINE x2\n• 50 standing oblique twists\n• 30 second floor plank\n• ROUTINE x2\n• REPEAT AB EXERCISES*\n• Routine x2\n• March in place 20 seconds\n. Stretch and relax\nDAY 4 CARDIO\n• ROUTINE x2\n• 100 jumping jacks\n. 30 second in-place sprint\n• ROUTINE x2\n• REPEAT CARDIO EXERCISES\n• 'Routine x2\n• March in place 20 seconds\n. Stretch and relax\nDAY 6-YOUR CHOICE\nCHOOSE FROM DAYS 1-5\nFOR TODAY'S WORKOUT!\nWhats your problem area?\n-LEGS- ABS-\n-ARMS - CARDIO-\nDAY 7 REST AND RELAX\nKiwi's Plan`

const { width } = Dimensions.get('window');

let fontSize = 20; // default font size

// adjust font size based on screen width
if (width < 400) {
    fontSize = 28;
} else if (width >= 400 && width < 600) {
    fontSize = 30;
} else if (width >= 600 && width < 850) {
    fontSize = 32;
} else {
    fontSize = 40;
}

function ConvertWorkoutNotesToApp(props) {
    const [workout, setWorkout] = useState(initialWorkoutData);
    const [dayIndex, setDayIndex] = useState(0);
    const [weekIndex, setWeekIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");
    const [disableBtn, setDisableBtn] = useState(true);
    const [image, setImage] = useState();
    const [errorMessage, setErrorMessage] = useState(null);
    const [step, setStep] = useState(0);
    const [dirtyAnalytics, setDirtyAnalytics ] = useState(true);

    const showModal = () => setModalVisible(true);
    const hideModal = () => setModalVisible(false);
    const containerStyle = {backgroundColor: 'white', margin: 20, padding: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' };

    useEffect(() => {
        // Good luck to anyone that might have to maintain this comp
        if (dirtyAnalytics) {
            (async() => {
                await Analytics.logEvent('[ConvertWorkoutNotesToApp]: Viewed');
            })();
            setDirtyAnalytics(false)
        }
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
        updateWorkoutStore()
    }, [workout])

    const updateWorkoutStore = () => {
        props.dispatch({ type: "FREE_WORKOUT_TIMER_WORKOUT", freeWorkoutTimerWorkout: workout })
    }

    const onToggleSnackBar = () => setVisible(!visible);

    const onDismissSnackBar = () => setVisible(false);

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    const pickImage = async () => {
        setSnackbarMessage("")
        setErrorMessage(null)
        try {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                quality: 1,
                
            });

            if (!result.cancelled) {
                let uri = result.uri;
                // TODO:
                // From Expo 48 Need to use below
                // let uri2 = result.assets[0].uri;
                let fileExtension = uri.substring(uri.indexOf('/') + 1, uri.indexOf(';base64'));
                let mediaType = determineFileType(fileExtension)
                const img = {
                    url: uri,
                    metadata: {"type": mediaType}
                }
                setImage(img);
                setDisableBtn(false)
                setStep(1)
            }
            
        } catch (error) {
            console.error(error)
            setSnackbarMessage("Error uploading image. Pick another")
            setErrorMessage("Error uploading image. Pick another")
            onToggleSnackBar()
        }
    };

    const uploadWorkoutImage = async() => {
        setSnackbarMessage("")
        setLoadingText("")
        setLoading(true)
        try {
            setLoadingText("🏋️‍♀️ Lifting image to cloud")
            const prepareImage = await prepareWorkoutImg(image.url)
            const uploadWorkoutImg = await uploadImage(prepareImage)
            const imgUrl = await uploadWorkoutImg.ref.getDownloadURL()
            convertWorkout(imgUrl)  
        } catch (error) {
            console.error(error)
            setSnackbarMessage("Error uploading image. Pick another")
            onToggleSnackBar()
            setLoading(false)
        }
    }

    const convertWorkout = (url) => {
        setLoadingText("🤖 Running through your workout")
        const createAcc = firebase.functions().httpsCallable('getTextFromWorkoutNotes');
            createAcc({
                imagePath: url, 
            })
            .then( result => {
                convertResponseToWorkout(result.data.fullTextAnnotation.text)
            })
            .catch((error) => {
                console.error(error)
                setSnackbarMessage("Error analyzing image. Contact support or pick another")
                setErrorMessage("Error analyzing image. Try again, pick another or contact support")
                onToggleSnackBar()
                setLoading(false)
            })
    }

    const prepareWorkoutImg = async (uri) => {
        const childPath = `convertWorkoutNotes/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath}
        return coverMedia
    }

    const uploadImage = async(imgData) => {
        // Create a root reference
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef
                            .child(imgData.childPath)
                            .put(imgData.blob)
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            console.error(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return uploadTask
    }

    const navigateWorkoutPage = (dummy) => {
        setLoadingText("🍻 Done!")
        setLoading(false)
        props.navigation.navigate("FreeWorkoutTimer")
    }

    const convertResponseToWorkout = async (visionResponse) => {
        setLoadingText("📱 Whipping up your playable workout")
        const formattedNewWorkout = await formatNewWorkout(visionResponse)
        setWorkout(prevState => ({
            ...prevState, ...formattedNewWorkout
        }))
        navigateWorkoutPage(formattedNewWorkout)
    }

    const formatNewWorkout = async (visionResponse) => {
        let newWorkout = await _.cloneDeep(workout);
        let dayCounter = dayIndex

        const split = visionResponse.split("\n")
        for (let i = 0; i < split.length; i++) {

            // Check if new day
            const isNewDay = await checkNewDay(split[i])
            if (isNewDay) {
                dayCounter = dayCounter + 1
                // 1. Increase Day Index? / Create a new day
                addDay(newWorkout, split[i])
                setDayIndex(dayCounter)
                // Set ExerciseNumber back to zero
                continue
            }

            // Check contains number
            const lineNumberChecked = await checkContainsNumber(split[i])
            const dayCounterDisgustingHardVar = dayCounter

            const whatever = createExercise(lineNumberChecked, newWorkout, dayCounterDisgustingHardVar)

        }
        return newWorkout
    }

    const checkNewDay = (line) => {
        // 1. Explicitly check if there is a new day
        let regex = /day/i
        let containsDay = regex.test(line)
        if(containsDay){
            return true
        }


        // 2. Attempt to infer if there is a new day based on if line without a number?
        return false

    }

    const checkContainsNumber = (line) => {
        let verdict = false
        let enrichedLine = line
        let numbers = null
        
        const matches = enrichedLine.match(/\d+/g);
        if (matches) {
            verdict = true
            numbers = matches
        }

        return {"number": verdict, "line": enrichedLine, "numbers": numbers}
    }

    const createExercise = (line, newWorkout, dayIdx) => {

        let oldDayExercises = newWorkout.weeks[weekIndex].days[dayIdx].exercises
        const rawName = line.line
        // Remove dotpoint from line
        let exName = rawName.replace("•", "")

        if (exName.length <= 1) {
            return
        }

        const initExercise = {      
            "image": '',
            "completed": false,
            "instructions": '',
        }

        const newExercises = formatExerciseNumbers(line, initExercise)
        oldDayExercises.push(newExercises)
        newWorkout.weeks[weekIndex].days[dayIdx].exercises = oldDayExercises
    }

    const formatExerciseNumbers = (formatLine, formatExercise) => {

        const replacedLine = formatLine.line.replace("•", "")
        const line = replacedLine.trim()

        // Check contains number
        if (formatLine.number) {
            const lineNumber = formatLine.numbers[0]
            // Check for seconds
            const checkForSeconds = formatLine.line.match(/[0-9]\sseconds/gi);
            const checkForSecond = formatLine.line.match(/[0-9]\ssecond/gi);
            const x = checkForX(formatLine.line, lineNumber)
            if (checkForSeconds) {
                const updatedLine = line.replace(`${lineNumber} seconds`, "")
                const updatedLineCapital = updatedLine.replace(`${lineNumber} Seconds`, "")
                formatExercise.time = `${lineNumber} seconds`
                formatExercise.name = updatedLineCapital
                return formatExercise
            } else if (checkForSecond) {
                const updatedLine = line.replace(`${lineNumber} second`, "")
                const updatedLineCapital = updatedLine.replace(`${lineNumber} Second`, "")
                formatExercise.time = `${lineNumber} second`
                formatExercise.name = updatedLineCapital
                return formatExercise
            } else if (x.verdict) {
                // 1. Add number to Reps
                formatExercise.reps = lineNumber
                // 2. Clean / remove number from name
                // const updatedLine = line.replace(`${lineNumber}`, "")
                const updatedLine = x.line.replace(`${lineNumber}`, "")

                const replacedLine = updatedLine.replace("•", "")
                const finalLine = replacedLine.trim()

                formatExercise.name = finalLine
                return formatExercise
            } else {
                formatExercise.name = x.line
                return formatExercise
            }
        } else {
            formatExercise.name = line
            return formatExercise
        }
    }

    const checkForX = (line, number) => {
        // This function looks to identify
        // and strip out xNumber of an exercise
        const xBeforeWithSpace = line.match(/x\s[0-9]/gi)
        const xBefore = line.match(/x[0-9]/gi)
        const xAfterWithSpace = line.match(/[0-9]\sx/gi)
        const xAfter = line.match(/[0-9]x/gi)

        if (xBeforeWithSpace) {
            const updatedLine = line.replace(`x ${number}`, `${number}`)
            const updatedLineCapital = updatedLine.replace(`X ${number}`, `${number}`)
            return {"verdict": true, "line": updatedLineCapital }
        } else if (xBefore) {
            const updatedLine = line.replace(`x${number}`, `${number}`)
            const updatedLineCapital = updatedLine.replace(`X${number}`, `${number}`)
            return {"verdict": true, "line": updatedLineCapital }
        } else if (xAfterWithSpace) {
            const updatedLine = line.replace(`${number} x`, `${number}`)
            const updatedLineCapital = updatedLine.replace(`${number} X`, `${number}`)
            return {"verdict": true, "line": updatedLineCapital }
        } else if (xAfter) {
            const updatedLine = line.replace(`${number}x`, `${number}`)
            const updatedLineCapital = updatedLine.replace(`${number}X`, `${number}`)
            return {"verdict": true, "line": updatedLineCapital }
        } else {
            return {"verdict": false, "line": line }
        }
    }

    const addDay = (workoutArg, dayName) => {
        const newDay =  {
            "name": dayName,
            "completed": false,
            "exercises": [],
            "openAccordion": false
        }
        workoutArg.weeks[weekIndex].days.push(newDay)
        return workoutArg
    }

    return (
        <View style={styles.root}>
            <Snackbar
                visible={visible}
                onDismiss={onDismissSnackBar}
                wrapperStyle={{ top: 0 }}
                action={{
                label: 'Ok',
                onPress: () => {
                    // Do something
                },
                }}>
                    {snackbarMessage}
            </Snackbar>
            <Portal>
                <Modal visible={modalVisible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                    <Title>Convert workout notes</Title>
                    <Paragraph style={{marginBottom: 10}}>G'day! 🦘</Paragraph>
                    <Paragraph style={{marginBottom: 10}}>Welcome to my little tool I built to convert your workout notes into a playable app.</Paragraph>
                    <Paragraph style={{marginBottom: 10}}>Download an image or take a screenshot of your workout and upload below</Paragraph>
                    <Paragraph style={{marginBottom: 10}}>It will try to convert to exercises organised by days.</Paragraph>
                    <Paragraph style={{marginBottom: 10}}>You can edit by clicking on the exercise</Paragraph>
                    <Paragraph>If you enjoyed this, signup to save workout and use the web app or download our IOS or Android app</Paragraph>
                    <Title>Tips</Title>
                    <Paragraph>• Start new days & exercises on new line</Paragraph>
                    <Paragraph>• Label days clearly</Paragraph>
                    <Paragraph>• Format reps as e.g. x20, 20x, or 20 </Paragraph>
                </Modal>
            </Portal>
            <View style={styles.sectionRoot}>
                <View style={styles.setCtnWidth}>
                    { loading ? (
                        <View style={styles.loadingScreen}>
                            <Title>Uploading...</Title>
                            <Image
                                style={{ width: 200, height: 200 }}
                                source={require('../../assets/moveIcons/koala_boxing_transparent_1.gif')}
                            /> 
                            <Subheading>{loadingText}</Subheading>
                            <View style={styles.sectionBlock}>
                                <Button
                                    mode='contained'
                                    disabled={true}
                                    loading={true}
                                >Upload Workout</Button>
                            </View>
                        </View>
                    ):(
                        {
                            0:
                                <View style={styles.sectionContent}>
                                    <View style={styles.sectionBlock}>
                                        <Title style={{textAlign: 'center', fontSize: fontSize}}>Convert Workout notes to App</Title>
                                    </View>
                                    <View style={styles.sectionBlock}>
                                        <Text style={{fontStyle: 'italic', textAlign: 'center'}}>Upload photo or screenshot of your workout to convert it into a playable workout </Text>
                                    </View>
                                    <View style={{flexDirection: 'column', alignItems: 'center', marginBottom: 20}}>
                                        <IconButton
                                                icon="information-outline"
                                                size={20}
                                                onPress={showModal}
                                                color="#008080"
                                            />
                                    </View>
                                    <View style={styles.sectionBlock}>
                                        <TouchableOpacity style={styles.addExercise} onPress={() => pickImage()}>
                                            <View style={styles.innerAddExercise}>
                                                <IconButton
                                                    color="#bdbdbd"
                                                    icon="cloud-upload-outline"
                                                    size={100}
                                                />
                                                <Title style={{color: '#bdbdbd', fontWeight: "bold" }}>Upload workout</Title>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            ,
                            1:
                                <View style={styles.sectionContent}>
                                    <View style={styles.imgBlock}>
                                        {image ? <Image
                                            source={{ uri: image.url }}
                                            style={{     
                                                height: 350,
                                                width: 300,
                                            }}
                                            resizeMode="contain"
                                        /> : null}
                                    </View>
                                    <View style={styles.sectionBlock}>
                                        <Button
                                            mode='outlined'
                                            onPress={() => setStep(0)}
                                        >Back</Button>
                                    </View>
                                    <View style={styles.sectionBlock}>
                                        <Button
                                            mode='contained'
                                            onPress={() => uploadWorkoutImage()}
                                            disabled={disableBtn}
                                        >Upload Workout</Button>
                                    </View>
                                    { errorMessage && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{errorMessage}</Text>}

                                </View>
                            ,
                        }[step]
                    )}
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerCtn}>
                    <View style={{marginBottom: 30, marginTop: 30}}>
                        <Title style={{textAlign: 'center'}}>Made with ❤️ by 
                            <Title> </Title>
                            <Title
                                onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                                style={{textDecorationLine: 'underline', textAlign: 'center'}}
                            >Move Fitness App</Title>
                        </Title>
                    </View>
                    <View style={{marginBottom: 15}}>
                        <Subheading style={{textAlign: 'center'}}>Easiest subscription platform for fitness creators and businesses</Subheading>
                    </View>
                    <View style={{marginBottom: 15}}>
                        <Subheading style={{textAlign: 'center'}}>Workouts • Challenges • On Demand videos</Subheading>
                    </View>
                    <View style={{marginBottom: 30, marginTop: 30}}>
                        <AppStoreBadges />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({

    sectionRoot: {
        // backgroundColor: 'white',
        backgroundColor: "#E8ECF2",
        flexDirection: "column",
        alignItems: "center",
        minHeight: Dimensions.get('window').height * 0.85,
    },
    setCtnWidth: {
        maxWidth: 700,
		width: Dimensions.get('window').width * 1,
        
    },
    sectionContent: {
        margin: 20
    },
    sectionBlock: {
        marginTop: 15,
        marginBottom: 15,
        flexDirection: "column",
        justifyContent: "center"
    },
    imgBlock: {
        marginTop: 15,
        marginBottom: 15,
        flexDirection: "column",
        alignItems: "center"
    },
    footer: {
        flexDirection: "column",
        alignItems: "center",
        // backgroundColor: 'white',
        backgroundColor: "#E8ECF2",
    },
    footerCtn: {
        maxWidth: Dimensions.get('window').width * 0.75,
        flexDirection: 'column',
        alignItems: 'center',
    },
    titleDesktop: {
        fontSize: 36,
        textAlign: 'center'
    }, 
    title: {
        textAlign: 'center'
    }, 
    addExercise: {
        borderStyle: 'dotted',
        borderWidth: 3,
        borderRadius: 10,
        backgroundColor: 'white',
        borderColor: '#bdbdbd',
        color: '#bdbdbd',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerAddExercise: {
        alignItems: 'center',
        margin: 15
    },
    loadingScreen: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: Dimensions.get('window').height * 0.65,
    },

})

const mapStateToProps = (store) => ({
    workoutStore: store.userState.freeWorkoutTimerWorkout,
})

export default connect(mapStateToProps)(ConvertWorkoutNotesToApp);