import React, {useState, useEffect } from 'react'
import { View, StyleSheet, Image } from 'react-native'

export default function media({ component }) {
    const [type, setType] = useState(null);

    useEffect(() => {
        switch (component) {
            case 'cover':
                setType('cover')
                break;
            case 'exercise':
                setType('exercise')
                break;
            default:
                setType('default')
                break;
        }
    }, [])

    return (
            <View>
                {type === 'cover' && 
                    <Image
                        style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                        source={require('../../assets/dumbell.png')}
                    /> 
                }
                {type === 'exercise' && 
                    <Image
                        style={{ width: 100, height: 100 }}
                        source={require('../../assets/dumbell.png')}
                    /> 
                }
                {type === 'default' && 
                    <Image
                        style={{ width: 100, height: 100  }}
                        source={require('../../assets/dumbell.png')}
                    /> 
                }
            </View>
    )
}

const styles = StyleSheet.create({
    container: {

    }
})
