import * as ImagePicker from 'expo-image-picker';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';
import ProfilePicture from './index';

export default function EditProfilePicture({ image, updateImage }) {
    const [imageLoading, setImageLoading] = useState(true)
    const [edited, setEdited] = useState(false)

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, [image]);

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            // Change this to .All for videos and images
            // mediaTypes: ImagePicker.MediaTypeOptions.Images,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.8,
            
        });
        if (!result.cancelled) {
            let uri = result.uri;
            let fileExtension = uri.substring(uri.indexOf('/') + 1, uri.indexOf(';base64'));
            let mediaType = determineFileType(fileExtension)
            const img = {
                url: uri,
                metadata: {"type": mediaType}
            }
            updateImage(img.url);
            setEdited(true)
        }
    };

    const handleDeleteImg = () => {
        updateImage(null);
        setEdited(false)
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    return (
        <View style={styles.container}>
            <ProfilePicture image={image} />
            <Button
                mode='outlined'
                onPress={() => pickImage()}
            >
                Pick an image
            </Button>
            { edited  ? (
                <Button
                    mode='outlined'
                    onPress={() => handleDeleteImg()}
                    color="red"
                    style={{marginLeft: 3}}
                >
                    Remove
                </Button>
            ) : null }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-evenly', 
        alignItems: 'center',
        margin: 20
    },
})
