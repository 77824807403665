
import * as Analytics from 'expo-firebase-analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, FlatList, Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Text, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import AppStoreBadges from '../appstoreBadge';
import ContentCard from '../content/ContentCardMini';
import OnDemandCardMini from '../Ondemand/OnDemandCardMini';

const { width  } = Dimensions.get('window');

  let fontSize = 20; // default font size

    // adjust font size based on screen width
    if (width < 400) {
        fontSize = 28;
    } else if (width >= 400 && width < 600) {
        fontSize = 30;
    } else if (width >= 600 && width < 850) {
        fontSize = 32;
    } else {
        fontSize = 40;
    }

function Feed(props) {
    const [workouts, setWorkouts] = useState([])
    const [publicChallenges, setPublicChallenges] = useState([])
    const [publicOnDemandVideos, setpublicOnDemandVideos] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async() => {
            await Analytics.logEvent('[FeedScreen]: Accessed');
        })();
        // Todo: Move to redux store
        // Ideally only load once component viewed
        getWorkoutsFeed()
        getChallengesFeed()
        getOnDemandVideoFeed()
    }, [props.usersFollowingLoaded, props.feed, props.challenges])

    const getWorkoutsFeed = () => {
        firebase.firestore()
        .collection("workouts")
        .where("public", "==", true)
        .orderBy("creation", "desc")
        .limit(15)
        .get()
        .then((snapshot) => {
            let workoutsData = snapshot.docs.map(doc => {
                const data = doc.data();
                const id = doc.id;
                return{id, ...data}
            })
            setWorkouts(workoutsData)
            setLoading(false)
        })
    }

    const getOnDemandVideoFeed = () => {
        firebase.firestore()
        .collection("onDemandWorkout")
        .where("public", "==", true)
        .orderBy("creation", "desc")
        .limit(15)
        .get()
        .then((snapshot) => {
            let onDemandData = snapshot.docs.map(doc => {
                const data = doc.data();
                const id = doc.id;
                return{id, ...data}
            })
            setpublicOnDemandVideos(onDemandData)
            setLoading(false)
        })
    }

    const getChallengesFeed = () => {
        firebase.firestore()
        .collection("challenges")
        .where("public", "==", true)
        .orderBy("creation", "desc")
        .limit(6)
        .get()
        .then((snapshot) => {
            let challengeData = snapshot.docs.map(doc => {
                const data = doc.data();
                const id = doc.id;
                return{id, ...data}
            })
            setPublicChallenges(challengeData)
            setLoading(false)
        })
    }

    return (
        <View style={styles.container}>
            <ScrollView style={styles.contentCtn}>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    <View style={styles.sectionBlock}>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktop : styles.title]}>Workouts, Challenges, On Demand Videos</Title>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktopColor : styles.titleColor]}>Health & Fitness Content Marketplace</Title>
                    </View>
                    <View style={styles.sectionBlock}>
                        <Text style={{fontStyle: 'italic', textAlign: 'center'}}>Marketplace to discover or sell health & fitness content online </Text>
                    </View>
                    <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Button 
                            mode='contained'
                            style={{minWidth: 300, margin: 10}}
                            onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                        >Sell content</Button>
                        <Button 
                            mode='outlined'
                            style={{minWidth: 300, margin: 10}}
                            onPress={() => props.navigation.navigate("Search")}
                        >Find content</Button>
                    </View>
                </View>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    { loading ? (
                        <View style={styles.loadingCtn}>
                            <Title>Fetching workouts...</Title>
                            <Image
                                style={[Dimensions.get('window').width > 900 ? styles.imageStyleDesktop : styles.imageStyle]}
                                source={require('../../assets/moveIcons/koala_victory_transparent_1.gif')}
                            />
                            <ActivityIndicator size="large" color="#008080" style={{marginBottom: 20, marginTop: 20}} />
                        </View>
                    ) : (
                        <View>
                            <View style={styles.titleCtn}>
                                <Title> Featured Workouts</Title>
                                <Button
                                    onPress={() => props.navigation.navigate("Search")}
                                >See all</Button>
                            </View>
                            <FlatList
                                numColumns={1}
                                horizontal={true}
                                data={workouts}
                                renderItem={({ item }) => (
                                    <TouchableOpacity
                                        style={styles.containerImage}
                                        onPress={() => props.navigation.navigate(
                                            'Content', {
                                                screen: 'Workout',
                                                params: {workoutId: item.id},
                                            }
                                        )}
                                    >
                                        <ContentCard workout={item} />
                                    </TouchableOpacity>
                                )}
                            />
                            <View style={styles.titleCtn}>
                                <Title> Featured Challenges</Title>
                                <Button
                                    onPress={() => props.navigation.navigate("Search")}
                                >See all</Button>
                            </View>
                            <FlatList
                                numColumns={1}
                                horizontal={true}
                                data={publicChallenges}
                                renderItem={({ item }) => (
                                    <TouchableOpacity
                                        style={styles.containerImage}
                                        onPress={() => props.navigation.navigate(
                                            'Content', {
                                                screen: 'Challenge',
                                                params: { challengeId: item.id },
                                            }
                                        )}
                                    >
                                        <ContentCard workout={item} />
                                    </TouchableOpacity>
                                )}
                            />
                            <View style={styles.titleCtn}>
                                <Title> Featured Videos</Title>
                                <Button
                                    onPress={() => props.navigation.navigate("Search")}
                                >See all</Button>
                            </View>
                            <FlatList
                                numColumns={1}
                                horizontal={true}
                                data={publicOnDemandVideos}
                                renderItem={({ item }) => (
                                    <TouchableOpacity
                                        style={styles.containerImage}
                                        onPress={() => props.navigation.navigate(
                                            'Content', {
                                                screen: 'OnDemandWorkout',
                                                params: {workoutId: item.id},
                                            }
                                        )}
                                    >
                                        <OnDemandCardMini onDemandVideo={item} />
                                    </TouchableOpacity>
                                )}
                            />
                        </View>
                    )}
                </View>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    <View style={styles.sectionBlock}>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktop : styles.title]}>What is <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktopColor : styles.titleColor]}>Move?</Title></Title>
                    </View>
                    <View style={styles.sectionBlock}>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>Move is a marketplace to discover or sell quality health and fitness content</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>Move is perfect for a variety of sports from Yoga, Pilates, Home workouts, Gym, Running or Basketball</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>On move you can discover or sell on demand videos, workout programs and even build challenges to complete</Text>
                    </View>
                </View>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    <View style={styles.sectionBlock}>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktop : styles.title]}>Earn from your fitness content</Title>
                    </View>
                    <View style={styles.sectionBlock}>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>If you're a health & fitness content creator, Move is the best platform to monetize your fitness content</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>Easily charge a monthly subscription for your clients to access your premium content.</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>Upload your content, conntect with Stripe and start earning monthly subscription revenue within 2 minutes</Text>
                    </View>
                    <View>
                        <Button 
                            mode='contained'
                            style={{margin: 10}}
                            onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                        >Sell content</Button>
                    </View>
                </View>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    <View style={styles.sectionBlock}>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktop : styles.title]}>Create</Title>
                    </View>
                    <View style={styles.sectionBlock}>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>Easily create playable and trackable workout programs, completable challenges or upload a library of on demand videos</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center', marginBottom: 10}}>All content can be individually selected as private or public, paid or free</Text>
                        <Text style={{fontStyle: 'italic', textAlign: 'center'}}>Share and complete with your friends or clients</Text>
                    </View>
                    <View>
                        <Button 
                            mode='contained'
                            style={{margin: 10}}
                            onPress={() => props.navigation.navigate("Add")}
                        >Create</Button>
                    </View>
                </View>
                <View style={[Dimensions.get('window').width > 800 ? styles.contentDesktop : styles.content]}>
                    <View style={styles.sectionBlock}>
                        <Title style={[Dimensions.get('window').width > 800 ? styles.titleDesktop : styles.title]}>Need some help?</Title>
                    </View>
                    <View style={styles.sectionBlock}>
                        <Text style={{fontStyle: 'italic', textAlign: 'center'}}>Vist our FAQ page</Text>
                    </View>
                    <View>
                        <Button 
                            mode='outlined'
                            style={{margin: 10}}
                            onPress={() => props.navigation.navigate('faq')}
                        >FAQs</Button>
                    </View>
                    <View style={{marginBottom: 30, marginTop: 40, flexDirection: 'column', alignItems: 'center'}}>
                        <AppStoreBadges />
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#E8ECF2",
        flexDirection: "column",
        alignItems: "center",
        flex: 1, 
        justifyContent: 'center', 
    },
    contentCtn: {
        maxWidth: 1000,
		width: Dimensions.get('window').width * 1,
    },
    content: {
        margin: 20
    },
    contentDesktop: {
        margin: 60
    },
    sectionBlock: {
        marginTop: 15,
        marginBottom: 15,
        flexDirection: "column",
        justifyContent: "center"
    },
    titleDesktop: {
        fontSize: fontSize,
        textAlign: 'center',
        marginBottom: 10
    }, 
    title: {
        textAlign: 'center',
        fontSize: fontSize,
    }, 
    titleDesktopColor: {
        fontSize: fontSize,
        textAlign: 'center',
        color: "#008080",
    }, 
    titleColor: {
        textAlign: 'center',
        color: "#008080",
        fontSize: fontSize,
    }, 
    loadingCtn: {
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        minHeight: Dimensions.get('window').height * 0.50,
    },
    imageStyle: {
        width: 200, height: 200
    },
    imageStyleDesktop: {
        width: 300, height: 300
    },
    titleCtn: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
        marginTop: 20
    },
    containerImage: {
        // flex: 1 / 3,
        // marginBottom: 20
    },
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    challenges: store.userState.challenges,
    feed: store.usersState.feed,
    usersFollowingLoaded: store.usersState.usersFollowingLoaded,
})
export default connect(mapStateToProps, null)(Feed);