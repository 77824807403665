import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Subheading, Title } from 'react-native-paper';

export default function SessionSelectedWorkout({ dayNum, exerciseNum, play, errorMessage, startWorkout }) {

    return (
        <View style={styles.container}>
            <View style={styles.ctn}>
                <View style={styles.ctnSection}>
                    <Subheading>Day: </Subheading>
                    <Title>{dayNum}</Title>
                </View>
                <View style={styles.ctnSection}>
                    <Subheading>Exercises: </Subheading>
                    <Title>{exerciseNum}</Title>
                </View>
            </View>
            <View style={styles.btnCtn}>
                <Button
                    mode="contained"
                    style={{width: 200}}
                    onPress={() => startWorkout()}
                    disabled={play}
                    loading={play}
                >Start workout</Button>
                { errorMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 5, textAlign: 'center'}}>{errorMessage}</Subheading>}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 2,
        borderRadius: 5,
        borderColor: '#008080',
        padding: 30
    },
    ctn: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 30
    },
    ctnSection: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    btnCtn: {
        flexDirection: 'column',
        alignItems: 'center'
    }
})

