import React, { useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';


export default function WebFilePicker({setMediaFile}) {
    const inputRef = useRef(null)

    const uploadFile = async (evt) => {
        const fileToUpload = await inputRef.current.files[0]
        setMediaFile(fileToUpload)
    }


    return (
        <View>
            <label>
                <input type="file" onChange={uploadFile} ref={inputRef} />
            </label>
        </View>
    )
}

const styles = StyleSheet.create({

});

