import { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, IconButton, Subheading, Text, Title } from 'react-native-paper';
import SelectWeek from '../../CustomComponents/SelectWeek/SelectWeek';
import CreateEditExercise from '../CreateEditExercise';

import ExerciseCard from '../../exercises/ExerciseListCard';

const _ = require('lodash');

export default function CreateExercises({ workout, setWorkout }) {

    const [step, setStep] = useState(0)
    const [editExerciseIndex, setEditExerciseIndex] = useState(null)
    const [editDayIndex, setEditDayIndex] = useState(null)
    const [selectedWeek, setSelectedWeek] = useState(0)
    const [selectDeleteWeek, setSelectDeleteWeek] = useState(false)
    const [addExercise, setAddExercise] = useState(false)

    const addNewWeek = () => {
        let newWeeks = _.cloneDeep(workout);
        const nextWeekNum = workout["weeks"].length + 1
        const newWeek = {
            "week": nextWeekNum.toString(),
            "completed": false,
            "days": [
                {
                    "day": "1",
                    "completed": false,
                    "exercises": []
                }
            ]
        }
        newWeeks.weeks.push(newWeek)
        setWorkout(prevState => ({
            ...prevState, ...newWeeks
        }))
        setSelectedWeek(parseInt(nextWeekNum - 1))
    }

    const showDelete = () => setSelectDeleteWeek(true);

    const showAddExercise = (dayIndex) => {
        setEditDayIndex(dayIndex)
        // setAddExercise(true)
    }

    const deleteWeek = () => {
        console.log("Delete week")
        if (workout.weeks.length < 2) {
            return
        } else {
            let newWorkout = _.cloneDeep(workout);
            newWorkout.weeks.splice(selectedWeek, 1)
            setWorkout(prevState => ({
                ...prevState, ...newWorkout
            }))
        }
    }

    const addDay = () => {
        let newWorkout = _.cloneDeep(workout);
        const newWeek = workout.weeks[selectedWeek]
        const nextDayNum = newWeek.days.length + 1
        const newDay =  {
            "name": "",
            "completed": false,
            "exercises": [],

        }
        // newWorkout.weeks[selectedWeek].days.splice(dayIndex + 1, 0, newDay)
        newWorkout.weeks[selectedWeek].days.push(newDay)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))

    }

    const deleteDay = (dayIndex) => {
        console.log("DeleteDay ", dayIndex)
        let newWorkout = workout
        newWorkout.weeks[selectedWeek].days.splice(dayIndex, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
    }

    const editDay = (dayIdx, exIdx) => {
        setEditExerciseIndex(exIdx)
        setEditDayIndex(dayIdx)
    }

    const closeEditExercise = () => {
        setEditExerciseIndex(null)
        setEditDayIndex(null)
    }

    const deleteExercise = (dayIdx, exIdx) => {
        console.log("delete ", dayIdx, exIdx)
        let newWorkout = workout
        newWorkout.weeks[selectedWeek].days[dayIdx].exercises.splice(exIdx, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        closeEditExercise()
    }

    return (
        <View>
            <SelectWeek 
                weeks={workout.weeks} 
                addWeek={addNewWeek} 
                selectedWeek={selectedWeek} 
                setSelectedWeek={setSelectedWeek} 
            />

            <View style={styles.weekTitleCtn}>
                <Title>Week {selectedWeek + 1}</Title>
                <View>
                    {
                        !selectDeleteWeek ? (
                            <IconButton
                                color="red"
                                icon="delete"
                                size={20}
                                onPress={showDelete}
                            />
                        ):(
                            <Button
                                mode="contained"
                                onPress={deleteWeek}
                                color="red" 
                            >
                                Delete Week
                            </Button>
                        )
                    }
                </View>
            </View>

            <View style={styles.contentCtn}>
                {
                    workout.weeks[selectedWeek].days.map((day, ix) => (
                        <View>
                            <View style={styles.dayTitleCtn}>
                                <Subheading>Day {ix + 1}</Subheading>
                                <IconButton
                                    color="red"
                                    icon="delete"
                                    size={20}
                                    onPress={() => deleteDay(ix)}
                                />
                            </View>

                            <View>
                                {
                                    day.exercises.length > 0 ? (
                                        <View>
                                            {
                                                day.exercises.map((exercise, eidx) => (
                                                    <TouchableOpacity 
                                                        style={styles.exerciseCard}
                                                        onPress={() => editDay(ix, eidx)}
                                                    >
                                                        {
                                                            ix === editDayIndex && eidx === editExerciseIndex ? (
                                                                <CreateEditExercise 
                                                                    edit={true} 
                                                                    exercise={exercise} 
                                                                    workout={workout} 
                                                                    setWorkout={setWorkout} 
                                                                    weekIndex={selectedWeek} 
                                                                    dayIndex={ix} 
                                                                    exIndex={eidx}
                                                                    display={showAddExercise}
                                                                    deleteExercise={deleteExercise}
                                                                    close={closeEditExercise} 
                                                                />
                                                                
                                                            ):(
                                                                <ExerciseCard exercise={exercise} />
                                                            )
                                                        }
                                                    </TouchableOpacity>
                                                ))
                                            }
                                        </View>
                                    ):(null)
                                }
                            </View>

                            <View>
                                {
                                    ix === editDayIndex ? (
                                        <View>
                                            <CreateEditExercise 
                                                workout={workout} 
                                                setWorkout={setWorkout} 
                                                weekIndex={selectedWeek} 
                                                dayIndex={ix} 
                                                display={showAddExercise} 
                                                close={closeEditExercise}
                                            />
                                        </View>
                                    ) : (
                                        <TouchableOpacity style={styles.addExercise} onPress={() => showAddExercise(ix)}>
                                            <View style={styles.innerAddExercise}>
                                                <IconButton
                                                    color="#bdbdbd"
                                                    icon="plus"
                                                    size={20}
                                                />
                                                <Text style={{color: '#bdbdbd'}}>Add Exercise</Text>
                                            </View>
                                        </TouchableOpacity>
                                    )
                                }
                            </View>
                        </View>
                    )
                )}
            </View>
            
            <View style={styles.contentCtn}>
                <Button
                    style={{marginTop: 20}}
                    mode="contained"
                    onPress={() => addDay()} 
                >
                    Add Day
                </Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({

    contentCtn: {
        marginTop: 20
    },
    weekTitleCtn: {
        marginTop: 15,
        marginBottom: 15,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dayTitleCtn: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    addExercise: {
        borderStyle: 'dotted',
        borderWidth: 1,
        borderRadius: 1,
        borderColor: '#bdbdbd',
        backgroundColor: '#eeeeee',
        color: '#bdbdbd',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerAddExercise: {
        flexDirection: 'column',
        alignItems: 'center',
        margin: 15
    },
    exerciseCard: {
        marginTop: 10,
        marginBottom: 10,
    }

});