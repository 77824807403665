import { MOVE_URL } from "@env"
import { useNavigation } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import * as WebBrowser from 'expo-web-browser'
import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import React, { useEffect, useState } from 'react'
import { ActivityIndicator, Image, Platform, StyleSheet, View } from 'react-native'
import { Button, Subheading, Text, Title } from 'react-native-paper'
import { connect } from 'react-redux'

function WebSubscribe(props) {
    const navigation = useNavigation();
    const [subscriptionInfo, setSubscriptionInfo] = React.useState(null);
    const [message, setMessage] = useState(null);
    const [urlSuccess, setUrlSuccess] = useState('');
    const [urlFailed, setUrlFailed] = useState('');
    const [status, setStatus] = useState('subscribing');
    const [loading, setLoading] = useState(false);
    const [updateSubscriptionLoading, setUpdateSubscriptionLoading] = useState(false);

    useEffect(() => {

        (async () => {
            if (Platform.OS === 'web') {
                if (props.currentUser) {
                    setUrlSuccess(`${MOVE_URL}/subscribe/?sub_status=success&subscribed_user_uid=${props.creator_uid}&subscribing_user_uid=${firebase.auth().currentUser.uid}`)
                    setUrlFailed(`${MOVE_URL}/subscribe/?sub_status=failed&subscribed_user_uid=${props.creator_uid}&subscribing_user_uid=${firebase.auth().currentUser.uid}`)
                }
            } else {
                // Stripe does not accept deeplink
                // https://stackoverflow.com/questions/68695786/stripe-connect-account-return-url-link-back-to-expo-application
                // Todo: Create Onboarding on Mobile
                // Create a DeepLink to our App
                if (props.currentUser) {
                    const successDeepLink = Linking.createURL('subscribe', {
                        queryParams: { 
                            sub_status: 'success',
                            subscribed_user_uid: props.creator_uid,
                            subscribing_user_uid: firebase.auth().currentUser.uid,
                        },
                    });
                    const failedDeepLink = Linking.createURL('subscribe', {
                        queryParams: { 
                            sub_status: 'failed', 
                            subscribed_user_uid: props.creator_uid,
                            subscribing_user_uid: firebase.auth().currentUser.uid,
                        },
                    });
                    setUrlSuccess(successDeepLink)
                    setUrlFailed(failedDeepLink)
                }
            }
        })();

        if (props.currentUser) {
        } else {
            if (props.sub_status === 'success') {
                // should do nothing as this is where we are returned from stripe
            } else {
                const unsubscribe = navigation.addListener('focus', () => {
                    // ⚠️ WARNING 
                    // You have a critical part of your app with janky logic
                    // This code is in place to force a retrigger when you get navigated
                    // here erroneously because react navigation web is a pain to deal with
                    navigation.navigate('Home', {
                        screen: 'Main',
                        params: {
                            screen: 'Feed'
                        },
                    });
                })
                return unsubscribe
            }
        }
        if (props) {
            if (props.sub_status === 'success') {
                setUpdateSubscriptionLoading(true)
                setStatus('success')

                if (!props.subscribed_user_uid || !props.subscribing_user_uid) {

                } else {
                    firebase.firestore()
                        .collection("userSubscribers")
                        .doc(props.subscribed_user_uid)
                        .collection("usersSubscribers")
                        .doc(props.subscribing_user_uid)
                        .set({})
                        .then((result) => {
                            setUpdateSubscriptionLoading(false)
                        })
                        .catch((error) => {
                            setMessage("Error saving users subscribers. Please contact support")
                        })

                    firebase.firestore()
                        .collection("userSubscriptions")
                        .doc(props.subscribing_user_uid)
                        .collection("subscriptions")
                        .doc(props.subscribed_user_uid)
                        .set({})
                        .then((result) => {
                            setUpdateSubscriptionLoading(false)
                        })
                        .catch((error) => {
                            setMessage("Error saving users subscription. Please contact support")
                        })
                }

            } else if (props.sub_status === 'failed') {
                setStatus('failed')
            } else {
            }
        }

        getSubscriptionInfo()
    }, [])

    const getSubscriptionInfo = () => {
        setMessage(null)
        firebase.firestore()
            .collection("userStripeAccount")
            .doc(props.creator_uid)
            .get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    setSubscriptionInfo(snapshot.data())
                }
                else {
                    setMessage("User has not set up or removed subscription")
                }
            })
            .catch((error) => {
                setMessage("Error getting users subscription data. Please try again later")
            })
    }


    const subscribe = () => {
        setMessage(null)
        if (!props.currentUser) {
            navigation.navigate("Login")
        } else {
            setLoading(true)
            const createAcc = firebase.functions().httpsCallable('createStripeSubscription');
            createAcc({
                price_id: subscriptionInfo.stripe_price_id, 
                connected_account: subscriptionInfo.stripe_acc, 
                return_url_success: urlSuccess, 
                return_url_failed: urlFailed
            })
            .then( result => {
                Linking.openURL(result.data.url)
                    .catch(err => console.error('An error occurred', err));

                setLoading(false)
                // handleOpenWithWebBrowser(result.data.url)
            })
            .catch((error) => {
                setLoading(false)
                setMessage("Error subscribing to this user")
                console.error(error)
            })
        }
    }

    const handleOpenWithWebBrowser = (url) => {
        WebBrowser.openBrowserAsync(url);
    };

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                {
                    status === 'subscribing' &&
                    <View>
                        { subscriptionInfo !== null ? (
                            <View style={styles.subCtn}>
                                <Title style={{marginBottom: 30}}>Subscribe to {props.creator.name}</Title>
                                <Subheading style={{marginBottom: 30}}>{subscriptionInfo.currency_symbol} {subscriptionInfo.price} {subscriptionInfo.currency} billed monthly</Subheading>
                                { message && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{message}</Text>}
                                <Button
                                    mode="contained"
                                    onPress={() => subscribe()}
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Subscribe
                                </Button>
                            </View>

                        ):(
                            <View style={styles.userLoading}>
                                <ActivityIndicator size="large" color="#008080" />
                                <Text>Loading User info</Text>
                                { message && <Text style={{marginTop: 10, color: 'red', marginBottom: 10}}>{message}</Text>}
                            </View>
                        )}
                    </View>
                }

                {
                    status === 'success' &&
                    <View>
                        {
                            updateSubscriptionLoading ? (
                                <View style={styles.subSuccess}>
                                    <ActivityIndicator style={{marginBottom: 30}} size="large" color="#008080"  />
                                    <Subheading>Saving your subscription. Please wait</Subheading>
                                </View>
                            ): (
                                <View style={styles.subSuccess}>
                                    <Image
                                        style={{ width: 100, height: 100, marginBottom: 20 }}
                                        source={require('../../assets/tick-white-background.png')}
                                        /> 
                                    <Title style={{ marginBottom: 20}}>Subscribe success!</Title>
                                    <Text style={{ marginBottom: 30}}>Your monthly subscription is now active</Text>
                                    <Button
                                        style={{ marginBottom: 30}}
                                        mode="outlined"
                                        onPress={() => 
                                            navigation.navigate('Home', {
                                                screen: 'Main',
                                                params: {
                                                    screen: 'Profile',
                                                    params: {
                                                        uid: props.subscribed_user_uid
                                                    }
                                                },
                                            })
                                        }
                                    >See subscription</Button>
                                    <Button
                                        mode="contained"
                                        onPress={() => 
                                            navigation.navigate('Home', {
                                                screen: 'Main',
                                                params: {
                                                    screen: 'Feed',
                                                },
                                            })
                                        }
                                    >Home</Button>
                                </View>
                            )}
                    </View>
                }

                {
                    status === 'failed' &&
                    <View style={styles.subFailed}>
                        <Title style={{ marginBottom: 20}}>Subscription failed</Title>
                        <Text>There was an error setting up your subscription. Please try again later.</Text>
                        { message && <Text style={{marginTop: 10, marginBottom: 30, color: 'red'}}>{message}</Text>}
                        <Button
                            icon="home"
                            mode="contained"
                            onPress={() => 
                                navigation.navigate('Home', {
                                    screen: 'Main',
                                    params: {
                                        screen: 'Feed',
                                    },
                                })
                            }
                        >Home</Button>
                    </View>
                }
            </View>
        </View>

    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    content: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    subCtn: {
        minHeight: "50%",
        backgroundColor: 'white',
        padding: '5%',
        flexDirection: "column",
        justifyContent: "space-around",
        borderRadius: 10,
        width: "90%"
    },
    subFailed: {
        padding: "5%"
    },
    subSuccess: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    challenges: store.userState.challenges,
    subscriptions: store.userState.subscriptions,
})

export default connect(mapStateToProps, null)(WebSubscribe);