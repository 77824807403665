import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Paragraph, Title } from 'react-native-paper';

const WhyDoIHaveToBuyCoins = (props) => {
  return (
    <View style={styles.container}>
        <Button onPress={() => props.navigation.goBack()} style={{marginBottom: 10}}>Go Back</Button>
        <Card style={styles.card}>
          <Card.Content>
            <Title style={{marginBottom: 10, textAlign: 'center'}}>Why do I have to buy coins</Title>
            <Paragraph style={{textAlign: 'center', marginBottom: 10}}>Because Apple makes us. Apple requires that they take a 30% cut of every transaction inside an app if you're selling digital goods. Physical goods are excempt which is why apps like Uber and Airbnb are exempt. </Paragraph>
            <Paragraph style={{textAlign: 'center', marginBottom: 10}}>Despite the 30% markup, they also don't provide support for user to user businesses. So in order to allow our fantastic creators to get paid, they require us to create our own digital currency and pay creators out that way.</Paragraph>
          </Card.Content>
        </Card>
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  card: {
    marginBottom: 20,
    alignItems: 'center',
  },
});

export default WhyDoIHaveToBuyCoins;
