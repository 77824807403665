import * as Analytics from 'expo-firebase-analytics';
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { Dimensions, FlatList, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Chip, Divider, Subheading, Text, Title } from 'react-native-paper';
import AppStoreBadges from '../appstoreBadge';
import SocialMediaLinks from '../Socials/SocialMediaLinks';
import CreatorCard from './CreatorCard';

const { width } = Dimensions.get('window');

let fontSize = 20; // default font size

// adjust font size based on screen width
if (width < 400) {
    fontSize = 28;
} else if (width >= 400 && width < 600) {
    fontSize = 30;
} else if (width >= 600 && width < 850) {
    fontSize = 32;
} else {
    fontSize = 40;
}

export default function DiscoverCreators(props) {
  const [categories, setCategories] = useState([{"category": "Home workout", "icon": "weight-lifter", "nav": "home-workout"}, {"category": "Yoga", "icon": "yoga", "nav": "yoga"}, {"category": "Pilates", "icon": "yoga", "nav": "pilates"}, {"category": "Gym", "icon": "dumbbell", "nav": "gym"}])
  const [homeWorkout, setHomeWorkout] = useState(null);
  const [yoga, setYoga] = useState(null);
  const [pilates, setPilates] = useState(null);
  const [gym, setGym] = useState(null);

  useEffect(() => {
    (async() => {
      await Analytics.logEvent('[Directory]: Root Viewed');
    })();
    getCreators()
  }, []);

  const getCreators = async() => {
    const db = getFirestore();
    getYoga(db)
    getGym(db)
    getHomeWorkout(db)

  }

  const getYoga = async(db) => {
    const q = query(collection(db, "creatorDirectory"), where("categories", "array-contains", "yoga"), limit(5));
    const querySnapshot = await getDocs(q);
    let resp = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const id = doc.id;
      return{id, ...data}
    })
    setYoga(resp)
  }

  const getHomeWorkout = async(db) => {
    const q = query(collection(db, "creatorDirectory"), where("categories", "array-contains", "home-workout"), limit(5));
    const querySnapshot = await getDocs(q);
    let resp = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const id = doc.id;
      return{id, ...data}
    })
    setHomeWorkout(resp)
  }

  const getGym = async(db) => {
    const q = query(collection(db, "creatorDirectory"), where("categories", "array-contains", "gym"), limit(5));
    const querySnapshot = await getDocs(q);
    let resp = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const id = doc.id;
      return{id, ...data}
    })
    setGym(resp)
  }

  return (
    <ScrollView>
    <View style={styles.root}>
      <View style={styles.setCtnWidth}>
        <View style={styles.headingCtn}>
          <Title style={{marginBottom: 20, fontSize: fontSize, textAlign: "center"}}>Discover Online Fitness Instructors</Title>
          <ScrollView horizontal={true} style={{marginBottom: 20}}>
            <Chip icon="weight-lifter" mode="outlined" style={{margin: 5}} onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'home-workout'})}>Home workout</Chip>
            <Chip icon="yoga" mode="outlined" style={{margin: 5}} onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'yoga'})}>Yoga</Chip>
            <Chip icon="yoga" mode="outlined" style={{margin: 5}} onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'pilates'})}>Pilates</Chip>
            <Chip icon="dumbbell" mode="outlined" style={{margin: 5}} onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'gym'})}>Gym</Chip>
          </ScrollView>
          <Text onPress={() => props.navigation.navigate("NewInstructor")} style={{textDecoration: 'underline', marginBottom: 20, textAlign: "center"}}>Add your business</Text>
        </View>

        <View style={styles.headingCtn}>
          <View style={styles.titleCtn}>
            <Title style={{ fontSize: "x-large" }}>Home workouts</Title>
            <Button
              onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'home-workout'})}
            >See all</Button>
          </View>
          <FlatList
              numColumns={1}
              horizontal={true}
              data={homeWorkout}
              renderItem={({ item }) => (
                  <TouchableOpacity
                      style={styles.containerImage}
                  >
                      <CreatorCard creator={item} />
                  </TouchableOpacity>
              )}
          />
        </View>

        <View style={styles.headingCtn}>
          <View style={styles.titleCtn}>
            <Title style={{ fontSize: "x-large" }}>Gym</Title>
            <Button
                onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'yoga'})}
            >See all</Button>
          </View>
          <FlatList
            numColumns={1}
            horizontal={true}
            data={gym}
            renderItem={({ item }) => (
                <TouchableOpacity
                    style={styles.containerImage}
                >
                    <CreatorCard creator={item} />
                </TouchableOpacity>
            )}
          />
        </View>

        <View style={styles.headingCtn}>
          <View style={styles.titleCtn}>
            <Title style={{ fontSize: "x-large" }}>Yoga</Title>
            <Button
                onPress={() => props.navigation.navigate("CategoryInstructors", {category: 'yoga'})}
            >See all</Button>
          </View>
          <FlatList
            numColumns={1}
            horizontal={true}
            data={yoga}
            renderItem={({ item }) => (
                <TouchableOpacity
                    style={styles.containerImage}
                >
                    <CreatorCard creator={item} />
                </TouchableOpacity>
            )}
          />
        </View>

        <Divider style={styles.divider} />

        <View style={styles.marketing}>
          
          <Title style={{marginBottom: 30, fontSize: 'x-large', textAlign: 'center'}}>Looking to make more money selling fitness content online?</Title>
          <Subheading style={{marginBottom: 30, textAlign: 'center'}} >Move Fitness is a free online fitness content marketplace for fitness creators. Earn more online and grow your audience with Move</Subheading>
          <Button 
            onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
            mode="contained"
            style={{marginBottom: 30}}
          >
              Find out more
          </Button>
        </View>
        <View style={styles.socials}>
          <SocialMediaLinks />
        </View>

        <Divider style={styles.divider} />

        <View style={styles.footer}>
          <View style={styles.footerCtn}>
              <View style={{marginBottom: 30, marginTop: 30}}>
                  <Title style={{textAlign: 'center'}}>Made with ❤️ by 
                      <Title> </Title>
                      <Title
                          onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                          style={{textDecorationLine: 'underline', textAlign: 'center'}}
                      >Move Fitness App</Title>
                  </Title>
              </View>
              <View style={{marginBottom: 15}}>
                  <Subheading style={{textAlign: 'center'}}>Easiest subscription platform for fitness creators and businesses</Subheading>
              </View>
              <View style={{marginBottom: 15}}>
                  <Subheading style={{textAlign: 'center'}}>Workouts • Challenges • On Demand videos</Subheading>
              </View>
              <View style={{marginBottom: 30, marginTop: 30}}>
                  <AppStoreBadges />
              </View>
          </View>
        </View>

      </View>
    </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  setCtnWidth: {
      maxWidth: 800,
      width: "100%",
      padding: 20,
      
  },
  divider: {
    marginTop: 100,
    marginBottom: 100
  },
  titleCtn: {
    backgroundColor: 'white',
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15
  },
  headingCtn: {
    marginBottom: 30,
    marginTop: 30
  },
  footer: {
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E8ECF2",
  },
  footerCtn: {
      maxWidth: Dimensions.get('window').width * 0.75,
      flexDirection: 'column',
      alignItems: 'center',
  },
  marketing: {
    marginTop: 30,
    marginBottom: 30,
  },
  socials: {
    marginTop: 30,
    marginBottom: 30,
  },
  containerImage: {
    marginRight: 10,
  }
})
