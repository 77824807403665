import React from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Chip, Searchbar, Subheading, Text, Title } from 'react-native-paper';

const Item = ({ title}) => (
    <View style={styles.item}>
        <Text style={styles.title}>{title}</Text>
    </View>
);

export default function CreateWorkoutCategory({onChangeSearch, searchQuery, categories, categorySelected, editCategory}) {

    const renderItem = ({ item }) => (
        <TouchableOpacity onPress={() => editCategory(item.id)}>
            <Item title={item.title}/>
        </TouchableOpacity>
    );

    return (
        <View>
            <Title style={{marginBottom: 15}}>Category?</Title>
            <Subheading style={styles.explainerText}>Choose the most relevant category</Subheading>
            <Searchbar
                placeholder="E.g. Gym, Yoga"
                onChangeText={onChangeSearch}
                value={searchQuery}
                style={{ marginBottom: 15}} 
            />
            <View style={{ flexDirection: 'row'}}>
                {
                    categories.map( cat => (
                        <Chip 
                            style={{ maxWidth: 100, margin: 10}} 
                            icon={cat.icon} 
                            onPress={() => console.log('Todo delete')}
                        >
                            {cat}
                        </Chip>
                    ))
                }
                </View>
                <FlatList
                    data={categorySelected}
                    renderItem={renderItem}
                    keyExtractor={item => item.id}
                />
        </View>
    )
}

const styles = StyleSheet.create({
    item: {
        backgroundColor: 'white',
        borderRadius: 10,
        textAlign: 'center',
        padding: 2,
    },
    title: {
        fontSize: 20,
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
});