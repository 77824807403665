import React, {useState, useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator, ScrollView } from 'react-native'
import { Title, Button, Snackbar } from 'react-native-paper';

import ExerciseCard from './ExerciseListCard';

export default function Exercises(props) {
    const [workout, setWorkout] = useState(null)
    const [following, setFollowing] = useState(false)
    const [visible, setVisible] = React.useState(false);
    const [challenge, setChallenge] = React.useState(false);
    const [challengeId, setChallengeId] = React.useState(null);
    const [workoutId, setWorkoutId] = React.useState(null);
    const [weekIndex, setWeekIndex] = React.useState(null);
    const [dayIndex, setDayIndex] = React.useState(null);

    useEffect(() => {
        setWorkout(props.route.params.exercises)
        setFollowing(props.route.params.following)
        if (props.route.params.challenge) {
            setChallenge(true)
            setChallengeId(props.route.params.challengeId)
            setWeekIndex(props.route.params.weekIndex)
            setDayIndex(props.route.params.dayIndex)
        }
    }, [])

    const onToggleSnackBar = () => setVisible(!visible);

    const onDismissSnackBar = () => setVisible(false);

    const startWorkout = () => {
        if (following || challenge) {
            props.navigation.navigate("Session", {
                exercises: workout.exercises, 
                challenge: challenge, 
                challengeId: challengeId,
                challengeData: props.route.params.challengeData,
                weekIndex: props.route.params.weekIndex,
                dayIndex: props.route.params.dayIndex,
                workout: props.route.params.workout, 
                workoutId: props.route.params.workoutId,
            })
        } else {
            onToggleSnackBar()
        }
    }

    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <View style={styles.topCtn}>
                {workout && <Title style={{marginBottom: 15}}>{workout.name}</Title>}
                <Button
                    style={styles.startWorkout}
                    mode="contained"
                    onPress={startWorkout}
                >Start Workout</Button>
                                        <Snackbar
                style={styles.snackbar}
                visible={visible}
                onDismiss={onDismissSnackBar}
                action={{
                    label: 'Ok',
                    onPress: () => {
                        // Do something
                },
                }}>
                    Go back and follow the workout to start!
            </Snackbar>
            </View>
            <ScrollView style={styles.content}>
            {
                workout !== null ? (
                    <View>
                        <Title>{workout.name}</Title>
                        {
                            workout.exercises.map(ex => (
                                <View style={styles.exerciseCtn}>
                                    <ExerciseCard exercise={ex} />
                                </View>
                                
                            ))
                        }
                    </View>
                ):(
                    <View>
                        <ActivityIndicator size="large" color="#008080" />
                    </View>
                )
            }
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    content: {
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    topCtn: {
        marginTop: 15,
        marginBottom: 15,
        padding: 20,
        alignItems: "center",
        justifyContent: "center",
    },
    startWorkout: {
        width: "100%",
        minWidth: "100%"

    },
    exerciseCtn: {
        marginBottom: 15
    },
    snackbar: {

    },
})
