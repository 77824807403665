
import { Picker } from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Button, Chip, Divider, Subheading, Switch, Text, TextInput, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserChallengesJoined, fetchUserWorkouts } from '../../redux/actions';
import { updateUserChallengeExercises } from '../../redux/actions/challenge';
import { updateWorkoutExercises } from '../../redux/actions/workout';
import NumberSelect from '../CustomComponents/NumberSelect/NumberSelect';

function EditExercise({ route, updateWorkoutExercises, updateUserChallengeExercises, fetchUserChallengesJoined, fetchUserWorkouts }) {
    const navigation = useNavigation();
    const { exercise } = route.params
    const { exerciseIndex } = route.params
    const { isChallenge } = route.params
    const { weeks } = route.params
    const { challengeId } = route.params
    const { workoutId } = route.params
    const { weekIndex } = route.params
    const { dayIndex } = route.params
    const [loading, setLoading] = useState(false);
    const [copyExercise, setCopyExercise] = useState(null);
    const [showReps, setShowReps] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showWeight, setShowWeight] = useState(false);
    const [showDistance, setShowDistance] = useState(false);
    const [exName, setExName] = useState('');
    const [exReps, setExReps] = useState('1');
    const [exRepsRest, setExRepsRest] = useState('10');
    const [exSets, setExSets] = useState('1');
    const [exTime, setExTime] = useState('');
    const [exDistance, setExDistance] = useState('3');
    const [exWeight, setExWeight] = useState('5');
    const [exInstructions, setExInstructions] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('kg');
    const [selectedDistance, setSelectedDistance] = useState('km');
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const [repsEdited, setRepsEdited] = useState(false);
    const [timeEdited, setTimeEdited] = useState(false);
    const [distanceEdited, setDistanceEdited] = useState(false);
    const [weightEdited, setWeightEdited] = useState(false);

    useEffect(() => {
        setupExercise()
        const cloneCopyExercise =  _.cloneDeep(exercise);
        setCopyExercise(cloneCopyExercise)
    }, [route.params])

    const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);

    const setupExercise = () => {
        setExName(exercise.name)
        setExInstructions(exercise.instructions)
        if (exercise.reps) {
            setExReps(exercise.reps)
            setExRepsRest(exercise.repsRest)
            setExSets(exercise.sets)
            setShowReps(true)
            setRepsEdited(true)
        }
        if (exercise.time) {
            setExTime(exercise.time)
            setShowTime(true)
            setTimeEdited(true)
        }
        if (exercise.distance) {
            setExDistance(exercise.distance)
            setSelectedDistance(exercise.distanceUOM)
            setShowDistance(true)
            setDistanceEdited(true)
        }
        if (exercise.weight) {
            setExWeight(exercise.weight)
            setSelectedWeight(exercise.weightUOM)
            setShowWeight(true)
            setWeightEdited(true)
        }
    }

    const increaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) + 1
                // Need React Native paper TextInput value only accepts type string :/
                const setsString = newSets.toString()
                setExSets(setsString);
                break;
            case 'reps':
                const newReps = parseInt(exReps) + 1
                const repsString = newReps.toString()
                setExReps(repsString);
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) + 1
                const restString = newRest.toString()
                setExRepsRest(restString);
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) + 1
                const weightString = newWeight.toString()
                setExWeight(weightString);
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) + 1
                const distanceString = newDistance.toString()
                setExDistance(distanceString);
                break;
            default:
                break;
        }
    }

    const decreaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) - 1
                // Need React Native paper TextInput value only accepts type string :/
                if (newSets > 0) {
                    const setsString = newSets.toString()
                    setExSets(setsString);
                }
                break;
            case 'reps':
                const newReps = parseInt(exReps) - 1
                if (newReps > 0) {
                    const repsString = newReps.toString()
                    setExReps(repsString);
                }
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) - 1
                if (newRest > 0) {
                    const restString = newRest.toString()
                    setExRepsRest(restString);
                }
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) - 1
                if (newWeight > 0) {
                    const weightString = newWeight.toString()
                    setExWeight(weightString);
                }
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) - 1
                if (newDistance > 0) {
                    const distanceString = newDistance.toString()
                    setExDistance(distanceString);
                }
                break;
            default:
                break;
        }
    }

    const handleNumberInput = (e, inputType) => {
        switch (inputType) {
            case 'sets':
                // Check only a number has been entered
                if(!isNaN(e)){
                    setExSets(e);
                }
                break;
            case 'reps':
                if(!isNaN(e)){
                    setExReps(e);
                }
                break;
            case 'exRest':
                if(!isNaN(e)){
                    setExRepsRest(e);
                }
                break;
            case 'exWeight':
                if(!isNaN(e)){
                    setExWeight(e);
                }
                break;
            case 'exDistance':
                if(!isNaN(e)){
                    setExDistance(e);
                }
                break;
            default:
                break;
        }
    }

    const validateExercise = () => {
        if (exName.length <= 2) {
            setErrorMessage("Name not enough characters")
            return false
        } else {
            return true
        }
    }

    const constructUpdatedExercise = async () => {
        setErrorMessage(null)
        const validate = await validateExercise()
        if (validate) {
            const updatedExercise =  exercise;
            updatedExercise.name = exName
            updatedExercise.instructions = exInstructions

            if (repsEdited) {
                updatedExercise.reps = exReps
                updatedExercise.repsRest = exRepsRest
                updatedExercise.sets = exSets
            }

            if (timeEdited) updatedExercise.time = exTime
            if (distanceEdited) {
                updatedExercise.distance = exDistance
                updatedExercise.distanceUOM = selectedDistance
            }
            if (weightEdited) {
                updatedExercise.weight = exWeight
                updatedExercise.weightUOM = selectedWeight
            }
    
            return updatedExercise
            
        }
    }

    const createWeeks = async () => {
        const updatedExercise = await constructUpdatedExercise()
        let updatedWeeks = _.cloneDeep(weeks);
        if (isSwitchOn) {
            // Apply exercise update to current and all future weeks
            // Update completed to false to ensure future weeks aren't completed
            updatedExercise.completed = false
            for (let i = weekIndex; i < updatedWeeks.length; i++) {
                try {
                    const currentWeek = updatedWeeks[i];
                    const currentDay = currentWeek.days[dayIndex];
                    
                    const exerciseIndexInArray = currentDay.exercises.findIndex((ex) => {
                        // Super dirty but javascript wouldn't compare identical objects. 
                        // Think you have to stringify
                        if (ex.name === copyExercise.name && ex.instructions === copyExercise.instructions && ex.image === copyExercise.image){
                            return true
                        } else {
                            return false
                        }
                    });
                    
                    if (exerciseIndexInArray !== -1) {
                        currentDay.exercises[exerciseIndexInArray] = updatedExercise;
                    }
                    
                } catch (error) {
                    // Continue without updating that week
                }
            }  
            return updatedWeeks          
            
        } else {
            updatedWeeks[weekIndex].days[dayIndex].exercises[exerciseIndex] = updatedExercise
            return updatedWeeks
        }

    }

    const saveEditExercise = async () => {
        try {
            setLoading(true)
            setErrorMessage(null)
            const newWeeks = await createWeeks()
    
            if (isChallenge) {
                updateUserChallengeExercises(challengeId, newWeeks)
                fetchUserChallengesJoined()
                navigation.goBack()
            } else {
                // Todo check user owns this exercise
                updateWorkoutExercises(workoutId, newWeeks)
                fetchUserWorkouts()
                navigation.goBack()
            }
            
        } catch (error) {
            console.error("update error: ", error)
            setErrorMessage("Error updating exercise. Contact support")
        } finally {
            setLoading(false)
        }
    }

    return (
        <ScrollView>
            <View style={styles.root}>
                <View style={styles.setCtnWidth}>

                    <View style={styles.section}>
                        <Title style={styles.title}>Name</Title>
                        <Text style={styles.explainerText}>Name of exercise e.g. Incline Bench Press</Text>
                        <TextInput
                            label="Exercise name"
                            defaultValue={exercise.name}
                            onChangeText={text => setExName(text)}
                        />
                    </View>

                    <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Chip style={{margin: 3}} icon="plus" onPress={() => setShowReps(!showReps)}>Add Reps</Chip>
                        <Chip style={{margin: 3}} icon="plus" onPress={() => setShowWeight(!showWeight)}>Add Weight</Chip>
                        <Chip style={{margin: 3}} icon="plus" onPress={() => setShowDistance(!showDistance)}>Add Distance</Chip>
                        <Chip style={{margin: 3}} icon="plus" onPress={() => setShowTime(!showTime)}>Add Time</Chip>
                    </View>

                    <View style={styles.section}>
                        {showReps && 
                            <View style={{marginTop: 20, marginBottom: 20}}>
                                <Title style={styles.title}>Sets & Reps</Title>
                                <Text style={styles.explainerText}>Enter Sets & Reps e.g. 3 x 12</Text>

                                <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
                                    <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                        <NumberSelect increase={() => increaseNumberSelect('sets')} decrease={() => decreaseNumberSelect('sets')} count={exSets} handleName='sets' handleWeeks={handleNumberInput} />
                                        <Text>Sets</Text>
                                    </View>
                                    <View>
                                        <Text style={{fontSize: 24}}> X </Text>
                                    </View>
                                    <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                        <NumberSelect increase={() => increaseNumberSelect('reps')} decrease={() => decreaseNumberSelect('reps')} count={exReps} handleName='reps' handleWeeks={handleNumberInput} />
                                        <Text>Reps</Text>
                                    </View>
                                </View>

                                <View style={{marginTop: 20, marginBottom: 20, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center',}}>
                                    <Title>Rest: </Title>
                                    <NumberSelect increase={() => increaseNumberSelect('exRest')} decrease={() => decreaseNumberSelect('exRest')} count={exRepsRest} handleName='exRest' handleWeeks={handleNumberInput} />
                                    <Subheading>seconds</Subheading>
                                </View>
                                <Text style={styles.explainerText}>Enter rest between sets in seconds if any</Text>
                                <Divider style={styles.divider} />
                            </View>
                        }
                        {showWeight && 
                            <View style={{marginTop: 10, marginBottom: 10}}>
                                <Title style={styles.title}>Weight</Title>
                                <Text style={styles.explainerText}>Enter weight for exercise</Text>
                                <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center'}}>
                                    <NumberSelect increase={() => increaseNumberSelect('exWeight')} decrease={() => decreaseNumberSelect('exWeight')} count={exWeight} handleName='exWeight' handleWeeks={handleNumberInput} />
                                    <Picker
                                        style={{width: 100}}
                                        selectedValue={selectedWeight}
                                        onValueChange={(itemValue, itemIndex) =>
                                            setSelectedWeight(itemValue)
                                        }>
                                        <Picker.Item label="kg" value="kg" />
                                        <Picker.Item label="lbs" value="lbs" />
                                    </Picker>
                                </View>
                                <Divider style={styles.divider} />
                            </View>
                        }
                        {showDistance && 
                            <View style={{marginTop: 10, marginBottom: 10}}>
                                <Title style={styles.title}>Distance</Title>
                                <Text style={styles.explainerText}>Enter distance for exercise</Text>
                                <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center'}}>
                                    <NumberSelect increase={() => increaseNumberSelect('exDistance')} decrease={() => decreaseNumberSelect('exDistance')} count={exDistance} handleName='exDistance' handleWeeks={handleNumberInput} />
                                    <Picker
                                        style={{width: 100}}
                                        selectedValue={selectedDistance}
                                        onValueChange={(itemValue, itemIndex) =>
                                            setSelectedDistance(itemValue)
                                        }>
                                        <Picker.Item label="km" value="km" />
                                        <Picker.Item label="mi" value="mi" />
                                    </Picker>
                                </View>
                                <Divider style={styles.divider} />
                            </View>
                        }
                        {showTime && 
                            <View>
                                <Title style={styles.title}>Time</Title>
                                <Text style={styles.explainerText}>Enter time for exercise e.g. 10mins</Text>
                                <TextInput
                                    style={{width: "100%"}}
                                    label="Time"
                                    placeholder="20 - 30 mins"
                                    value={exTime}
                                    onChangeText={text => setExTime(text)}
                                />
                                <Divider style={styles.divider} />
                            </View>
                        }
                    </View>

                    <View style={styles.section}>
                        <TextInput
                            label="Instructions"
                            defaultValue={exInstructions}
                            onChangeText={text => setExInstructions(text)}
                        />
                        <Text style={styles.explainerText}>Optionally enter description of exercise</Text>
                        <Divider style={styles.divider} />
                    </View>

                    <View style={styles.section}>
                        <View style={{ flexDirection: 'column', alignItems: 'center'}}>
                            <Title style={styles.title}>Apply all future weeks?</Title>
                            <Text style={styles.explainerText}>Select to apply changes to all future weeks for this day for this exercise. Off will apply just for this week</Text>
                            <Switch 
                                style={{ marginTop: 30}}
                                value={isSwitchOn} 
                                onValueChange={onToggleSwitch} 
                                color="#008080"
                            />

                        </View>

                    </View>
                    <View style={{marginBottom: 60, marginTop: 10}} >
                        <Button 
                            mode="contained" 
                            color="#008080" 
                            style={{flex: 1}} 
                            onPress={() => saveEditExercise()}
                            disabled={loading}
                            loading={loading}
                        >
                            Save Changes
                        </Button>
                        { errorMessage && <Text style={{marginTop: 10, marginBottom: 10, color: 'red'}}>{errorMessage}</Text>}

                    </View>

                </View>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: 'white',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    setCtnWidth: {
        maxWidth: 800,
        width: "100%",
        padding: 20,
        height: '100%'
        
    },
    section: {
        marginBottom: 30
    },
    title: {
        marginBottom: 15
    },
    divider: {
        marginTop: 15,
        marginBottom: 15
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
});

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ updateUserChallengeExercises, updateWorkoutExercises, fetchUserChallengesJoined, fetchUserWorkouts  }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(EditExercise);