// import firebase from 'firebase/compat/app';
// import "firebase/compat/auth";
import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Subheading, Text, TextInput } from 'react-native-paper';

import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, browserLocalPersistence } from "firebase/auth";

export class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            message: null
        }
        this.onSignUp = this.onSignUp.bind(this)
    }

    signIn(){
        const { email, password } = this.state;


        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            this.props.navigation.navigate("Profile", {uid: user.uid})
            this.setState({
                loading: false
            })
        })
        .catch((error) => {
            console.error(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            this.setState({
                loading: false,
                message: `Error signing in. ${error.message}`
            })
        });

        // firebase.auth().signInWithEmailAndPassword(email, password)
            // .then((result) => {
            //     this.props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
            //     this.setState({
            //         loading: false
            //     })
            // })
        //     .catch((error) => {
        //         this.setState({
        //             loading: false,
        //             message: `Error signing in. ${error.message}`
        //         })
        //     })

    }

    onSignUp(){
        this.setState({
            loading: true,
            message: null
        })
        const { email, password } = this.state;
        
        return this.signIn()

        // const auth = getAuth();
        // setPersistence(auth, browserLocalPersistence)
        // // setPersistence(auth, browserSessionPersistence)
        //   .then(() => {
        //     // Existing and future Auth states are now persisted in the current
        //     // session only. Closing the window would clear any existing state even
        //     // if a user forgets to sign out.
        //     // ...
        //     // New sign-in will be persisted with session persistence.
        //     // return signInWithEmailAndPassword(auth, email, password);
        //     return this.signIn()
        //   })
        //   .catch((error) => {
        //     // Handle Errors here.
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        //   });



        // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        // .then(() => {
        //     // Existing and future Auth states are now persisted in the current
        //     // session only. Closing the window would clear any existing state even
        //     // if a user forgets to sign out.
        //     // ...
        //     // New sign-in will be persisted with session persistence.
        //     // return firebase.auth().signInWithEmailAndPassword(email, password)
        //     return this.signIn()
        // })
        // .catch((error) => {
        //     // Handle Errors here.
        //     console.error(error)
        //     var errorCode = error.code;
        //     var errorMessage = error.message;
        // });



        // firebase.auth().signInWithEmailAndPassword(email, password)
        // .then((result) => {
        //     this.props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
        //     this.setState({
        //         loading: false
        //     })
        // })
        // .catch((error) => {
        //     this.setState({
        //         loading: false,
        //         message: `Error signing in. ${error.message}`
        //     })
        // })


    }

    render(){
        return (
            <View style={styles.container}>
                <TextInput 
                    placeholder="Email"
                    onChangeText={(email) => this.setState({ email })}
                    style={{marginBottom: 10}}
                    left={<TextInput.Icon name="email-outline" />}
                />
                <TextInput 
                    placeholder="Password"
                    secureTextEntry={true}
                    onChangeText={(password) => this.setState({ password })}
                    style={{marginBottom: 20}}
                    left={<TextInput.Icon name="lock-outline" />}
                />

                <Button 
                    onPress={() => this.onSignUp()}
                    mode="contained"
                    disabled={this.state.loading}
                >Sign In</Button>

                { this.state.message && <Text style={{marginTop: 10, marginBottom: 15, color: 'red'}}>{this.state.message}</Text>}

                <View style={styles.subhead}>
                    <Subheading>Don't have an account yet? </Subheading>
                    <Subheading 
                        onPress={() => this.props.navigation.navigate("Register")}
                        style={{textDecoration: 'underline'}} 
                    >
                        Register
                    </Subheading>
                </View>
                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        padding: '5%',
    },
    subhead:{
        flexDirection: 'row',
        marginTop: 30
    }
})

export default Login
