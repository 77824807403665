import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Title, Button } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

export default function NotFound() {
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <Title>404 Sorry page not found</Title>
            <Button
                mode="contained"
                onPress={() => navigation.navigate("Main")}
            >Home</Button>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        padding: '5%',
    },
})
