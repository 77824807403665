import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Switch, View } from 'react-native';
import { Button, Subheading, Text, TextInput } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUser } from '../../redux/actions/index';
import EditProfilePicture from '../media/ProfilePicture/EditProfilePicture';



function EditProfile(props) {
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [paid, setPaid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const { currentUser, workouts, challenges } = props;
        if (props.route.params.uid === null) {
            props.navigation.navigate("Login")
        } else {
            if (props.route.params.uid === firebase.auth().currentUser.uid) {
                setUser(currentUser)
                try {
                    setUsername(currentUser.name)
                    if (currentUser.description) {
                        setDescription(currentUser.description)
                    }
                    if (currentUser.profileImage) {
                        setImage(currentUser.profileImage)
                    }
                    if (currentUser.paid) {
                        setPaid(currentUser.paid)
                    } else {
                        setPaid(false)
                    }
                } catch (error) {
                    
                }
            } else {
                // Not the users account
                props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
            }
        }
    }, [props.currentUser])

    const onTogglePaid = () => {
        if (!paid) {
            props.navigation.navigate("Payment")
        }
        // setPaid(!paid)
    };

    const validateSaveProfile = () => {
        if (!username.length > 0) {
            setMessage("Error: Cannot have empty username")
            setLoading(false)
            return false
        } else {
            return true
        }
    }

    const validateUserName = async () => {
        if (username !== props.currentUser.name) {
            const usersData = await firebase.firestore()
                .collection("users")
                .where("name", "==", username)
                .get()
                .then((snapshot) => {
                    const usernameResults = snapshot.docs
                    if (usernameResults.length > 0) {
                        setMessage("Sorry username taken! Please choose another")
                        setLoading(false)
                        return false
                    } else {
                        return true
                    }
                })
            return usersData
            
        } else {
            return true
        }
    }

    const onLogout = () => {
        firebase.auth().signOut();
        props.navigation.navigate("Login")
    }

    const checkIfImageUploaded = (i) => {
        const imgType = i.substring(0, 4)
        if (imgType === 'http') {
            return true
        } else {
            // should === 'data'.
            // the image has not yet been uploaded
            return false
        }
    }

    const uploadImage = async(imgData) => {
        // Create a root reference
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef
                            .child(imgData.childPath)
                            .put(imgData.blob)
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            console.error(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return uploadTask
    }

    const prepareExerciseImage = async (uri) => {
        const childPath = `profilePicture/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath}
        return coverMedia
    }

    const prepareUploadImage = async (body) => {
        if (image && image.length > 0) {
            const checkIfUploaded = await checkIfImageUploaded(image)
            if (!checkIfUploaded) {
                const createImg = await prepareExerciseImage(image)
                const uploadExImage = await uploadImage(createImg)
                const imgUrl = await uploadExImage.ref.getDownloadURL()                
                const exerciseMedia = {"url": imgUrl, metadata: createImg.metadata}
                if (exerciseMedia) {
                    body.profileImage = exerciseMedia.url
                    return true
                }
            } else {
                return true
            }            
        } else {
            return true
        }

    }

    const saveProfile = async () => {
        setLoading(true)
        setMessage(null)

        let body = {
            name: username,
            paid: paid,
            description: description,
        }
        const saveImage = await prepareUploadImage(body)
        const valid = await validateSaveProfile()
        const validUsername = await validateUserName()
        
        if (valid && validUsername && saveImage) {
            firebase.firestore().collection("users")
                .doc(firebase.auth().currentUser.uid)
                .update(body)
                .then((result) => {
                    setLoading(false)
                    props.fetchUser()
                    props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
                })
                .catch((error) => {
                    setMessage("Error updating please try again")
                    setLoading(false)
                    console.log("error ", error)
                })
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <View style={styles.section}>
                    <EditProfilePicture image={image} updateImage={setImage} />
                </View>
                <View style={styles.section}>
                    <Subheading style={{fontSize: 'large', marginBottom: 10}}>Enable paid content</Subheading>
                    <Switch value={paid} onValueChange={onTogglePaid} disabled={paid} />                    
                    <Text style={styles.section}>Enable this to charge subscription for premium content</Text>
                </View>
                <View style={styles.section}>
                    <TextInput
                        mode="outlined" 
                        placeholder="Username"
                        label="Username"
                        onChangeText={(name) => setUsername(name)}
                        style={{marginBottom: 10}}
                        value={username}
                    />

                    { message && <Text style={{marginTop: 10, color: 'red'}}>{message}</Text>}
                </View>
                <View style={styles.section}>
                    <TextInput
                        mode="outlined" 
                        placeholder="Bio"
                        label="Bio"
                        value={description}
                        onChangeText={(desc) => setDescription(desc)}
                    />
                </View>
                <View style={styles.section}>
                    <Button 
                        icon="content-save-outline" 
                        mode="contained" 
                        onPress={saveProfile}
                        disabled={loading}
                        loading={loading}
                    >
                        Save Profile
                    </Button>
                </View>
                <View style={styles.section}>
                    <Button
                        onPress={() => onLogout()}
                    >
                        Logout
                    </Button>
                </View>
                <View style={styles.section}>
                    <Button
                        icon="delete"
                        color='red'
                        onPress={()=> 
                            props.navigation.navigate('Delete Account',
                            {uid: props.uid}
                        )}
                    >
                        Delete Account
                    </Button>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({


    container: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: '#E8ECF2'
        backgroundColor: 'white'
    },
    content: {
        maxWidth: 800,
        width: "100%",
        padding: 20,
    },
    section: {
        marginBottom: 15,
        marginTop: 15
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },

})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUser }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(EditProfile);



