import * as Linking from 'expo-linking';
import * as Analytics from 'expo-firebase-analytics';
import { Image, StyleSheet, View, TouchableOpacity, Platform } from 'react-native';

export default function AppStoreBadges() {

    const handleLink = async(social) => {
        if (social === 'apple') {
            await Analytics.logEvent('[AppStore]: Apple');
            Linking.openURL("https://apps.apple.com/us/app/move-workout-and-fitness/id1612751956");
        } else if (social === 'google') {
            await Analytics.logEvent('[AppStore]: Google Play');
            Linking.openURL("https://play.google.com/store/apps/details?id=com.moveco.moveapp");
        } else {
            
        }
    };
  return (
    <View style={styles.container}>
        {
            Platform.OS === 'web' ? ( 
                <View>
                    <TouchableOpacity
                        onPress={() => handleLink('apple')}
                    >
                        <Image
                            style={{ width: 200, height: 50, marginBottom: 10 }}
                            source={require('../../assets/appStoreBadges/apple-app-store-badge.png')}
                        /> 
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => handleLink('google')}
                    >
                        <Image
                            style={{ width: 200, height: 50, marginBottom: 10 }}
                            source={require('../../assets/appStoreBadges/google-play-badge.png')}
                        /> 
                    </TouchableOpacity>
                </View>
        ):(null)}
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
        // flexDirection: 'row'
    }
})
