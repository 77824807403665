import * as ImagePicker from 'expo-image-picker';
import React, { useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button, Text, Title } from 'react-native-paper';
import Media from '../../media/index';


export default function CreateWorkoutCoverImg({ determineFileType, image, setImage}) {

    useEffect(() => {
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }, []);

    const pickImage = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            // Change this to .All for videos and images
            // mediaTypes: ImagePicker.MediaTypeOptions.Images,
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.8,
            
        });
        if (!result.cancelled) {
            let uri = result.uri;
            let fileExtension = uri.substring(uri.indexOf('/') + 1, uri.indexOf(';base64'));
            let mediaType = determineFileType(fileExtension)
            const img = {
                url: uri,
                metadata: {"type": mediaType}
            }
            setImage(img);
        }
    };

    return (
        <View>
            <View style={{marginBottom: 20}}>
                <Title>Add Cover Image? *</Title>
                <Text style={styles.explainerText}>Optionally add cover photo or video *</Text>
            </View>
            <View style={styles.content}>
                <Button
                    icon="image"
                    mode="outlined"
                    onPress={pickImage}
                >
                    Pick an image from camera roll
                </Button>
                {image ? <Media contentType={image.metadata.type} url={image.url} component='cover' /> : null}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    explainerText: {
        marginTop: 5,
        marginBottom: 10,
        color: "#9e9e9e",
    },
});