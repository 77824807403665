import * as Analytics from 'expo-firebase-analytics';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, doc, getFirestore, increment, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Card, Paragraph, Snackbar, Text, Title } from 'react-native-paper';

const IOSSubscribeScreen = (props) => {

  const [coins, setCoins] = useState(0);
  const [loading, setLoading] = useState(false);
  const [subscribeCostCoins, setSubscribeCostCoins] = useState(0);
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {

    (async() => {
      await Analytics.logEvent('[IOSSubscribeScreen]: Viewed');
    })();

    getUserCoins();
    calculateSubCostInCoins();
    

  }, [props.route.params])

  /**
   * Get a users coins
   */
  const getUserCoins = async () => {
    const db = getFirestore();
    const auth = getAuth();
    const user_id = auth.currentUser?.uid;
    const docRef = doc(db, 'userCoins', user_id);

    // attach snapshot listener
    onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
          console.log("Document data:", doc.data());
          const data = doc.data()
          setCoins(data.coins)
      } else {
          console.error("No such document!");
      }
      }, (error) => {
      console.error("Error getting document:", error);
      });

  };

  /**
   * Subscribe to a creator with coins
   * Used on IOS apps where stripe not allowed
   * @returns 
   */
  const subscribeWithCoins = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const db = getFirestore();

    if (!currentUser) {
      setError('No signed-in user found.');
      setSnackbarVisible(true);
      return;
    }
    

    if (coins < subscribeCostCoins) {
      setError('Not enough coins, please buy more.');
      setSnackbarVisible(true);
      return;
    }

    setLoading(true)

    const userCoinsRef = doc(db, 'userCoins', currentUser.uid);
    // // Add a document creator's subscribers list
    const userSubscribersRef = doc(
      db,
      'userSubscribers',
      props.route.params.creatorUid,
      'usersSubscribers',
      currentUser.uid
    );
    // // Add a document to users subscriptions
    const userSubscriptionsRef = doc(
      db,
      'userSubscriptions',
      currentUser.uid,
      'subscriptions',
      props.route.params.creatorUid
    );

    try {
      const docRef = await addDoc(collection(db, "userSubscriptionWithCoins"), {
        costInCoins: subscribeCostCoins,
        uid: currentUser.uid,
        created_at: serverTimestamp(),
        creatorName: props.route.params.creatorName,
        creatorUid: props.route.params.creatorUid,
        price: props.route.params.price,
      });
      console.log("Document written with ID: ", docRef.id);


      await setDoc(userCoinsRef, {
        coins: increment(-subscribeCostCoins),
        lastUpdated: serverTimestamp(),
        uid: currentUser.uid,
      }, { merge: true });

    await setDoc(userSubscribersRef, {})
      .then((result) => {
        // setUpdateSubscriptionLoading(false);
      })
      .catch((error) => {
        setError('Error saving users subscribers. Please contact support');
      });

    await setDoc(userSubscriptionsRef, {})
      .then((result) => {
        // setUpdateSubscriptionLoading(false);
      })
      .catch((error) => {
        setError('Error saving users subscription. Please contact support');
      });


      setError('Subscription successful.');

      // Successful subscribe - go to creators profile
      props.navigation.navigate('Home', {
        screen: 'Main',
        params: {
            screen: 'Profile',
            params: { uid: props.route.params.creatorUid },
        },
      })


    } catch (error) {
      setError('Error adding subscription: ' + error.message);
    }
    setSnackbarVisible(true);
    setLoading(false)
  };

  /**
   * Calculates the total coins needed to start a subscription
   * Adds 30% to cover cost of apple tearing us a new one
   * Adds 5% more to cover errors and some currency exchange stuff
   * This is disgusting a needs a major rework to factor in the cost 
   * of coins relative to currency exchange rates
   */
  const calculateSubCostInCoins = () => {
    try {
      const subPrice = props.route.params.price.price;
      const plusAppleDoesntGiveAShtAboutDevelopersTax = subPrice * 1.35;
      const costInCoins = Math.round(plusAppleDoesntGiveAShtAboutDevelopersTax * 100);
      setSubscribeCostCoins(costInCoins);
    } catch (error) {
      console.error("Error getting price of sub");
    }
  };


  return (
    <View style={styles.container}>
      <Snackbar
        visible={snackbarVisible}
        onDismiss={() => setSnackbarVisible(false)}
        duration={Snackbar.DURATION_SHORT}
      >
        {error}
      </Snackbar>
      <Card style={styles.card}>
      {/* <View>
          {
              user.profileImage ? (
                  <ProfilePicture image={user.profileImage} />
              ): (
                  <ProfilePicture image={null} />
              )
          }
      </View> */}


        <Card.Content style={{marginBottom: 30}}>
            <Title style={{marginBottom: 20, textAlign: 'center'}}>Subscribe to {props.route.params.creatorName}</Title>
            <Paragraph style={{textAlign: 'center'}}>Get unlimited access to {props.route.params.creatorName} exclusive workout routines, challenges and on demand video library</Paragraph>
        </Card.Content>

        <Card.Actions style={{flexDirection: 'column', justifyContent: 'center'}}>
          <View>
            <Text style={{marginBottom: 20, textAlign: 'center'}}>Your coins: {coins.toLocaleString()}</Text>
            <Button
              mode="contained"
              onPress={() =>
                props.navigation.navigate("IOSSubscribe", {
                  screen: "BuyCoinsIOS",
                  params: {
                    creatorName: props.route.params.creatorName,
                    // currencySymbol,
                    // priceAmount,
                    creatorUid: props.route.params.creatorUid,
                    isIAR: props.route.params.isIAR,
                    // price,
                  },
                })
              }
            >
              Buy Coins
            </Button>
          </View>
        </Card.Actions>
        <Card.Actions style={{flexDirection: 'column', justifyContent: 'center'}}>

        {props.route.params.isIAR ? (
          <View></View>
          ):( 
            <Button
              onPress={() =>
                props.navigation.navigate("IOSSubscribe", {
                  screen: "WhyDoIHaveToBuyCoins"
                })
              }
            >
              Why do I have to buy coins?
            </Button>
          )}
        </Card.Actions>
        <Card.Actions style={{flexDirection: 'column', justifyContent: 'center'}}>
          {props.route.params.isIAR ? (
            <View>
              <Text style={{marginBottom: 20, textAlign: 'center'}}>Total • {subscribeCostCoins.toLocaleString()} Coins per month</Text>
              <Button 
                mode="contained" 
                onPress={subscribeWithCoins}
                loading={loading}
                disabled={loading}
              >
                Subscribe • {subscribeCostCoins.toLocaleString()} Coins
              </Button>
            </View>
          ):( 
              <Button mode="contained" onPress={() => props.navigation.navigate('Home', {
                screen: 'Main',
                params: {
                    screen: 'Profile',
                    params: { uid: props.route.params.creatorUid },
                },
              })}>
                  Subscribe from users profile
              </Button>
          )}
        </Card.Actions>
        <Card.Actions style={{flexDirection: 'column', justifyContent: 'center'}}>
          {props.route.params.isIAR ? (
            <Button                     
              onPress={() => 
                props.navigation.goBack()
              }
            >
                Back
            </Button>
            ):( 
              <Button onPress={() => props.navigation.navigate('Home', {
                screen: 'Main',
                params: {
                    screen: 'Profile',
                    params: { uid: props.route.params.creatorUid },
                },
              })}>
                  Back to users profile
              </Button>
            )}
        </Card.Actions>

      </Card>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  card: {
    marginBottom: 20,
    alignItems: 'center',
  },
  subscriptionAmount: {
    fontSize: 18,
    marginRight: 10,
  },
});

export default IOSSubscribeScreen;
