import React, { useState  } from 'react';
import { View, StyleSheet } from 'react-native'
import { Picker } from '@react-native-picker/picker';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
    }

export default function CurrencyCountrySelect(props) {
    const [currencies, setCurrencies] = useState([
        { code: 'US', label: 'United States dollar',  currency: 'usd', symbol: '$' },
        { code: 'GB', label: 'British Pound',  currency: 'gbp', symbol: '£' },
        { code: 'AU', label: 'Australian dollar',  currency: 'aud', symbol: '$' },
        { code: 'CA', label: 'Canadian dollar', currency: 'cad', symbol: '$' },
        { code: 'NZ', label: 'New Zealand dollar', currency: 'nzd', symbol: '$' },
        { code: 'AT', label: 'Austria (Euro)', currency: 'eur', symbol: '€' },
        { code: 'BE', label: 'Belgium (Euro)', currency: 'eur', symbol: '€' },
        { code: 'FR', label: 'France (Euro)', currency: 'eur', symbol: '€' },
        { code: 'DE', label: 'Germany (Euro)', currency: 'eur', symbol: '€' },
        { code: 'IT', label: 'Italty (Euro)', currency: 'eur', symbol: '€' },
    ]);
    const [currencyIndex, setCurrencyIndex] = useState(0);

    const handleChange = (itemIndex) => {
        setCurrencyIndex(itemIndex)
        props.handleCurrencyChange(currencies[itemIndex])
    }

    return (
        <View style={styles.container}>

            <Picker
                selectedValue={currencies.currencyIndex}
                style={{ height: 50, width: 180 }}
                onValueChange={(itemValue, itemIndex) => handleChange(itemIndex)}
            >
                {
                    currencies.map(w => (
                        <Picker.Item label={`${countryToFlag(w.code)}    ${w.label}`} value={w.currency} />
                    ))
                }
            </Picker>
        </View>
    )
}

const styles = StyleSheet.create({

});


