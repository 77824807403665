import React from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { Subheading, Card } from 'react-native-paper';

export default function ContentCard({workout}) {
    return (
        <Card style={[Dimensions.get('window').width > 800 ? styles.containerDesktop : styles.container]}>
            { workout.image && workout.image.url.length > 0 ? (
                <Card.Cover source={{uri: workout.image.url}}  />
            ) : (
                <Card.Cover source={require('../../assets/dumbell.png')} resizeMode='contain' style={{padding: 30}} />
            )}
            <Card.Content style={styles.content}>
                <Subheading style={styles.title}>{workout.name}</Subheading>
            </Card.Content>
        </Card>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: 10,
        width: 260,
        height: 260,
        backgroundColor: "white"
    },
    containerDesktop: {
        margin: 10,
        width: 280,
        height: 280,
        backgroundColor: "white"
    },
    content: {
        alignItems: 'center',
        alignContent: 'center'
    },
    title: {
        marginTop: 5,
        marginLeft: 5,
        fontWeight: "bold"
    },
    // img: {
    //     width: '100%', 
    //     height: 120 
    // },
    // imgDesktop: {
    //     width: '100%', 
    //     height: 240 
    // },
    // defaultImg: {
    //     width: '100%', 
    //     height: 120,
    //     padding: 30 
    // },
    // defaultImgDesktop: {
    //     width: '100%', 
    //     height: 240,
    //     padding: 30 
    // }
})