import * as UpChunk from '@mux/upchunk';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Switch, View } from 'react-native';
import { Button, Subheading, Text, TextInput, Title } from 'react-native-paper';
import CustomFilePicker from '../media/CustomPicker/index';

let selectCategories = require('../workouts/CreateWorkout/Categories');


export default function NewOndemandWorkout({ handleEditProfile, currentUser, setStep, fetchUserOnDemandWorkouts  }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [publicWorkout, setPublicWorkout] = useState(true);
    const [paid, setPaid] = useState(false);
    const [paidMessage, setPaidMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [categorySelected, setCategorySelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const SPORTS_CATEGORIES = selectCategories.SPORTS_CATEGORIES
    const [description, setDescription] = useState('');
    const [mediaFile, setMediaFile] = useState(null);
    const [platform, setPlatform] = useState(null);
    const [progress, setProgress] = useState(null)
    const [isPreparing, setIsPreparing] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [assetId, setAssetId] = useState(null)
    const [nativeUploadProgress, setNativeUploadProgress] = useState(0);

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'web') {
                setPlatform('web')
            } else {
                setPlatform('native')
            }
        })();
    }, [])

    const onTogglePublic = () => setPublicWorkout(!publicWorkout);
    const onTogglePaid = () => {
        setPaidMessage(null)
        if (!paid) {
            if (currentUser.paid) {
                setPaid(!paid)
            } else {
                setPaidMessage("Setup paid account first. Click edit below. Only takes a few minutes")
            }
        } else {
            setPaid(!paid)
        }
    };

    const validateOnDemand = () => {

        if (title.length < 2) {
            setErrorMessage("Workout name too short")
            setError(true)
            return false
        } else if (mediaFile === null) {
            setErrorMessage("No file attached yet")
            setError(true)
            return false
        } else {
            return true
        }
    }

    const uploadOnDemand = async () => {
        setErrorMessage(null)
        setUploadLoading(true)
        const valid = await validateOnDemand()
        if (valid) {
            if (platform === 'web') {
                uploadOnDemandWeb()
            } else {
                // Native View
                uploadOnDemandNative()
                // uploadOnDemandWeb()

            }
        } else {
            setUploadLoading(false)
        }
    }

    const uploadOnDemandWeb = async () => {
        setIsUploading(true)
        const muxUrl = await getAuthenticatedMuxUrl()
        const upload = UpChunk.createUpload({
            endpoint: muxUrl.data.url,
            file: mediaFile,
        })

    
        upload.on('error', (err) => {
            setErrorMessage(err.detail)
        })
    
        upload.on('progress', (progress) => {
            setProgress(Math.floor(progress.detail))
        })
    
        upload.on('success', (resp) => {
            setIsPreparing(true)
            getMuxAsset(muxUrl.data.id)

        })
    }

    const uploadOnDemandNative = async () => {
        const imageResponse = await fetch(mediaFile)
        const blob = await imageResponse.blob()
        // Create an authenticated Mux URL
        // this request should hit your backend and return a "url" in the
        // response body
        const muxUrl = await getAuthenticatedMuxUrl()
        try {
          let res = await fetch(muxUrl.data.url, {
                method: 'PUT',
                body: blob,
                headers: { "content-type": blob.type}
            })
            .then(response => {
                getMuxAsset(muxUrl.data.id)
            });
        } catch(error) {
            console.error(error);
        }


    }

    const getAuthenticatedMuxUrl = () => {
        const createAcc = firebase.functions().httpsCallable('getAuthenticatedMuxUrl');
        return createAcc()
            .then( result => {
                setAssetId(result.data.id)
                return result
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const getMuxAsset = (id) => {
        const getVideo = firebase.functions().httpsCallable('getMuxVideoAsset');
        return getVideo({
            upload_id: id, 
        })
        .then( result => {
            const data = {
                user_id: firebase.auth().currentUser.uid,
                asset_id: result.data.id,
                playback_id: result.data.playback_ids[0].id,
                public: publicWorkout,
                paid: paid,
                category: categories,
                by: currentUser.name,
                title: title,
                description: description,
                likesCount: 0,
                creation: firebase.firestore.FieldValue.serverTimestamp()
            }

            // Upload to DB
            firebase.firestore()
                .collection('onDemandWorkout')
                .add(data)
                .then((function () {
                    setStep(1)
                    fetchUserOnDemandWorkouts()
                }))
                .catch((error) => {
                    setErrorMessage("Error saving ondemand")
                });
        })
        .catch((error) => {
            setErrorMessage("Error getting the video from mux")
            console.error(error)
        })
    }

    return (
        <View>
            <Title style={styles.title}>New On Demand workout</Title>

            <View style={styles.contents}>
                <Subheading
                    style={styles.subheading}
                >
                    Workout name
                </Subheading>
                <Text style={styles.explainerText}>Title of your On Demand workout</Text>
                <TextInput 
                    onChangeText={name => setTitle(name)}
                />
            </View>

            <View style={styles.contents}>
                <Subheading style={styles.subheading}>Workout Description</Subheading>
                <Text style={styles.explainerText}>Optionally add description of workout</Text>
                <TextInput
                    onChangeText={text => setDescription(text)}
                    multiline={true}
                />
            </View>

            <View style={styles.contents}>
                <Subheading style={styles.subheading}>Public workout?</Subheading>
                <Text style={styles.explainerText}>Turn off to make private only to you</Text>
                <Switch value={publicWorkout} onValueChange={onTogglePublic} />
            </View>

            <View style={styles.contents}>
                <Subheading style={styles.subheading}>Paid workout?</Subheading>
                <Text style={styles.explainerText}>Toggle on to make workout viewable only to users with subscription to you</Text>
                <Switch value={paid} onValueChange={onTogglePaid} />
                <View>
                    { paidMessage && <Subheading style={{marginTop: 15, color: 'red', marginBottom: 15}}>{paidMessage}</Subheading>}
                    { paidMessage && <Button mode="contained" onPress={() => handleEditProfile()}>Edit Profile</Button>}
                </View>
            </View>

            <View style={styles.contents}>
                <Subheading style={styles.subheading}>Select video</Subheading>
                <Text style={styles.explainerText}>Select a video to add to your on demand library</Text>
                <CustomFilePicker setMediaFile={setMediaFile} />
            </View>

            <View>
                {isUploading ? (
                    <>
                        {isPreparing ? (
                            <View>
                                <Text style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>Almost done. Putting the finishing touches on</Text>
                            </View>
                        ) : (
                            <View>
                                <Text style={{textAlign: 'center'}}>Uploading video...{progress ? `${progress} %` : ''}</Text>
                                <Text style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}}>Large files may take some time</Text>
                            </View>
                        )}
                    </>
                ) : (
                    null
                )}
            </View>


            <View style={styles.submit}>
                <Button
                    mode="contained"
                    onPress={() => uploadOnDemand()}
                    loading={uploadLoading}
                    disabled={uploadLoading} 
                >
                    Submit
                </Button>
                { errorMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 10}}>{errorMessage}</Subheading>}
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        marginTop: 15,
        marginBottom: 30
    },
    subheading: {
        marginBottom: 15
    },
    explainerText: {
        marginTop: 5,
        marginBottom: 10,
        color: "#9e9e9e",
    },
    contents: {
        marginTop: 20,
        marginBottom: 20
    },
    submit: {
        marginTop: 20,
        marginBottom: 40
    }
});