import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Card, Title } from 'react-native-paper';

export default function CreatorCard({creator}) {
    const navigation = useNavigation();

    const navigateToCreator = () => {
        navigation.navigate("IndividualInstructors", {name: `${creator.slug}`, id: creator.id})
    };
    
    return (
        <TouchableOpacity 
            key={creator.id} 
            onPress={() => navigateToCreator()}
            style={styles.container}
        >
            <Card style={styles.card}>
                <Card.Cover source={{ uri: creator.coverImage }} />
                <Card.Content style={styles.cardContent}>
                <Title>{creator.firstName} {creator.lastName}</Title>
                </Card.Content>
            </Card>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 350
    },
    card: {
        alignItems: 'center',
    },
    cardContent: {
        marginTop: 10,
        marginBottom: 10
    }
})
