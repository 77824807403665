import React, { useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';

export default function ProfilePicture({ image }) {
    const [loading, setLoading] = useState(true)

    const imageLoaded = () => {
        setLoading(false)
    }
    return (
        <View>
            {
                image !== null ? (
                    <View>
                        <Image
                            style={styles.image}
                            source={{ uri: image }}
                            onLoad={imageLoaded}
                            accessibilityLabel="profile-picture"
                        />
                        {
                            loading && 
                                <ActivityIndicator size="small" color="#008080"  />
                        }
                    </View>
                ):(
                    <Image
                        accessibilityLabel="profile-picture"
                        style={styles.image}
                        source={require('../../../assets/profile-user.png')}
                    /> 
                )
            }
        </View>
    )
}

const styles = StyleSheet.create({
    image: {
        width: 100, 
        height: 100,
        borderRadius: 50
    },
    loading: {

    }
})
