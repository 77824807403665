import 'react-native-gesture-handler';
import 'react-native-reanimated'
import { API_KEY, APP_ID, AUTH_DOMAIN, MEASUREMENT_ID, MESSAGING_SENDER_ID, PROJECT_ID, STORAGE_BUCKET } from "@env";
import { DefaultTheme as NavDefaultTheme, NavigationContainer } from '@react-navigation/native';
import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import * as Linking from "expo-linking";
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import 'firebase/compat/remote-config';
import React, { Component } from 'react';
import 'react-native-gesture-handler';
import { configureFonts, DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import DrawerNavigator from "./navigation/DrawerNavigation";
import rootReducer from './redux/reducers';

import { initializeAuth } from "firebase/auth"
import { getReactNativePersistence } from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"


const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID
};

if (firebase.apps.length === 0) {
  const defaultApp = firebase.initializeApp(firebaseConfig);
  // Initialize Remote Config and get a reference to the service
  // const remoteConfig = firebase.remoteConfig();
  // const remoteConfig = firebase.remoteConfig({
  //   // Use local storage instead of IndexedDB
  //   persistence: firebase.remoteConfig.Persistence.LOCAL,
  // });
  // Needed in production so as not to exceed quotas
  // remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  initializeAuth(defaultApp, {
    persistence: getReactNativePersistence(AsyncStorage)
  });

}

const store = createStore(rootReducer, applyMiddleware(thunk))


const prefix = Linking.createURL('/');
// Schema for Linking to our App
// Config maps to Names in Stack Screen
// Todo: Fix to have a root stack e.g.
// https://github.com/ReactNativeSchool/getting-started-react-navigation-v5/blob/master/App/index.js
// https://www.youtube.com/watch?v=nQVCkqvU1uE
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      NotFound: '*',
      Return: 'return',
      faq: "faq",
      about: "about",
      Contact: "contact-us",
      Tutorials: "tutorials",
      Pricing: "pricing",
      SellFitnessContentOnline: "sell-fitness-workouts-online",
      FreeWorkoutTimer: "best-free-workout-timer",
      ConvertWorkoutNotesToApp: "convert-workout-notes-to-playable-app",
      Content: {
        screens: {
          Challenge: "challenge",
          Workout: "workout",
          OnDemandWorkout: "on-demand-workout"
        },
      },
      legals: {
        initialRouteName: 'legals',
        screens: {
          privacy: "privacy",
          terms: "terms",
          gdpr: "gdpr"
        },
      },
      DiscoverCreators: {
        screens: {
          DiscoverInstructors: "best-online-fitness-instructors",
          CategoryInstructors: "best-online-fitness-instructors/:category",
          IndividualInstructors: "best-online-fitness-instructors/:name/:id",
        },
      },
      Blogs: {
        screens: {
          Blogs: "blog",
          Blog: "blog/:slug",
        },
      },
      Home: {
        initialRouteName: 'Main',
        screens: {
          Main: {
            initialRouteName: 'feed',
            screens: {
              Feed: 'feed',
              Profile: 'profile/:uid',
              Search: 'search',
              Add: 'add',
            },
          },
          Landing: "landing",
          Payment: "payment",
          Login: "login",
          Subscribe: "subscribe",
          Register: "register",
        },
      },
    }
  }
};

let customFonts = {
  'Montserrat-Black': require('./assets/fonts/Montserrat-Black.ttf'),
  'Montserrat-Bold': require('./assets/fonts/Montserrat-Bold.ttf'),
  'Montserrat-Regular': require('./assets/fonts/Montserrat-Regular.ttf'),
  'Montserrat-Medium': require('./assets/fonts/Montserrat-Medium.ttf'),
  'Montserrat-Light': require('./assets/fonts/Montserrat-Light.ttf'),
  'Montserrat-Thin': require('./assets/fonts/Montserrat-Thin.ttf'),
};

const fontConfig = {
  web: {
    regular: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Montserrat-Medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Montserrat-Light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Montserrat-Thin',
      fontWeight: 'normal',
    },
  },
  ios: {
    regular: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Montserrat-Medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Montserrat-Light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Montserrat-Thin',
      fontWeight: 'normal',
    },
  },
  android: {
    regular: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Montserrat-Medium',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Montserrat-Light',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Montserrat-Thin',
      fontWeight: 'normal',
    },
  }
};


const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#008080',
    accent: '#f1c40f',
  },
  fonts: configureFonts(fontConfig),

};

const navTheme = {
  ...NavDefaultTheme,
  colors: {
    ...NavDefaultTheme.colors,
    primary: '#008080',
    accent: '#f1c40f',
    background: '#E8ECF2'
  },
  fonts: configureFonts(fontConfig),
};

export class App extends Component {

  constructor(props) {
    super()
    this.state = {
      loaded: false,
      fontsLoaded: false,
    }
  }

  async _loadFontsAsync() {
    await Font.loadAsync(customFonts);
    this.setState({ fontsLoaded: true });
  }

  componentDidMount() {
    
    firebase.auth().onAuthStateChanged((user) => {
      if(!user){
        this.setState({
          loggedIn: false,
          loaded: true
        })
      } else {
        this.setState({
          loggedIn: true,
          loaded: true,
        })
      }
    })

    this._loadFontsAsync();

  }

  render(){
    const { loggedIn, loaded, fontsLoaded } = this.state

    if (!loaded || !fontsLoaded) {
      return <AppLoading />;
    }

    return(
      <Provider store={store}>
        <PaperProvider theme={theme}>
          <NavigationContainer theme={navTheme} linking={linking}>
            <DrawerNavigator navigation={this.props.navigation} />
          </NavigationContainer>
        </PaperProvider>
      </Provider>
    )
  }
}

export default App

