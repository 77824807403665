import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { Paragraph, Text, Title } from 'react-native-paper';

export default function InterviewBlog({ navigation, blog }) {

    return (
        <View>
            <View style={styles.heading}>
                <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>{blog.title}</Title>
                <Paragraph style={{textAlign: 'center', fontSize: 'large'}}>{blog.description}</Paragraph>
            </View>
            <View style={styles.img}>
                {blog.coverImage ? <Image source={{ uri: blog.coverImage }} style={styles.image} /> : null}
            </View>
            {/* <View style={styles.contents}>
                <Subheading>Contents</Subheading>
                {blog.questions &&
                blog.questions.map((question, index) => (
                    <View key={index}>
                    <Text>{index + 1}. {question.question}</Text>
                    </View>
                ))}
            </View> */}
            <View style={styles.blogCtn}>
                {blog.questions &&
                blog.questions.map((question, index) => (
                    <View key={index} style={styles.questionCtn}>
                    <Title style={[Dimensions.get('window').width > 900 ? styles.subTitleDesktop : styles.subTitle]}>{question.question}</Title>
                    <Text style={{fontSize: 'x-large'}} >{question.answer}</Text>
                    </View>
                ))}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    headlineTitle: {
        fontSize: 30,
        marginBottom: 30,
        textAlign: 'center'
    },
    headlineTitleDesktop: {
        fontSize: 50,
        marginBottom: 35,
        textAlign: 'center'
    },
    subTitleDesktop: {
    fontWeight: "bold",
    fontSize: "x-large",
    // fontSize: 32,
    marginTop: 20,
    marginBottom: 20
    },
    subTitle: {
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20
    },
    heading: {
    marginTop: 20,
    marginBottom: 20,
    },
    img: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
    },
    image: {
    height: 300,
    width: 300,
    borderRadius: 50
    },
    blogCtn: {
    marginTop: 30,
    marginBottom: 30,
    },
    questionCtn: {
    marginTop: 30,
    marginBottom: 30,
    },
})
