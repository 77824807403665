import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Subheading } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserChallenges, fetchUserChallengesJoined } from '../../../redux/actions';
import CreateWorkoutCategory from '../../workouts/CreateWorkout/CreateWorkoutCategory';
import CreateWorkoutCoverImg from '../../workouts/CreateWorkout/CreateWorkoutCoverImg';
import CreateChallengeDescription from './CreateChallengeDescription';
import CreateChallengePaid from './CreateChallengePaid';
import CreateChallengePublic from './CreateChallengePublic';
import CreateChallengeTitle from './CreateChallengeTitle';
import SelectWorkout from './SelectWorkout';
import SubmitCreateChallenge from './SubmitCreateChallenge';

import CreateExercises from '../../workout_v2/CreateExercises/CreateExercises';


const _ = require('lodash');
let selectCategories = require('../../workouts/CreateWorkout/Categories');

function CreateChallenge(props) {
    const [step, setStep] = useState(0)
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(false);
    const [publicWorkout, setPublicWorkout] = useState(true);
    const [paid, setPaid] = useState(false);
    const [paidMessage, setPaidMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [categorySelected, setCategorySelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const SPORTS_CATEGORIES = selectCategories.SPORTS_CATEGORIES
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [workoutList, setWorkoutList] = useState([]);
    
    const [workout, setWorkout] = useState({
        "weeks": [
            {
                "week": "1",
                "completed": false,
                "days": [
                    {
                        "completed": false,
                        "exercises": [],
                        "name": ""
                    },
                ]
            }
        ]
    })

    useEffect(() => {

        if (!props.currentUser) {
            props.navigation.navigate("Login")
        }

        if (props.workoutsUserFollowing && props.workouts) {
            generateWorkouts(props.workoutsUserFollowing, props.workouts)
        }
    }, []);

    const generateWorkouts = (followingWorkouts, usersWorkouts) => {
        const concatedWorkouts = followingWorkouts.concat(usersWorkouts)
        setWorkoutList(concatedWorkouts)
    }

    const decreaseStep = () => {
        setStep(step - 1)
    }
    const increaseStep = async () => {
        switch (step) {
            case 0:
                setStep(step + 1)
                break;
            case 1:
                setStep(step + 1)
                break;
            case 2:
                const titleValid = await validateTitle()
                if (titleValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 3:
                setStep(step + 1)
                break;
            case 4:
                setStep(step + 1)
                setPaidMessage(null)
                setErrorMessage(null)
                setError(false)
                break;
            case 5:
                const catValid = await validateCategory()
                if (catValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 6:
                setStep(step + 1)
                break;
            case 7:
                setStep(step + 1)
                break;
            case 8:
                setStep(step + 1)
                break;
        }
    }

    const onTogglePublic = () => setPublicWorkout(!publicWorkout);
    const onTogglePaid = () => {
        setPaidMessage(null)
        if (!paid) {
            if (props.currentUser.paid) {
                setPaid(!paid)
            } else {
                setPaidMessage("Must setup your account as a paid account to charge for workouts. Visit your edit profile page to set up. It only takes 3 minutes!")
            }
        } else {
            setPaid(!paid)
        }
    };

    const validateTitle = () => {
        if (name.length > 1) {
            return true
        } else {
            setErrorMessage("Name not long enough")
            setError(true)
            return false
        }
    }

    const validateCategory = () => {
        if (categories.length > 0) {
            return true
        } else {
            setErrorMessage("Please select category")
            setError(true)
            return false
        }
    }

    const handleEditProfile = () => {
        props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
    }

    const onChangeSearch = query => {
        setSearchQuery(query)
        searchCategory(query)
    }

    const searchCategory = (text) => {
        let regex = new RegExp(`(?:${text})`, 'gi');
        const filteredSearch = SPORTS_CATEGORIES.filter( el => el.id.match(regex))
        const displaySearch = filteredSearch.slice(0, 4)
        setCategorySelected(displaySearch)
    }
    const editCategory = (cat) => {
        // Add in function to remove here. 
        const newCategories = [...categories]
        newCategories.push(cat)
        setCategories(newCategories)
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                {
                    props.currentUser !== null ? (
                        {
                            0: 
                                <View style={styles.stepCtn}>
                                    <SelectWorkout workoutList={workoutList} setWorkout={setWorkout} />
                                </View>
                                ,
                            1: 
                                <ScrollView style={styles.workoutStepCtn}>
                                    <CreateExercises workout={workout} setWorkout={setWorkout} />
                                </ScrollView>
                                ,
                            2: 
                                <View style={styles.stepCtn}>
                                    <CreateChallengeTitle name={name} updateName={setName} submit={() => increaseStep()} error={error} />
                                </View>
                                ,
                            3: 
                                <View style={styles.stepCtn}>
                                    <CreateChallengePublic publicWorkout={publicWorkout} onTogglePublic={() => onTogglePublic()} />
                                </View>
                                ,
                            4: 
                                <View style={styles.stepCtn}>
                                    <CreateChallengePaid paid={paid} onTogglePaid={onTogglePaid} />
                                </View>
                                ,
                            5: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutCategory onChangeSearch={onChangeSearch} searchQuery={searchQuery} categories={categories} categorySelected={categorySelected} editCategory={editCategory} />
                                </View>
                                ,
                            6: 
                                <View style={styles.stepCtn}>
                                    <CreateChallengeDescription description={description} setDescription={setDescription} />
                                </View>
                                ,
                            7: 
                                <ScrollView style={styles.workoutStepCtn}>
                                    <CreateWorkoutCoverImg determineFileType={determineFileType} image={image} setImage={setImage} />
                                </ScrollView>
                                ,
                            8:
                                <View style={styles.stepCtn}>
                                    <SubmitCreateChallenge 
                                        username={props.currentUser.name}
                                        fetchUserWorkouts={props.fetchUserWorkouts}
                                        workout={workout}
                                        image={image}
                                        publicChallenge={publicWorkout}
                                        paid={paid}
                                        name={name}
                                        description={description}
                                        navigation={props.navigation}
                                        categories={categories}
                                        fetchUserChallenges={props.fetchUserChallenges}
                                        fetchUserChallengesJoined={props.fetchUserChallengesJoined}
                                    />
                                </View>
                        }[step]
                    ):(
                        <ActivityIndicator size="large" color="#008080" />
                )}
            </View>

            <View>
                { errorMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 10}}>{errorMessage}</Subheading>}
                { paidMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Subheading>}
                { paidMessage && <Button mode="contained" onPress={() => handleEditProfile()}>Edit Profile</Button>}
            </View>

            <View style={styles.buttonsCtn}>
                <Button
                    mode="outlined"
                    onPress={() => decreaseStep()} 
                    disabled={step === 0} 
                >
                    Back
                </Button>
                <Button
                    mode="contained"
                    onPress={() => increaseStep()} 
                    disabled={step === 8} 
                >
                    Next
                </Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around', 
        alignItems: 'center',
        backgroundColor: 'white'
    },
    content: {
        // maxWidth: 600,
        // width: "100%",
        // padding: 10,
        // maxHeight: "100%",
        // height: "100%",
        // padding: 20
    },
    stepCtn: {
        minHeight: Dimensions.get('window').height * 0.65,
        width: Dimensions.get('window').width * 1,
        maxWidth: 600,
        justifyContent: "center",
        padding: 20
    },
    workoutStepCtn: {
        minHeight: Dimensions.get('window').height * 0.75,
        maxHeight: Dimensions.get('window').height * 0.75,
        width: Dimensions.get('window').width * 1,
        maxWidth: 600,
        flex: 1
    },
    buttonsCtn: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%"
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
});


const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    workoutsUserFollowing: store.userState.workoutsUserFollowing,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUserChallenges, fetchUserChallengesJoined  }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(CreateChallenge);
