import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useState } from 'react';
import { ActivityIndicator, Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserOnDemandWorkouts } from '../../redux/actions';
import NewOndemandWorkout from './NewOndemandWorkout';


let selectCategories = require('../workouts/CreateWorkout/Categories');
const _ = require('lodash');

function CreateOnDemand(props) {

    const [step, setStep] = useState(0)
    const [title, setTitle] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(false);
    const [publicWorkout, setPublicWorkout] = useState(true);
    const [paid, setPaid] = useState(false);
    const [paidMessage, setPaidMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [categorySelected, setCategorySelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const SPORTS_CATEGORIES = selectCategories.SPORTS_CATEGORIES
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    

    const decreaseStep = () => {
        setStep(step - 1)
    }
    const increaseStep = async () => {
        switch (step) {
            case 0:
                const titleValid = await validateTitle()
                if (titleValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 1:
                setStep(step + 1)
                break;
            case 2:
                setStep(step + 1)
                setPaidMessage(null)
                setErrorMessage(null)
                setError(false)
                break;
            case 3:
                const catValid = await validateCategory()
                if (catValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 4:
                setStep(step + 1)
                break;
            case 5:
                setStep(step + 1)
                break;
            case 6:
                setStep(step + 1)
                break;
        }
    }

    const onTogglePublic = () => setPublicWorkout(!publicWorkout);

    const onTogglePaid = () => {
        setPaidMessage(null)
        if (!paid) {
            if (props.currentUser.paid) {
                setPaid(!paid)
            } else {
                setPaidMessage("Must setup your account as a paid account to charge for workouts. Visit your edit profile page to set up. It only takes 3 minutes!")
            }
        } else {
            setPaid(!paid)
        }
    };

    const validateTitle = () => {
        if (name.length > 1) {
            return true
        } else {
            setErrorMessage("Name not long enough")
            setError(true)
            return false
        }
    }

    const validateCategory = () => {
        if (categories.length > 0) {
            return true
        } else {
            setErrorMessage("Please select category")
            setError(true)
            return false
        }
    }

    const handleEditProfile = () => {
        props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
    }

    const onChangeSearch = query => {
        setSearchQuery(query)
        searchCategory(query)
    }

    const searchCategory = (text) => {
        let regex = new RegExp(`(?:${text})`, 'gi');
        const filteredSearch = SPORTS_CATEGORIES.filter( el => el.id.match(regex))
        const displaySearch = filteredSearch.slice(0, 4)
        setCategorySelected(displaySearch)
    }
    const editCategory = (cat) => {
        // Add in function to remove here. 
        const newCategories = [...categories]
        newCategories.push(cat)
        setCategories(newCategories)
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    return (
        <View style={styles.container}>
            <ScrollView style={styles.contents}>
                {
                    props.currentUser !== null ? (
                        {
                            0: 
                                <View>
                                    <NewOndemandWorkout 
                                        handleEditProfile={handleEditProfile}
                                        currentUser={props.currentUser}
                                        setStep={setStep}
                                        fetchUserOnDemandWorkouts={props.fetchUserOnDemandWorkouts}
                                    />
                                </View>
                                ,
                            1: 
                                <View style={styles.successCtn}>
                                    <View style={styles.success}>
                                        <Image
                                            style={{ width: 100, height: 100, marginBottom: 20 }}
                                            source={require('../../assets/tick-white-background.png')}
                                            /> 
                                        <Title style={{marginBottom: 20}}>On Demand workout created!</Title>
                                        <Button
                                            mode="contained"
                                            onPress={() => props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})}
                                        >See workouts</Button>
                                    </View>
                                </View>
                        }[step]
                    ):(
                        <ActivityIndicator size="large" color="#008080" />
                )}
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white'
    },
    contents: {
        maxWidth: 600,
        width: "100%",
        padding: 10,
        maxHeight: "100%",
        height: "100%",
    },
    successCtn: {
        minHeight: Dimensions.get('window').height * 0.65,
        width: Dimensions.get('window').width * 1,
        maxWidth: 600,
        justifyContent: "center",
        padding: 20
    },
    success: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

});


const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({  fetchUserOnDemandWorkouts }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(CreateOnDemand);
