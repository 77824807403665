import * as Analytics from 'expo-firebase-analytics';
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Chip, Divider, Title } from 'react-native-paper';
import CustomSocialMediaLinks from '../Socials/CustomSocialMediaLinks';

export default function IndividualCreators(props) {
  const [creator, setCreator] = useState(null);
  useEffect(() => {
    (async() => {
      await Analytics.logEvent(`[Directory]: Individual ${ props.route.params.name }`);
    })();
    getCreator()
  }, []);

  const getCreator = async() => {
    const db = getFirestore();
    const docRef = doc(db, "creatorDirectory", `${props.route.params.id}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setCreator(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  return (
    <View style={styles.root}>

      <View style={styles.setCtnWidth}>
        <View style={styles.btn}>
          <Button 
            onPress={() => props.navigation.navigate("DiscoverInstructors")}
            icon="arrow-left"
          >
              ALL INSTRUCTORS
          </Button>
        </View>
        { creator ? (
          <View>
            <View style={styles.img}>
              {creator.coverImage ? <Image source={{ uri: creator.coverImage }} style={styles.image} /> : null}
            </View>
            <View style={styles.heading}>
              <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>{creator.firstName} {creator.lastName}</Title>
              {/* <Paragraph style={{textAlign: 'center', fontSize: 'large'}}>{creator.bio}</Paragraph> */}
            </View>
            <View>

            </View>
            <View style={{marginTop: 20, marginBottom: 20}}>
              { creator.socials ? (
                  <CustomSocialMediaLinks socials={creator.socials} />
              ):(null)}
            </View>
            <View style={{marginTop: 20, marginBottom: 20}}>
              { creator.categories ? (
                <ScrollView horizontal={true}>
                  {
                      creator.categories.map((cat, i) => (
                          <Chip mode="outlined" style={{margin: 5}} key={i} >{cat}</Chip>
                          
                      ))
                  }
                  </ScrollView>
              ):(null)}
            </View>

            <Divider style={styles.divider} />

          </View>
          ):(
            <View style={styles.loadingCtn}>
              <ActivityIndicator size="large" color="#008080" />
            </View>
          )
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  setCtnWidth: {
      maxWidth: 800,
      width: "100%",
      padding: 20,
      
  },
  divider: {
    marginTop: 100,
    marginBottom: 100
  },
  img: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  loadingCtn: {
    height: Dimensions.get('window').height * 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 50
  },
  btn: {
    marginTop: 10,
    marginBottom: 10
  },
  headlineTitle: {
    fontSize: 30,
    marginBottom: 30,
    textAlign: 'center'
  },
  headlineTitleDesktop: {
      fontSize: 50,
      marginBottom: 35,
      textAlign: 'center'
  },
  heading: {
    marginTop: 20,
    marginBottom: 20,
  },
})
