import { Image } from 'expo-image';
import { Dimensions, StyleSheet, View } from 'react-native';
import { Paragraph, Text, Title } from 'react-native-paper';

const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

const { width } = Dimensions.get('window');

let fontSize = 20; // default font size

// adjust font size based on screen width
if (width < 400) {
    fontSize = 28;
} else if (width >= 400 && width < 600) {
    fontSize = 30;
} else if (width >= 600 && width < 850) {
    fontSize = 32;
} else {
    fontSize = 40;
}


export default function DefaultBlog({ navigation, blog }) {

    return (
        <View>
            <View style={styles.heading}>
                <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>{blog.title}</Title>
                <Paragraph style={{textAlign: 'center', fontSize: 'large'}}>{blog.description}</Paragraph>
            </View>
            <View style={styles.img}>
                {blog.coverImage ? 
                    <View style={styles.imgCtn}>
                        <Image
                            style={styles.image}
                            source={blog.coverImage}
                            placeholder={blurhash}
                            contentFit="cover"
                            transition={1000}
                        />
                    </View>
                : null}
            </View>
            <View style={styles.contents}>
                <Title style={{fontSize: fontSize, marginBottom: 20}}>Contents</Title>
                {blog.contents &&
                blog.contents.map((content, index) => (
                    <View key={index}>
                        <Text style={{fontSize: 'x-large', marginBottom: 10}}>{index + 1}. {content}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.blogCtn}>
                {
                    blog.body && blog.body.map((content, index) => (
                    <View key={index} style={styles.questionCtn}>
                        {content.type == 'title' && <Title style={{fontSize: fontSize, marginBottom: 10}}>{content.contents}</Title>}
                        {content.type == 'text' && <Text style={{fontSize: 'x-large', marginBottom: 10}} >{content.contents}</Text>}
                        {content.type == 'image' && 
                            <View style={styles.imgCtn}>
                                <Image
                                    style={styles.image}
                                    source={content.contents}
                                    placeholder={blurhash}
                                    contentFit="cover"
                                    transition={1000}
                                />
                            </View>
                        }
                    </View>
                ))}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    headlineTitle: {
        fontSize: fontSize,
        marginBottom: 30,
        textAlign: 'center'
    },
    headlineTitleDesktop: {
        fontSize: fontSize,
        marginBottom: 35,
        textAlign: 'center'
    },
    subTitleDesktop: {
    fontWeight: "bold",
    fontSize: "x-large",
    // fontSize: 32,
    marginTop: 20,
    marginBottom: 20
    },
    subTitle: {
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20
    },
    heading: {
    marginTop: 20,
    marginBottom: 20,
    },
    img: {
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    // marginTop: 30,
    // marginBottom: 30,
    },
    imgCtn: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
        marginBottom: 40,
    },
    image: {
        height: 300,
        width: 300,
        borderRadius: 50
    },
    blogCtn: {
    marginTop: 30,
    marginBottom: 30,
    },
    questionCtn: {
    marginTop: 30,
    marginBottom: 30,
    },
})
