import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import React, { useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import { Button, Snackbar, Subheading, Text, Title } from 'react-native-paper';

export default function SubmitCreateChallenge({ workout, image, publicChallenge, paid, categories, name, description, fetchUserChallenges, fetchUserChallengesJoined, username, navigation }) {
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const createChallenge = async () => {
        setLoading(true)
        setErrorMessage(null)
        try {
            setErrorMessage(null)
            const validate = await validateChallenge()
            if (validate) {
                // 1. Upload image return image URL
                const coverMedia = await prepareCoverImage()
                // 2. Upload the exercise images
                const updateWeekImages = await uploadImages()
                // 3. Calculate number of days in challenge
                const totalDays = updateWeekImages.weeks.reduce((count, current) => count + current.days.length, 0);
                // 4. Upload the workout
                const challengeUpload = await uploadChallenge(coverMedia, updateWeekImages, totalDays)
            }
        }
        catch (error) {
            setLoading(false)
            console.error(error)
            setErrorMessage("Error saving challenge")
            setSnackbarVisible(true)
            setSnackbarMessage('Error saving challenge')
            return
        }
    }

    const uploadChallenge = (coverImg, weeks, daysCount) => {
        // 1. Create the challenge
        firebase.firestore()
        .collection('challenges')
        .add({
            user_id: firebase.auth().currentUser.uid,
            image: coverImg,
            public: publicChallenge,
            paid: paid,
            by: username,
            name: name,
            description: description,
            weeks: weeks.weeks,
            category: categories,
            likesCount: 0,
            numDays: daysCount,
            completed: false,
            users: [],
            leaderboard: [],
            creation: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then((docRef) => {
            // 2. Create the User's challenge
            firebase.firestore()
            .collection('userChallenges')
            .doc(firebase.auth().currentUser.uid)
            .collection("challenges")
            .add({
                user_id: firebase.auth().currentUser.uid,
                image: coverImg,
                public: publicChallenge,
                paid: paid,
                by: username,
                name: name,
                description: description,
                weeks: weeks.weeks,
                completed: false,
                creation: firebase.firestore.FieldValue.serverTimestamp(),
                challenge_id: docRef.id,
                complete_percent: 0,
                complete_unit: 0,
                numDays: daysCount,
            })
            .then((docRef) => {
                setLoading(false)
                setStep(1)
                fetchUserChallenges()
                fetchUserChallengesJoined()
            })
            .catch((error) => {
            });
        })
        .catch((error) => {
            setLoading(false)
            setErrorMessage("Error saving challenge")
            setSnackbarVisible(true)
            setSnackbarMessage('Error saving challenge')
        });
        return coverImg
    }

    const validateChallenge = () => {
        if (name.length <= 2) {
            setErrorMessage("Invalid challenge name")
            setSnackbarMessage("Invalid challenge name")
            onToggleSnackBar()
            return false
        } else {
            return true
        }
    }

    const navigateProfile = () => {
        navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
    }

    const prepareCoverImage = async () => {
        if (image) {
            const createImg = await prepareImageUpload()
            const coverImg = await uploadCoverImage(createImg)
            const imgUrl = await coverImg.ref.getDownloadURL()
            const coverMedia = {"url": imgUrl, metadata: createImg.metadata}
            return coverMedia
        } else {
            return null
        }
    }

    const prepareImageUpload = async () => {
        // Prepares image for upload
        const uri = image.url
        const childPath = `workoutImage/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath, metadata: image.metadata}
        return coverMedia
    }

    const uploadCoverImage = async(imgData) => {
        const task = firebase
            .storage()
            .ref()
            .child(imgData.childPath)
            .put(imgData.blob)

        task.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return task
    }

    const uploadImages = async() => {
        let updatedWorkout = _.cloneDeep(workout);
        const newWeeks = await uploadExerciseImages(updatedWorkout)
        return newWeeks
    }

    const uploadExerciseImages = async(workArgs) => {
        const work = workArgs
        // Big yucky.
        // For each week in workout
        for (let i = 0; i < work.weeks.length; i++) {
            // For each Day
            for (let j = 0; j < work.weeks[i].days.length; j++) {
                // For each exercise
                for (let e = 0; e < work.weeks[i].days[j].exercises.length; e++) {
                    if (work.weeks[i].days[j].exercises[e].image && work.weeks[i].days[j].exercises[e].image.url.length > 0) {
                        // There is a saved image
                        const checkIfUploaded = await checkIfImageUploaded(work.weeks[i].days[j].exercises[e].image && work.weeks[i].days[j].exercises[e].image.url)
                        if (!checkIfUploaded) {
                            const createImg = await prepareExerciseImage(work.weeks[i].days[j].exercises[e].image)
                            const uploadExImage = await uploadImage(createImg)
                            const imgUrl = await uploadExImage.ref.getDownloadURL()
                            const exerciseMedia = {"url": imgUrl, metadata: createImg.metadata}
                            work.weeks[i].days[j].exercises[e].image = exerciseMedia
                        }
                    }
                }
            }
        }
        return work
    }

    const checkIfImageUploaded = (i) => {
        const imgType = i.substring(0, 4)
        if (imgType === 'http') {
            return true
        } else {
            // should === 'data'.
            // the image has not yet been uploaded
            return false
        }
    }

    const prepareExerciseImage = async (imagePath) => {
        // Prepares image for upload for exercise image
        const uri = imagePath.url
        const childPath = `workoutImage/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath, metadata: imagePath.metadata}
        return coverMedia
    }

    const uploadImage = async(imgData) => {
        // Create a root reference
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef
                            .child(imgData.childPath)
                            .put(imgData.blob)
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            console.error(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return uploadTask
    }

    return (
        <View>
            {
                loading ? (
                    <View style={styles.createLoading}>
                        <Title style={{marginBottom: 15}}>Creating challenge</Title>
                        <Subheading style={{marginBottom: 15}}>Uploading lots of images & videos may take a few minutes</Subheading>
                        <Text style={styles.explainerText}>Please don't close screen yet</Text>
                        <ActivityIndicator size="large" color="#008080" />
                    </View>
                ):(
                    <View>
                        {
                            workout !== null ? (
                                {
                                    0: 
                                    <View>
                                        <Button
                                            icon="content-save-outline" 
                                            mode="contained"
                                            onPress={()=> createChallenge()}
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            Create Challenge
                                        </Button>
                                        { errorMessage && <Text style={{marginTop: 10, color: 'red'}}>{errorMessage}</Text>}
                                    </View>
                                    ,
                                1: 
                                    <View style={styles.stepCtn}>
                                            <Image
                                                style={{ width: 100, height: 100, marginBottom: 15 }}
                                                source={require('../../../assets/tick-white-background.png')}
                                                /> 
                                            <Title style={{ marginBottom: 15}}>Challenge created!</Title>
                                            <Button
                                                mode="contained"
                                                onPress={()=> navigateProfile()}
                                            >See challenges</Button>
                                    </View>
                                    
                                }[step]
                                ):(
                                    <ActivityIndicator size="large" color="#008080" />
                            )}
                    </View>
                )
            }
            <Snackbar
                visible={snackbarVisible}
                onDismiss={onDismissSnackBar}
                action={{
                label: 'Gotcha',
                onPress: () => {
                    // Do something
                },
                }}>
                {snackbarMessage}
            </Snackbar>
        </View>
    )
}

const styles = StyleSheet.create({

    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
        marginBottom: 15
    },
    createLoading: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    stepCtn: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
});