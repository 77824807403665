import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { Card, Chip, Paragraph, Title } from 'react-native-paper';

export default function BlogCard({blog}) {
    const navigation = useNavigation();

    const navigateToBlog = (blogSlug) => {
        navigation.navigate('Blog', { slug:  blogSlug});
    };
    
    return (
        <TouchableOpacity 
            key={blog.id} 
            onPress={() => navigateToBlog(blog.slug)}
            style={[Dimensions.get('window').width > 800 ? styles.containerDesktop : styles.containerMobile]}
        >
            <Card style={[Dimensions.get('window').width > 800 ? styles.cardDesktop : styles.cardMobile]}>
                <Card.Cover source={{ uri: blog.coverImage }} />
                <Card.Content style={[Dimensions.get('window').width > 800 ? styles.cardContentDesktop : styles.cardContentMobile]}>
                <Title>{blog.title}</Title>
                <Paragraph>{blog.description}</Paragraph>
                { blog.tags ? (
                    <ScrollView horizontal={true}>
                    {
                        blog.tags.map((tag, i) => (
                            <Chip mode="outlined" style={{margin: 5}} key={i} >{tag}</Chip>
                            
                        ))
                    }
                    </ScrollView>
                ):(null)}
                </Card.Content>
            </Card>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    containerDesktop: {
        maxWidth: 350
    },
    containerMobile: {

    },
    cardDesktop: {
        alignItems: 'center',
    },
    cardMobile: {

    },
    cardContentDesktop: {
        marginTop: 10,
        marginBottom: 10
    },
    cardContentMobile: {
        marginTop: 10,
        marginBottom: 10
    }
})
