import { MOVE_URL } from "@env";
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { Share, StyleSheet, View } from 'react-native';
import { IconButton } from 'react-native-paper';

export default function NativeShare(props) {
    const [url, setUrl] = useState(null)

    useEffect(() => {
        const webUrl = `${MOVE_URL}${props.params}`
        setUrl(webUrl)
    }, [])

    const onShare = async () => {
        try {
            const result = await Share.share({
                message: props.message,
                url: url,
                title: props.title,
                image: props.image
            });
            // if (result.action === Share.sharedAction) {
            //     if (result.activityType) {
            //         // shared with activity type of result.activityType
            //     } else {
            //         // shared
            //     }
            // } else if (result.action === Share.dismissedAction) {
            // // dismissed
            // }
        } catch (error) {
            alert(error.message);
        }
    }


    return (
        <View>
            <IconButton
                icon="share"
                size={30}
                color="#008080"
                onPress={onShare}
            />
        </View>
    )
}

const styles = StyleSheet.create({

});

