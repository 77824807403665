import React, {useState, useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator, Image, Dimensions  } from 'react-native'
const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').width;

export default function photo({url, component}) {
    const [imageLoading, setImageLoading] = useState(true)
    const [type, setType] = useState(null);

    const imageLoaded = () => {
        setImageLoading(false)
    }

    useEffect(() => {
        switch (component) {
            case 'cover':
                setType('cover')
                break;
            case 'exercise':
                setType('exercise')
                break;
            default:
                setType('default')
                break;
        }
    }, [])


    return (
            <View>
                {imageLoading ? (
                    <View style={styles.loading}>
                        <ActivityIndicator color="#008080" />
                    </View>
                ):(
                    null
                )}

                {type === 'cover' && 
                    <View style={{ width: '100%', height: deviceHeight * 0.4 }}>
                        <Image
                            source={{ uri: url }}
                            onLoad={imageLoaded}
                            style={{     
                                flex: 1,
                                height: undefined,
                                width: undefined
                            }}
                            resizeMode="cover"
                        />
                    </View>
                    // <Image
                    //     style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                    //     source={{ uri: url }}
                    //     onLoad={imageLoaded}
                    // /> 
                }
                {type === 'exercise' && 
                    <Image
                        style={{ width: 80, height: 80 }}
                        source={{ uri: url }}
                        onLoad={imageLoaded}
                    /> 
                }
                {type === 'default' && 
                    <Image
                        style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                        source={{ uri: url }}
                        onLoad={imageLoaded}
                    /> 
                } 
            </View>
    )
}

const styles = StyleSheet.create({
    loading: {
        alignItems: 'center',
        justifyContent: 'center',
        height: "100%",
        width: "100%"
    }
})
