import { Picker } from '@react-native-picker/picker';
import { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { Button, Subheading, Text, Title } from 'react-native-paper';

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export default function CalculateEarning(props) {
    const [visible, setVisible] = useState(false);
    const [selectedSubscribers, setSelectedSubscribers] = useState(500);
    const [subscribers, setSubscribers] = useState([{'label': 50, value: 50}, {'label': 100, value: 100}, {'label': 200, value: 200}, {'label': 500, value: 500}, {'label': 1000, value: 1000}, {'label': 5000, value: 5000}, {'label': "10,000", value: 10000}, {'label': "20,000", value: 20000}, {'label': "50,000", value: 50000}, {'label': "100,000", value: 100000}, {'label': "1,000,000", value: 1000000},]);
    const [selectedPercentage, setSelectedPercentage] = useState(1);
    const [percentage, setPercentage] = useState([{'label': '1', value: 0.01}, {'label': '5', value: 0.05}, {'label': '10', value: 0.1}, {'label': '20', value: 0.2}, {'label': '40', value: 0.4}, {'label': '50', value: 0.5}, {'label': "60", value: 0.6}, {'label': "80", value: 0.8}, {'label': "90", value: 0.9}, {'label': "100", value: 1},]);
    const [selectedPrice, setSelectedPrice] = useState(20);
    const [price, setPrice] = useState([{'label': '$5', value: 5}, {'label': '$7', value: 7}, {'label': '$10', value: 10}, {'label': '$15', value: 15}, {'label': '$20', value: 20}, {'label': '$25', value: 25}, {'label': "$30", value: 30}, {'label': "$35", value: 35}, {'label': "$40", value: 40}, {'label': "$50", value: 50},]);
    const [amount, setAmount] = useState(5000);

    useEffect(() => {
        calculateAmount()
    },[selectedSubscribers, selectedPercentage, selectedPrice]) 

    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    const containerStyle = {backgroundColor: 'white', padding: 20};

    const calculateAmount = () => {
        // https://stripe.com/au/pricing
        const newAmount = parseInt(selectedSubscribers * selectedPercentage * selectedPrice)
        const stripeFee = (newAmount * 0.0175) + 0.3
        const moveFee = (newAmount * 0.2)
        const finalAmount = parseInt(newAmount - stripeFee - moveFee)
        setAmount(finalAmount)
    }
    
    const calcFollowers = (followersInput) => {
        setSelectedSubscribers(followersInput)
        calculateAmount()
    }

    return (
        <View style={styles.root}>
            <Title style={[Dimensions.get('window').width > 900 ? styles.headlineTitleDesktop : styles.headlineTitle]}>Estimate what you could make on Move</Title>
            <View style={[Dimensions.get('window').width > 900 ? styles.pickerCtnDesktop : styles.pickerCtn]}>
                <Picker
                    style={styles.picker}
                    selectedValue={selectedSubscribers}
                    onValueChange={(itemValue, itemIndex) =>
                        setSelectedSubscribers(itemValue)
                        // calcFollowers(itemValue)
                    }>
                        {
                            subscribers.map((s, i) => (
                                <Picker.Item label={`${s.label} Followers`} value={s.value} key={i} />
                            ))
                        }
                </Picker>
                <Picker
                    style={styles.picker}
                    selectedValue={selectedPercentage}
                    onValueChange={(itemValue, itemIndex) =>
                        setSelectedPercentage(itemValue)
                    }>
                        {
                            percentage.map((p, i) => (
                                <Picker.Item label={`${p.label} %`} value={p.value} key={i} />
                            ))
                        }
                </Picker>
                <Picker
                    style={styles.picker}
                    selectedValue={selectedPrice}
                    onValueChange={(itemValue, itemIndex) =>
                        setSelectedPrice(itemValue)
                    }>
                        {
                            price.map((p, i) => (
                                <Picker.Item label={`${p.label}/month`} value={p.value} key={i} />
                            ))
                        }
                </Picker>
            </View>
            <View style={styles.amountCtn}>
                <Title style={{fontSize: 30}}>US{dollarUS.format(amount)}</Title>
                <Subheading>  Per month</Subheading>
            </View>
            <Button 
                mode='contained'
                onPress={() => props.navigation.navigate('Home', {
                    screen: 'Register',
                })}
            >Get Started</Button>
            <Text style={{color: 'grey', marginTop: 5}}>Net of Move & credit card fees</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    picker: {
        minHeight: 50,
        fontSize: 16,
        padding: 10,
        margin: 10,
        borderRadius: 5
        // borderColor: 'gray'    
    },
    pickerCtn: {
        // flexDirection: 'row',
        // flexWrap: 'nowrap',
        // justifyContent: 'space-around'
        
    },
    pickerCtnDesktop: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-around'
    },
    headlineTitle: {
        fontSize: 18,
        marginBottom: 30,
    },
    headlineTitleDesktop: {
        fontSize: 25,
        marginBottom: 35,
    },
    amountCtn: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        marginBottom: 30,
        marginTop: 30,
        alignItems: 'center'
    }

})