import * as Analytics from 'expo-firebase-analytics';
import React, { useEffect } from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, View } from 'react-native';
import { Chip, Subheading, Text, Title } from 'react-native-paper';

export default function HowItWorks() {

    useEffect(() => {
        // Crashes app
        (async() => {
            await Analytics.logEvent('[HowItWorks]: Accessed');
        })();
    }, [])
    
    return (
        <View style={styles.container}>
            <ScrollView style={styles.content}>
                <View style={styles.welcome}>
                    <Title style={{marginBottom: 15, color: 'white'}}>Welcome to</Title>
                    <Title style={{marginBottom: 30, color: 'white', fontSize: 34}}>Move</Title>
                    <Subheading style={{textAlign: 'center', color: 'white'}}>One place to create your own exercise workouts & challenges or follow ones from your favourite creators</Subheading>
                </View>
                <View style={styles.feature}>
                    <Title style={{marginBottom: 15 }}>Features</Title>
                    <Subheading>Why Move?</Subheading>
                    <View style={styles.featureCtn}>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/create.png')}
                            /> 
                            <Title>Create</Title>
                            <Text style={styles.explainerText}>Create your own exercise routines</Text>
                        </View>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/follow.png')}
                            /> 
                            <Title>Follow</Title>
                            <Text style={styles.explainerText}>or follow ones from your favourite creators</Text>
                        </View>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/goal.png')}
                            /> 
                            <Title>Challenges</Title>
                            <Text style={styles.explainerText}>Create or join challenges e.g. 12 week beginner gym</Text>
                        </View>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/progress-chart.png')}
                            /> 
                            <Title>Track progress</Title>
                            <Text style={styles.explainerText}>Track your progress as you complete your routines</Text>
                        </View>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/community.png')}
                            /> 
                            <Title>Community</Title>
                            <Text style={styles.explainerText}>Join a chanellge with others to stay motivated & accountable</Text>
                        </View>
                        <View style={styles.card}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                source={require('../../assets/faqIcons/subscription.png')}
                            /> 
                            <Title>Subscriptions</Title>
                            <Text style={styles.explainerText}>Content creators can charge subs for their highest quality content</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.good}>
                    <Title style={{marginBottom: 30}}>Good for</Title>
                    <View style={{flexDirection: 'row', justifyContent: 'space-around', width: '100%'}}>
                        <Chip>Gym</Chip><Chip>Yoga</Chip><Chip>Running</Chip><Chip>Pilates</Chip><Chip>+ More</Chip>
                    </View>
                </View>
                <View style={styles.footer}>
                    {/* <Text>Created with 💙 by <a href='https://www.tiktok.com/@startup_from_scratch?'>@startup_from_scratch</a></Text> */}
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        margin: 0,
        flex: 1
    },
    formCtn: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentPage: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: Dimensions.get('window').height * 0.60,
        maxWidth: 600,
        width: "100%",
    },
    ctnWrapper: {
        justifyContent: 'center', 
        alignItems: 'center'
    },
    featureCtn: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    },
    welcome: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.50,
        backgroundColor: '#008080',
        color: 'white',
    },
    feature: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.60,
    },
    good: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.50,
        backgroundColor: 'white',
        color: 'white',
    },
    footer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.20,
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
        marginBottom: 15,
        textAlign: 'center'
    },
    card: {
        backgroundColor: 'white',
        borderRadius: 20,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        margin: 20,
        // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        height: 225,
        width: 320,
        // shadowColor: '#000',
        // shadowColor: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        shadowColor: 'rgba(149, 157, 165, 0.2)',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.8,
        shadowRadius: 2,  
        elevation: 5
    }
})