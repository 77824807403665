import * as Analytics from 'expo-firebase-analytics';
import * as Linking from 'expo-linking';
import React, { useEffect } from 'react';
import { Dimensions, Image, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Subheading, Title } from 'react-native-paper';

export default function Contact(props) {

    useEffect(() => {
        (async() => {
            await Analytics.logEvent('[FAQs]: Accessed');
        })();
    }, [])

    const handleLink = (social) => {
        if (social === 'discord') {
            Linking.openURL("https://discord.gg/fQr85EnJsn");
        } else if (social === 'tiktok') {
            Linking.openURL("https://www.tiktok.com/@move_io");
        } else {
            
        }
    };

    const navigateFaq = () => {
        props.navigation.navigate('faq')
    }
    
    return (
        <View style={styles.header}>
            <ScrollView>
                <View style={styles.welcome}>
                    <Title style={{marginBottom: 30, color: 'white', fontSize: 30}}>Contact Us</Title>
                </View>
                <View style={styles.container}>
                    <View style={styles.content}>
                        <View style={styles.email}>
                            <Image
                                style={{ width: 50, height: 50, marginRight: 30 }}
                                source={require('../../assets/socials/email.png')}
                            /> 
                            <Subheading>hellomovefitness@outlook.com</Subheading>
                        </View>
                        <View style={styles.socials}>
                            <TouchableOpacity
                                onPress={() => handleLink('discord')}
                            >
                                <Image
                                    style={{ width: 75, height: 75, marginBottom: 20 }}
                                    source={require('../../assets/socials/discord.png')}
                                /> 
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => handleLink('tiktok')}
                            >
                                <Image
                                    style={{ width: 75, height: 75, marginBottom: 20 }}
                                    source={require('../../assets/socials/tiktok.png')}
                                /> 
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        margin: 0,
    },
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    content: {
        maxWidth: 600,
        width: "100%",
        minHeight: Dimensions.get('window').height * 0.5,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    welcome: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.40,
        backgroundColor: 'rgba(0,128,128, 1)',
        color: 'white',
    },
    contact: {
        margin: 10,
        maxWidth: 600
    },
    email: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%'
    },
    socials: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%'
    }
})