import { Image, StyleSheet, View } from 'react-native';
import { IconButton, Subheading, Title } from 'react-native-paper';
import Media from '../media/index';

export default function ExerciseCard({exercise}) {
    return (
        <View style={styles.container}>
            <View style={styles.image}>
            { exercise.image && exercise.image.url.length > 0 ? (
                <Media contentType={exercise.image.metadata.type} url={exercise.image.url} component='exercise' />
            ):(
            <View style={{ padding: 10}}>
                <Image
                    style={{ width: 60, height: 60 }}
                    source={require('../../assets/dumbell.png')}
                /> 
            </View>
            )}
            </View>
            <View style={styles.name}>
                <Title>{exercise.name}</Title>
                <View style={styles.extrasCtn}>
                    { exercise.reps && <Subheading style={styles.explainerText}>{exercise.sets} sets x {exercise.reps} reps</Subheading>}
                    { exercise.distance && <Subheading style={styles.explainerText}>{exercise.distance}  {exercise.distanceUOM}</Subheading>}
                    { exercise.weight && <Subheading style={styles.explainerText}>{exercise.weight}  {exercise.weightUOM}</Subheading>}
                    { exercise.time && 
                        <View style={styles.row}>
                            <IconButton
                                style={{marginRight: 10}}
                                icon="timer-outline"
                                size={10}
                            />
                            <Subheading style={styles.explainerText}>{exercise.time}</Subheading>
                        </View>
                    }
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "2%"
    },
    name: {
        flex: 2
    },
    image: {
        flex: 1
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
    row: {
        flexDirection: "row",
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    extrasCtn: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
})
