import { MOVE_URL } from "@env";
import { useNavigation, useRoute } from '@react-navigation/native';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, Image, Platform, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Subheading, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersData, fetchUserWorkouts } from '../../redux/actions';
import Paywall from '../main/Paywall';
import Media from '../media/index';
import Share from '../share/index';
import CreateEditViewWorkout from '../workouts/CreateEditViewWorkout';


function Workout(props) {
    const navigation = useNavigation();
    const route = useRoute();
    const [workout, setWorkout] = useState(null)
    const [subscribedWorkout, setSubscribedWorkout] = useState(true)
    const [paidData, setPaidData] = useState(null)
    const [usersWorkout, setUsersWorkout] = useState(false)
    const [following, setFollowing] = useState(false)
    const [liked, setLiked] = useState(false)
    const [imageLoading, setImageLoading] = useState(true)
    const [url, setUrl] = useState(true)

    useEffect(() => {

        (async () => {
            if (Platform.OS === 'web') {
                const webUrl = window.location.href
                setUrl(webUrl)
            } else {
                // If mobile, create web URL
                // TODO: In future needs to create IOS URI
                const webUrl = `${MOVE_URL}/workout?workoutId=${route.params.workoutId}`
                setUrl(webUrl)
            }
        })();

        if (props.route.params) {
            // Need to check if route is there first
            if(props.workoutFollowing.indexOf(props.route.params.workoutId) > -1 ){
                setFollowing(true)
            } else {
                setFollowing(false)
            }
    
            firebase.firestore()
                .collection("workouts")
                .doc(props.route.params.workoutId)
                .get()
                .then((snapshot) => {
                    const workoutData = snapshot.data()
                    checkIfPremiumContent(workoutData)
                    setWorkout(workoutData)
                })

            getWorkoutLikes()
        }

    }, [props.workoutFollowing, props.subscriptions, props.currentUser, props.route])

    const checkIfPremiumContent = (workoutData) => {
        // 1. Check if user exsists
        if (props.currentUser) {
            // 2. Check if users workout
            if (workoutData.user_id === firebase.auth().currentUser.uid) {
                setUsersWorkout(true)
                setFollowing(true)
            } else {
                // Not users workout
                // 3. Check if paid workout
                if (workoutData.paid) {
                    // 4. Check if subscribed
                    if (props.subscriptions.indexOf(workoutData.user_id) > -1 ) {
                        // The current user is subscribed to this premium content
                        setSubscribedWorkout(true)
                    } else {
                        // the user is not subscribed
                        // Get the subscription data
                        setSubscribedWorkout(false)
                        getPaidData(workoutData.user_id)
                    }
                } else {
                    // Show non premium content
                    setSubscribedWorkout(true)

                }
            }
        } else {
            // 5. Check if paid workout
            if (workoutData.paid) {
                setSubscribedWorkout(false)
                getPaidData(workoutData.user_id)
            } else {
                // Show non premium content
                setSubscribedWorkout(true)
            }
        }
    }

    const getWorkoutLikes = () => {
        if (props.currentUser) {
            firebase.firestore()
            .collection("workouts")
            .doc(props.route.params.workoutId)
            .collection("likes")
            .doc(firebase.auth().currentUser.uid)
            .onSnapshot((snapshot) => {
                if (snapshot.exists) {
                    setLiked(true)
                } else {
                    setLiked(false)
                }
            })
        }
    }

    const getPaidData = (user_id) => {
        firebase.firestore()
            .collection("userStripeAccount")
            .doc(user_id)
            .get()
            .then((snapshot) => {
                setPaidData(snapshot.data())
            })
    }

    const onFollow = () => {
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })
        } else {
            firebase.firestore()
            .collection("userWorkouts")
            .doc(firebase.auth().currentUser.uid)
            .collection("following")
            .doc(props.route.params.workoutId)
            .set({})
            .then((docRef) => {
                fetchUserWorkouts()
            })
            .catch((error) => {
            });
        }
    }
    
    const onUnfollow = () => {
        firebase.firestore()
        .collection("userWorkouts")
        .doc(firebase.auth().currentUser.uid)
        .collection("following")
        .doc(props.route.params.workoutId)
        .delete()
    }

    const onLikePress = () => {
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Login")
        } else {
            firebase.firestore()
                .collection("workouts")
                .doc(props.route.params.workoutId)
                .collection("likes")
                .doc(firebase.auth().currentUser.uid)
                .set({})
        }
    }
    const onDislikePress = () => {
        firebase.firestore()
            .collection("workouts")
            .doc(props.route.params.workoutId)
            .collection("likes")
            .doc(firebase.auth().currentUser.uid)
            .delete()
    }

    const imageLoaded = () => {
        setImageLoading(false)
    }


    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            { workout !== null ? (
                <ScrollView style={styles.container}>
                    <View>
                        { workout.image && workout.image.url.length > 0 ? (
                            <View style={styles.mediaCtn}>
                                <Media contentType={workout.image.metadata.type} url={workout.image.url} component='cover' /> 
                            </View>
                        ):(
                            <View style={{ marginBottom: 10}}>
                                <Image
                                    style={{ width: "100%", height: "auto", minHeight: 100, minWidth: 100, marginBottom: 10 }}
                                    resizeMode="contain"
                                    source={require('../../assets/dumbell.png')}
                                /> 
                            </View>
                        )}
                    </View>
                    <View style={styles.infoContainer}>

                        <Title>{workout.name}</Title>
                        <TouchableOpacity 
                            onPress={() => navigation.navigate('Home', {
                                screen: 'Main',
                                params: {
                                    screen: 'Profile',
                                    params: {
                                        uid: workout.user_id,
                                    },
                                },
                            })}
                        >
                        <Subheading style={{color: "#9e9e9e"}}>Created by {workout.by}</Subheading>
                        </TouchableOpacity>
                        <View style={styles.statsCtn}>

                            <View style={styles.iconRow}>
                                { following ? (
                                    <Button
                                        mode="outlined"
                                        color="#008080" 
                                        onPress={() => onUnfollow()}
                                    >Following</Button>
                                ) : (
                                    <Button
                                        mode="contained"  
                                        onPress={() => onFollow()}
                                    >Follow</Button>
                                )}
                                <Share
                                    params={`/workout?workoutId=${route.params.workoutId}`}
                                    message={"Check out this Workout"}
                                    title={`${workout.name} by ${workout.by}`}
                                    image={null}   
                                />
                            </View>

                            <View style={styles.iconRow}>

                                { liked ? 
                                    (
                                        
                                        <Button
                                            icon="heart"
                                            onPress={()=> onDislikePress()}
                                        >Unlike</Button>
                                    ) : (
                                        <Button 
                                            icon="heart-outline"
                                            onPress={()=> onLikePress()}
                                        >Like</Button>
                                    )
                                }
                                <Button 
                                    icon="comment"
                                    onPress={()=> 
                                        props.navigation.navigate('Workout Comments', 
                                        {workoutId: props.route.params.workoutId}
                                    )}
                                >Comments</Button>
                            </View>
                        </View>
                        <View style={styles.description}>
                            <Paragraph>{workout.description}</Paragraph>
                        </View>
                    </View>
                    <View style={styles.exerciseSection}>
                        <Title style={{marginBottom: 15}}>Exercises</Title>
                        { subscribedWorkout ? (
                            <CreateEditViewWorkout 
                                navigation={navigation}
                                workout={workout} 
                                editable={usersWorkout} 
                                following={following}
                                workoutId={props.route.params.workoutId}
                                setWorkout={setWorkout}
                            />
                        ):(
                            <View>
                                <Paywall 
                                    creatorName={workout.by} 
                                    workoutPrice={paidData} 
                                    uid={workout.user_id} 
                                    navigation={navigation} 
                                />
                            </View>
                        )}
                    </View>
                </ScrollView>
            ):(
                <View style={styles.loadingCtn}>
                    <ActivityIndicator size="large" color="#008080" />
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 700,
        width: "100%",
        padding: 10
    },
    infoContainer: {
        backgroundColor: 'white',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        padding: 15
    },
    statsCtn: {
        marginBottom: 15
    },
    iconRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
    },
    description: {
        marginTop: 15
    },
    exerciseCtn: {
        marginBottom: 15,
    },
    dayTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    loadingCtn: {
        width: '90%',
        height: '90%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    exerciseSection: {
        marginTop: 15,
        padding: 15
    },
    dayCtn: {
        marginBottom: 15
    },
    mediaCtn: {
        maxWidth: Dimensions.get('window').width * 1,
        height: "auto",
        // maxHeight: Dimensions.get('window').height * 0.5
        justifyContent: 'center'
    }
})

const mapStateToProps = (store) => ({
    users: store.usersState.users,
    workoutFollowing: store.userState.workoutFollowing,
    currentUser: store.userState.currentUser,
    subscriptions: store.userState.subscriptions,
    workouts: store.userState.workouts,
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUsersData }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(Workout);
