import * as Analytics from 'expo-firebase-analytics';
import { useEffect, useState } from 'react';
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native';
import DraggableFlatList, {
    ScaleDecorator
} from "react-native-draggable-flatlist";
import { Button, IconButton, List, Modal, Portal, Subheading, Text, TextInput, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import AppStoreBadges from '../../appstoreBadge';
import ExerciseCard from '../../exercises/ExerciseListCard';
import CreateEditExercise from '../../workout_v2/CreateEditExercise';
import FreeWorkoutTimerSession from './FreeWorkoutTimerSession';
import _ from 'lodash';

const NUM_ITEMS = 10;
function getColor(i) {
    const multiplier = 255 / (NUM_ITEMS - 1);
    const colorVal = i * multiplier;
    return `rgb(${colorVal}, ${Math.abs(128 - colorVal)}, ${255 - colorVal})`;
}

const initialWorkoutData = {
    "weeks": [
        {
            "week": "1",
            "completed": false,
            "days": [
                {
                    "completed": false,
                    "exercises": [],
                    "name": "",
                    "openAccordion": true
                },
            ]
        }
    ]
}

const { width  } = Dimensions.get('window');

let fontSize = 20; // default font size

// adjust font size based on screen width
if (width < 400) {
    fontSize = 28;
} else if (width >= 400 && width < 600) {
    fontSize = 30;
} else if (width >= 600 && width < 850) {
    fontSize = 32;
} else {
    fontSize = 40;
}

function FreeWorkoutTimer(props) {
    const [workout, setWorkout] = useState(initialWorkoutData);
    const [exercise, setExercise] = useState();
    const [exerciseIndex, setExerciseIndex] = useState(0);
    const [selectedDayIndex, setSelectedDayIndex] = useState(0);
    const [dayIndex, setDayIndex] = useState(0);
    const [dayName, setDayName] = useState('');
    const [selectedWeek, setSelectedWeek] = useState(0);
    const [selectedDay, setSelectedDay] = useState(0);
    const [showAddExercise, setShowAddExercise ] = useState(false);
    const [dirtyAnalytics, setDirtyAnalytics ] = useState(true);
    const [edit, setEdit ] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => setModalVisible(true);
    const hideEditDayModal = () => setModalVisible(false);
    const containerStyle = {backgroundColor: 'white', margin: 20, padding: 20 };

    useEffect(() => {
        if (dirtyAnalytics) {
            // Gross conditional check so as not to run analytics more than once
            (async() => {
                await Analytics.logEvent('[FreeWorkoutTimer]: Viewed');
                setDirtyAnalytics(false)
            })();
        }
        updateWorkoutStore()
        console.log("Hello good Sirs checking out thy console log")
    }, [props.workoutStore])

    const updateWorkoutStore = () => {
        // Causing error when coming from convert. Need to work out a better way to handle
        // props.dispatch({ type: "FREE_WORKOUT_TIMER_WORKOUT", freeWorkoutTimerWorkout: workout })

        if (props.workoutStore && Object.keys(props.workoutStore).length !== 0) {
            setWorkout(props.workoutStore)
        } else {
        }
    }

    const handleShowAddExercise = () => {
        setEdit(false)
        setShowAddExercise(false)
    }

    const restartWorkout = () => {
        setExerciseIndex(0)
        setShowAddExercise(false)
        setEdit(false)
    }

    const handleDisplay = () => {
        // Empty function to pass through to CreateExercises
    }

    const updateExercise = (ex, pressedExDayIndex) => {
        setSelectedDayIndex(pressedExDayIndex)
        setDayIndex(pressedExDayIndex)
        // Get Index of Exercise
        // const indexEx = props.workoutStore.weeks[selectedWeek].days[selectedDayIndex].exercises.indexOf(ex)

        const indexEx = workout.weeks[selectedWeek].days[pressedExDayIndex].exercises.indexOf(ex)
        setExerciseIndex(indexEx)
        // Set Exercise
        setExercise(ex)
        setShowAddExercise(true)
        setEdit(true)
    }

    const deleteExercise = () => {
        let newWorkout = workout
        newWorkout.weeks[selectedWeek].days[selectedDayIndex].exercises.splice(exerciseIndex, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        handleShowAddExercise()
    }

    const addDay = () => {
        let newWorkout = _.cloneDeep(workout);
        const newWeek = workout.weeks[selectedWeek]
        const nextDayNum = newWeek.days.length + 1
        const newDay =  {
            "name": "",
            "completed": false,
            "exercises": [],
            "openAccordion": true

        }
        newWorkout.weeks[selectedWeek].days.push(newDay)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))

    }

    const deleteDay = () => {
        let newWorkout = workout
        newWorkout.weeks[selectedWeek].days.splice(selectedDayIndex, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        hideEditDayModal()
    }

    const handleDayPress = (pressedDayIndex) => {
        let newWorkout = _.cloneDeep(workout);
        const accordion = newWorkout.weeks[selectedWeek].days[pressedDayIndex].openAccordion
        newWorkout.weeks[selectedWeek].days[pressedDayIndex].openAccordion = !accordion
        if (!accordion) {
            setSelectedDay(pressedDayIndex)
            setSelectedDayIndex(pressedDayIndex)
        }
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
    }

    const addExercise = (pressedDayIndex) => {
        setDayIndex(pressedDayIndex)
        setShowAddExercise(!showAddExercise)
    }

    const handleOpenEditDayModal = (dayInputIndex) => {
        setDayName(workout.weeks[selectedWeek].days[dayInputIndex].name)
        setSelectedDayIndex(dayInputIndex)
        showModal()
    }

    const editDay = () => {
        let newWorkout = _.cloneDeep(workout);
        newWorkout.weeks[selectedWeek].days[selectedDayIndex].name = dayName
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        hideEditDayModal()
    }

    const renderItem = ({ item, drag, isActive, dayIx }) => {
        return (
          <ScaleDecorator style={{paddingLeft: 0}}>
            <TouchableOpacity
              onLongPress={drag}
              disabled={isActive}
              onPress={() => updateExercise(item)}
              style={[
                styles.rowItem,
                { backgroundColor: isActive ? "red" : 'white', margin: 20, padding: 0 },
              ]}
            >
              <ExerciseCard exercise={item} />
            </TouchableOpacity>
          </ScaleDecorator>
        );
    };

    const renderDayName = (dayIx, selectedDayName) => {
        let regex = /day/i
        let containsDay = regex.test(selectedDayName)
        if(containsDay){
            return `${selectedDayName}`
        } else {
            return `Day ${dayIx + 1}: ${selectedDayName}`
        }
    }
    
    return (
        <View style={styles.root}>
            <Portal>
                <Modal visible={modalVisible} onDismiss={hideEditDayModal} contentContainerStyle={containerStyle}>
                    <Title style={{marginBottom: 40}}>Edit day</Title>
                    <TextInput
                        mode="outlined" 
                        placeholder="Day name"
                        label="Day name"
                        onChangeText={(name) => setDayName(name)}
                        style={{marginBottom: 40}}
                        value={dayName}
                    />
                    <View style={{flexDirection: 'row', marginBottom: 40}}>
                        <View style={{flex: 1}}>
                            <Button
                                style={{marginTop: 20, borderColor: 'red'}}
                                mode="outlined"
                                onPress={() => deleteDay()} 
                                color="red"
                            >
                                Delete day
                            </Button>
                        </View>
                        <View style={{flex: 4, flexDirection: 'row', justifyContent: 'flex-end'}}>
                            <Button
                                style={{marginTop: 20}}
                                onPress={hideEditDayModal} 
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{marginTop: 20, marginLeft: 20}}
                                mode="contained"
                                onPress={() => editDay()} 
                            >
                                Save day
                            </Button>

                        </View>
                    </View>
                </Modal>
            </Portal>
            <View style={styles.sectionRoot}>
                <View style={styles.sectionCtn}>
                    <View style={styles.sectionContent}>
                        <View style={styles.sectionBlock}>
                            <Title style={{textAlign: 'center', fontSize: fontSize}}>Free workout timer</Title>
                        </View>
                        <View style={styles.sectionBlock}>
                            <FreeWorkoutTimerSession 
                                exercises={workout.weeks[0].days[selectedDay].exercises} 
                                closeAddEx={handleShowAddExercise} 
                                restart={restartWorkout}
                                dayNum={selectedDay + 1}
                            />
                        </View>
                        <View style={styles.sectionBlock}>
                        {
                            workout.weeks ? (
                                <View>
                                    {
                                        workout.weeks.map((week, idx) => (
                                            <View>
                                                {
                                                    week.days.map((day, ix) => (
                                                        <View>

                                                            <List.Section style={{paddingLeft: 0}}>
                                                                {
                                                                    day.openAccordion ? (
                                                                        <List.Accordion
                                                                            // title={`Day ${ix + 1}: ${day.name}`}
                                                                            title={renderDayName(ix, day.name)}
                                                                            left={props => <List.Icon {...props} icon="checkbox-marked-circle" color="#008080" />}
                                                                            style={{paddingLeft: 0}}
                                                                            onPress={() => handleDayPress(ix)}
                                                                            expanded={workout.weeks[idx].days[ix].openAccordion}
                                                                        >
                                                                            <List.Item title="Edit day" right={props => (<IconButton size={30} color="#008080" icon="pencil" onPress={() => handleOpenEditDayModal(ix)} />)}/>

                                                                            <DraggableFlatList
                                                                                data={workout.weeks[idx].days[ix].exercises}
                                                                                // onDragEnd={({ data }) => setWorkout(data)}
                                                                                onDragEnd={({ data }) => console.log("TODO://")}
                                                                                keyExtractor={(item) => item.key}
                                                                                // renderItem={() => renderItem(ix)}
                                                                                // renderItem={renderItem}
                                                                                style={{paddingLeft: 0}}
                                                                                renderItem={({ item, drag, isActive }) => (
                                                                                    <ScaleDecorator style={{paddingLeft: 0}}>
                                                                                        <TouchableOpacity
                                                                                        onLongPress={drag}
                                                                                        disabled={isActive}
                                                                                        onPress={() => updateExercise(item, ix)}
                                                                                        style={[
                                                                                            styles.rowItem,
                                                                                            { backgroundColor: isActive ? "red" : 'white', margin: 20, padding: 0 },
                                                                                        ]}
                                                                                        >
                                                                                        <ExerciseCard exercise={item} />
                                                                                        </TouchableOpacity>
                                                                                    </ScaleDecorator>
                                                                                )}
                                                                            />
                                                                            <View style={{ marginTop: 20, marginBottom: 20, paddingLeft: 0}}>
                                                                                <TouchableOpacity style={styles.addExercise} onPress={() => addExercise(ix)}>
                                                                                        <View style={styles.innerAddExercise}>
                                                                                            <IconButton
                                                                                                color="#bdbdbd"
                                                                                                icon="plus"
                                                                                                size={20}
                                                                                            />
                                                                                            <Text style={{color: '#bdbdbd', fontWeight: "bold" }}>Add Exercise</Text>
                                                                                        </View>
                                                                                </TouchableOpacity>
                                                                            </View>
        
                                                                        </List.Accordion>
                                                                    ):(
                                                                        <List.Accordion
                                                                            // title={`Day ${ix + 1}: ${day.name}`}
                                                                            title={renderDayName(ix, day.name)}
                                                                            left={props => <List.Icon {...props} icon="checkbox-blank-circle-outline" color="#008080" />}
                                                                            style={{paddingLeft: 0}}
                                                                            onPress={() => handleDayPress(ix)}
                                                                            expanded={workout.weeks[idx].days[ix].openAccordion}
                                                                        >
                                                                            <DraggableFlatList
                                                                                data={workout.weeks[idx].days[ix].exercises}
                                                                                // onDragEnd={({ data }) => setWorkout(data)}
                                                                                onDragEnd={({ data }) => console.log("TODO://")}
                                                                                keyExtractor={(item) => item.key}
                                                                                style={{paddingLeft: 0}}
                                                                                // renderItem={renderItem}
                                                                                renderItem={({ item, drag, isActive }) => (
                                                                                    <ScaleDecorator style={{paddingLeft: 0}}>
                                                                                        <TouchableOpacity
                                                                                        onLongPress={drag}
                                                                                        disabled={isActive}
                                                                                        onPress={() => updateExercise(item, ix)}
                                                                                        style={[
                                                                                            styles.rowItem,
                                                                                            { backgroundColor: isActive ? "red" : 'white', margin: 20, padding: 0 },
                                                                                        ]}
                                                                                        >
                                                                                        <ExerciseCard exercise={item} />
                                                                                        </TouchableOpacity>
                                                                                    </ScaleDecorator>
                                                                                )}
                                                                            />
                                                                        </List.Accordion>

                                                                    )
                                                                }

                                                            </List.Section>
                                                        </View>
                                                    ))
                                                }
                                            </View>
                                        ))
                                    }
                                </View>
                            ):(null)
                        }

                        </View>
                        <View style={styles.sectionBlock}>
                            {
                                showAddExercise && !edit ? (
                                    <CreateEditExercise 
                                        workout={workout} 
                                        setWorkout={setWorkout} 
                                        weekIndex={0} 
                                        dayIndex={dayIndex} 
                                        display={handleDisplay} 
                                        close={handleShowAddExercise}
                                        edit={edit}
                                    />
                                ):(
                                    null
                                )
                            }
                            {
                                showAddExercise && edit ? (
                                    <CreateEditExercise 
                                        edit={true} 
                                        exercise={exercise} 
                                        workout={workout} 
                                        setWorkout={setWorkout} 
                                        weekIndex={0} 
                                        dayIndex={dayIndex} 
                                        exIndex={exerciseIndex}
                                        display={handleShowAddExercise}
                                        close={handleShowAddExercise}
                                        deleteExercise={deleteExercise}
                                        
                                    />
                                ):(
                                    null
                                )
                            }
                        </View>
                        <View style={styles.sectionBlock}>
                            <Button
                                style={{marginTop: 20}}
                                mode="contained"
                                onPress={() => addDay()} 
                            >
                                Add Day
                            </Button>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <View style={styles.footerCtn}>
                    <View style={{marginBottom: 30, marginTop: 30}}>
                        <Title style={{textAlign: 'center'}}>Made with ❤️ by 
                            <Title> </Title>
                            <Title
                                onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                                style={{textDecorationLine: 'underline', textAlign: 'center'}}
                            >Move Fitness App</Title>
                        </Title>
                    </View>
                    <View style={{marginBottom: 15}}>
                        <Subheading style={{textAlign: 'center'}}>Easiest subscription platform for fitness creators and businesses</Subheading>
                    </View>
                    <View style={{marginBottom: 15}}>
                        <Subheading style={{textAlign: 'center'}}>Workouts • Challenges • On Demand videos</Subheading>
                    </View>
                    <View style={{marginBottom: 30, marginTop: 30}}>
                        <AppStoreBadges />
                    </View>
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    sectionRoot: {
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#E8ECF2",
        minHeight: Dimensions.get('window').height * 0.85,
    },
    sectionCtn: {
        maxWidth: 600,
		width: Dimensions.get('window').width * 1,
    },
    sectionContent: {
        margin: 20
    },
    sectionBlock: {
        marginTop: 10,
        marginBottom: 10
    },
    footer: {
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: 'white',
    },
    footerCtn: {
        maxWidth: Dimensions.get('window').width * 0.75,
        flexDirection: 'column',
        alignItems: 'center',
    },
    addExercise: {
        borderStyle: 'dotted',
        borderWidth: 3,
        borderRadius: 10,
        backgroundColor: 'white',
        borderColor: '#bdbdbd',
        color: '#bdbdbd',
        flexDirection: 'column',
        alignItems: 'center',
    },
    innerAddExercise: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: 'center',
        margin: 15
    },
    dayTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    rowItem: {
        // padding: 0
    }
})

const mapStateToProps = (store) => ({
    workoutStore: store.userState.freeWorkoutTimerWorkout,
})

export default connect(mapStateToProps)(FreeWorkoutTimer);