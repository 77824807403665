import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import NativeFilePicker from './NativeFilePicker';
import WebFilePicker from './WebFilePicker';

export default function CustomPicker({setMediaFile}) {
    const [platform, setPlatform] = useState(null)

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'web') {
                setPlatform('web')
            } else {
                setPlatform('native')
            }
        })();
    }, [])


    return (
        <View>
            {platform === 'native' ? (
                <NativeFilePicker setMediaFile={setMediaFile} />
            ) : null}

            {platform === 'web' ? (
                <WebFilePicker setMediaFile={setMediaFile} />
            ) : null}
        </View>
    )
}

