import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';


export function updateWorkoutExercises(workoutId, updatedWeeks) {
    return ((dispatch) => {
        firebase.firestore()
            .collection('workouts')
            .doc(workoutId)
            .update({
                weeks: updatedWeeks
            })
            .then((result) => {
                console.log("result update workout ", result)
                return result
            })
            .catch((error) => {
                console.log("error update workout ", error)
                return error
            })
    })
}