import * as Linking from 'expo-linking';
import { useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';

const CustomSocialMediaLinks = ({ socials }) => {

    const [formattedSocials, setFormattedSocials] = useState(null);

    useEffect(() => {
        // Data currently structured as array of Objs with name as key :/
        const socialsObj = Object.assign({}, ...socials);
        setFormattedSocials(socialsObj)
        
    }, [])

    const handleLink = (social) => {
        if (social === 'instagram') {
            Linking.openURL(`${formattedSocials.instagram}`);
        } else if (social === 'youtube') {
            Linking.openURL(`${formattedSocials.youtube}`);
        } else if (social === 'twitter') {
            Linking.openURL(`${formattedSocials.twitter}`);
        } else if (social === 'tiktok') {
            Linking.openURL(`${formattedSocials.tiktok}`);
        } else if (social === 'website') {
            Linking.openURL(`${formattedSocials.website}`);
        } else if (social === 'linktree') {
            Linking.openURL(`${formattedSocials.linktree}`);
        } else {
            
        }
    };
    return (
        <View style={styles.container}>
            { formattedSocials ? (
                <View style={styles.socials}>
                    {
                        formattedSocials.instagram ? (
                            <TouchableOpacity
                            onPress={() => handleLink('instagram')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-instagram.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                    {
                        formattedSocials.youtube ? (
                            <TouchableOpacity
                            onPress={() => handleLink('youtube')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-youtube.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                    {
                        formattedSocials.twitter ? (
                            <TouchableOpacity
                            onPress={() => handleLink('twitter')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-twitter.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                    {
                        formattedSocials.tiktok ? (
                            <TouchableOpacity
                            onPress={() => handleLink('tiktok')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-tiktok.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                    {
                        formattedSocials.website ? (
                            <TouchableOpacity
                            onPress={() => handleLink('website')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-website.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                    {
                        formattedSocials.linktree ? (
                            <TouchableOpacity
                            onPress={() => handleLink('linktree')}
                        >
                            <Image
                                style={{ width: 25, height: 25 }}
                                source={require('../../assets/socialIcons/social-icons-linktree.png')}
                            /> 
                        </TouchableOpacity>
                    ):(null)}
                </View>
                ):(null)}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {

    },
    socials: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%'
    }
});

export default CustomSocialMediaLinks;
