import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';

export default function Leaderboard(props) {
    const [leaderboard, setLeaderboard] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getLeaderboard()
    }, [])

    const getLeaderboard = () => {
        firebase.firestore()
            .collection("challenges")
            .doc(props.route.params.challengeId)
            .collection("leaderboard")
            .orderBy("score", "desc")
            .limit(25)
            .get()
            .then((snapshot) => {
                let leaderboardData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setLeaderboard(leaderboardData)
                setLoading(false)
            })
            .catch((error) => {
            });
    }


    return (
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <ScrollView style={styles.container}>
                <Title style={styles.title} >Community Progress</Title>
                {
                    leaderboard !== null && !loading ? (
                        <View>
                            {
                                leaderboard.map((l, i) => (
                                    <View style={styles.cardCtn}>
                                        <View style={styles.card}>
                                            <Title>{i + 1 }.</Title>
                                            <Title>{l.name}</Title>
                                            <Title>{l.score} pts</Title>
                                        </View>
                                    </View>
                                    
                                ))
                            }
                        </View>
                    ):(
                        <View>
                            <ActivityIndicator size="large" color="#008080" />
                        </View>
                    )
                }
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        maxWidth: 600,
        padding: 20,
    },
    title: {
        marginBottom: 20
    },
    cardCtn: {
        marginBottom: 15
    },
    card: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "5%",
        justifyContent: "space-between"
    },
})

