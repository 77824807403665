import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput, Title } from 'react-native-paper';

export default function CreateChallengeTitle({ submit, name, updateName, error }) {
    return (
        <View>
            <Title style={styles.title}>Challenge Name?</Title>
            <TextInput 
                onSubmitEditing={() => submit()}
                error={error}
                value={name}
                onChangeText={name => updateName(name)}
                style={{ minWidth: 300}} 
            />
        </View>
    )
}

const styles = StyleSheet.create({
    title: {
        marginBottom: 30
    }
});