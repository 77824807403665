import { Picker, StyleSheet, View } from 'react-native';
import { Button, Subheading, Text, Title } from 'react-native-paper';

export default function SelectWorkout({ workoutList, setWorkout }) {

    const handleSelectWorkout = (value, index) => {
        try {
            if (value !== 'none') {
                setWorkout(workoutList[index-1])
            } else {
                setWorkout({
                    "weeks": [
                        {
                            "week": "1",
                            "completed": false,
                            "days": [
                                {
                                    "completed": false,
                                    "exercises": [],
                                    "name": ""
                                },
                            ]
                        }
                    ]
                })
            }
        } catch (error) {
            
        }
    }

    return (
        <View>
            <View style={styles.section}>
                <Title style={{marginBottom: 20 }}>Create from workout?</Title>
                <Subheading style={styles.explainerText}>Choose to create a challenge based off a workout you've already created or select none to pick exercises from scratch</Subheading>
                <View>
                {
                    workoutList.length > 0 ? (
                        <View>
                            <Picker
                                // selectedValue={"none"}
                                style={{ height: 50, width: 300 }}
                                onValueChange={(itemValue, itemIndex) => handleSelectWorkout(itemValue, itemIndex)}
                            >
                                <Picker.Item label="None" value="none" />
                                {
                                    workoutList.map(w => (
                                        <Picker.Item label={w.name} value={w.name} />
                                    ))
                                }
                            </Picker>
                        </View>
                    ): (
                        <View>
                            <Text>You have no workouts</Text>
                            <Text>Create or follow a workout first</Text>
                            <Button
                                onPress={()=> 
                                    props.navigation.popToTop()}
                            >
                                Browse Workouts
                            </Button>
                                                        
                        </View>
                    )
                }
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    explainerText: {
        marginTop: 5,
        marginBottom: 15,
        color: "#9e9e9e",
    },
    section: {
        alignItems: "center",
    }, 
});