import * as Analytics from 'expo-firebase-analytics';
import React, { useEffect } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, List, Subheading, Title } from 'react-native-paper';

export default function Faq(props) {

    useEffect(() => {
        (async() => {
            await Analytics.logEvent('[FAQs]: Accessed');
        })();
    }, [])
    
    return (
        <View style={styles.header}>
            <ScrollView>
                <View style={styles.welcome}>
                    <Title style={{marginBottom: 30, color: 'white', fontSize: 30}}>Frequently Asked Questions</Title>
                    <Subheading style={{textAlign: 'center', color: 'white', marginBottom: 15}}>If you can't find an answer that you're looking for, feel free to drop us a line.</Subheading>
                    <Button 
                        mode='outlined' 
                        onPress={() => props.navigation.navigate('contact-us')}
                        style={{color: "#008080", backgroundColor: "white"}}
                    >
                        Contact Us
                    </Button>
                </View>
                <View style={styles.container}>
                    <View style={styles.content}>
                        <View style={styles.faq}>
                            <List.Section>
                                <List.Accordion
                                    title="What is move?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Move is a cure for workout boredom. Move lets you create your own workouts & challenges, play through them, invite friends to join or even 
                                                access content from your favourite fitness content creators"  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How do I create a workout?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Go to the home section. Click on the + symbol in the bottom navigation. Follow workout creation steps. Voilà!"  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How do I create a challenge?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Go to the home section. Click on the + symbol in the bottom navigation. Follow challenge creation steps. You can create a challenge 
                                                off a workout you have already created or build from scratch!"  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How do I play a workout / challenge"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Once you have created or joined a workout or challenge, go to the home section. Click on your profile to see your workouts & challenges.
                                                Click on the workout you want. Expand the week and day. Click on the day or select workout. Click Start Workout button."  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How do I join someone elses workout or challenge?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Navigate to a users profile through a link or clicking on their username. Click on their workout or challenge. Click follow. 
                                                if it is a premium content page you have to subscribe to this user first."  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="I'm a content creator. Should I join Move?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Move is perfect for Yoga, Pilates, Gym and other instructors and influencers looking to drive more traffic and monetise their content. 
                                                Move allows you to create a content library and charge a monthly subscription for users to access."  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How do I charge subscription for my content?"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Go to Home section, go to your profile through the bottom navigation. Click 'Edit profile' up the top. Toggle enable content. 
                                                Follow the steps to set up your payment details on Move. Only takes 2 mins!"  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How does subscriptions work"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="Move allows users to change a monthly subscription to access their premium content. Users can set the amount at their discretion"  
                                    />
                                </List.Accordion>
                                <List.Accordion
                                    title="How does premium content work"
                                    left={props => <List.Icon {...props} />}
                                >
                                    <List.Item
                                        titleNumberOfLines={10} 
                                        title="To make a workout or challenge premium content you must first have set your account up as a paid account. You can then determine which 
                                                content you want paid by selecting 'paid' toggle when creating or editing a workout"  
                                    />
                                </List.Accordion>
                            </List.Section>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        margin: 0,
    },
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: '#E8ECF2'

    },
    content: {
        maxWidth: 600,
        width: "100%",
    },
    welcome: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.50,
        backgroundColor: 'rgba(0,128,128, 1)',
        color: 'white',
    },
    faq: {
        margin: 10,
        maxWidth: 600
    },
})