import { Image, View } from 'react-native';
import { DataTable } from 'react-native-paper';

export default function MoveComparisonTable(props) {

    return (
        <View>
            <DataTable>
                <DataTable.Header>
                    <DataTable.Title></DataTable.Title>
                    <DataTable.Title>
                        <Image
                            style={{ width: 20, height: 20, marginBottom: 20 }}
                            source={require('../../assets/moveIcons/move_icon_teal_bground.png')}
                        /> 
                        {/* <Text style={{fontWeight: 'bold'}}>Move</Text> */}
                    </DataTable.Title>
                    <DataTable.Title>
                        <Image
                            style={{ width: 20, height: 20, marginBottom: 20 }}
                            source={require('../../assets/landingIcons/youtube.png')}
                        /> 
                        {/* <Text style={{fontWeight: 'bold'}}>YouTube</Text> */}
                    </DataTable.Title>
                    <DataTable.Title>
                    <Image
                        style={{ width: 20, height: 20, marginBottom: 20 }}
                        source={require('../../assets/landingIcons/pdf.png')}
                    /> 
                    {/* <Text style={{fontWeight: 'bold'}}>PDFs</Text> */}
                    </DataTable.Title>
                </DataTable.Header>

                <DataTable.Row>
                    <DataTable.Cell>App Apple & Android</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>Website</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>On demand video</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>Playable workouts</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>Challenges</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>Subscriptions</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
                <DataTable.Row>
                    <DataTable.Cell>Leaderboards</DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/tick-white-background.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                    <DataTable.Cell>                            
                        <Image
                        style={{ width: 30, height: 30}}
                        source={require('../../assets/landingIcons/cancel.png')}
                    /> </DataTable.Cell>
                </DataTable.Row>
            </DataTable> 
        </View>
    )
}