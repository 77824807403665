import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';


// Dummy component used for when there was a routing bug
// with react navigation v6 with first route in stack
// when pressing back when on child, would go to empty tab
export default function Faq(props) {

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                <Button
                    mode="contained"
                    onPress={() => 
                        props.navigation.navigate('Home', {
                            screen: 'Main',
                            params: {
                                screen: 'Feed',
                            },
                        })
                    }
                >
                    Return to home
                </Button>

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center',
        backgroundColor: '#E8ECF2'

    },
    content: {
        maxWidth: 600,
        width: "100%",
    },
})