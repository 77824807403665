import { addDoc, collection, getFirestore } from "firebase/firestore";
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Subheading, Text, TextInput, Title } from 'react-native-paper';
import EditProfilePicture from '../media/ProfilePicture/EditProfilePicture';
import CreateWorkoutCategory from '../workouts/CreateWorkout/CreateWorkoutCategory';


let selectCategories = require('../workouts/CreateWorkout/Categories');

const NewCreatorDirectory = (props) => {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [bio, setBio] = useState(null);
    const [website, setWebsite] = useState(null);
    const [instagram, setInstagram] = useState(null);
    const [tiktok, setTiktok] = useState(null);
    const [youtube, setYoutube] = useState(null);
    const [twitter, setTwitter] = useState(null);
    const [message, setMessage] = useState(null);
    const [disable, setDisable] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [categorySelected, setCategorySelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const SPORTS_CATEGORIES = selectCategories.SPORTS_CATEGORIES

    const addAccount = async () => {

        if (!firstName || !lastName || !bio || categorySelected.length === 0) {
        setMessage("Missing required field (first, last name, bio & category) please complete and resubmit")
        setDisable(false)
        return;
        }
        const slug = await createSlug(firstName, lastName)
        const db = getFirestore();
        const docData = {
                firstName,
                lastName,
                bio,
                website,
                instagram,
                tiktok,
                youtube,
                twitter,
                slug,
                categories: categorySelected,
                profileImage: null
        };
        console.log("docData ", docData)
        await addDoc(collection(db, "creatorDirectory"), docData)
          .then(() => {
              console.log('Account added successfully!');
          })
          .catch(error => {
              console.error('Error adding account: ', error);
              setMessage("Error creating. Please try again or contact support.")
              setDisable(false)
          });
    };

    const onChangeSearch = query => {
        setSearchQuery(query)
        searchCategory(query)
    }


    const createSlug = (first, last) => {
        const trimF = first.trim()
        const trimL = last.trim()
        const combine = `${trimF}-${trimL}`
        return combine.toLowerCase()
    }
const searchCategory = (text) => {
    let regex = new RegExp(`(?:${text})`, 'gi');
    const filteredSearch = SPORTS_CATEGORIES.filter( el => el.id.match(regex))
    const displaySearch = filteredSearch.slice(0, 4)
    setCategorySelected(displaySearch)
}
const editCategory = (cat) => {
    // Add in function to remove here. 
    const newCategories = [...categories]
    newCategories.push(cat)
    setCategories(newCategories)
}

  return (
    <View style={styles.container}>
      <View style={styles.titleCtn}>
      <Button 
        onPress={() => props.navigation.navigate("DiscoverInstructors")}
        icon="arrow-left"
        >
            ALL INSTRUCTORS
        </Button>
        <Title>Request to be added</Title>
        <Subheading>Fill in your details below to be added to our directory of online fitness instructors</Subheading>
      </View>
        <TextInput
        label="First Name*"
        value={firstName}
        onChangeText={text => setFirstName(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Last Name*"
        value={lastName}
        onChangeText={text => setLastName(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Bio*"
        value={bio}
        onChangeText={text => setBio(text)}
        mode="outlined"
        style={styles.input}
        multiline={true}
        numberOfLines={4}
      />
      <View>
        <CreateWorkoutCategory onChangeSearch={onChangeSearch} searchQuery={searchQuery} categories={categories} categorySelected={categorySelected} editCategory={editCategory} />
      </View>
      <TextInput
        label="Website"
        value={website}
        onChangeText={text => setWebsite(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Instagram"
        value={instagram}
        onChangeText={text => setInstagram(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Tiktok"
        value={tiktok}
        onChangeText={text => setTiktok(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Youtube"
        value={youtube}
        onChangeText={text => setYoutube(text)}
        mode="outlined"
        style={styles.input}
      />
      <TextInput
        label="Twitter"
        value={twitter}
        onChangeText={text => setTwitter(text)}
        mode="outlined"
        style={styles.input}
      />
    <View>
        <Subheading>Choose a display picture (Optional)</Subheading>
        <EditProfilePicture image={profileImage} updateImage={setProfileImage} />
    </View>
    <View style={{marginBottom: 30}}>
        <Button mode="contained" onPress={addAccount} style={styles.button} disabled={disable} loading={disable}>
        Submit
        </Button>
        { message && <Text style={{marginTop: 10, marginBottom: 15, color: 'red'}}>{message}</Text>}
    </View>
    </View>
  );
};

const styles = StyleSheet.create({
    container: {
    padding: 16,
    },
    input: {
    marginVertical: 8,
    },
    button: {
    marginTop: 16,
    },
    titleCtn: {
    backgroundColor: 'white'
    }
});

export default NewCreatorDirectory;

