import { USER_STATE_CHANGE, USER_POSTS_STATE_CHANGE, USER_WORKOUTS_STATE_CHANGE, USER_FOLLOWING_STATE_CHANGE, USER_WORKOUT_FOLLOWING_STATE_CHANGE, 
            WORKOUTS_USER_FOLLOWING, USER_CHALLENGES_STATE_CHANGE, USER_SUBSCRIPTIONS_STATE_CHANGE, USER_CHALLENGES_JOINED, USER_ONDEMAND_WORKOUT_STATE_CHANGE, 
            USER_ONDEMAND_WORKOUT_SAVED, ON_DEMAND_USER_FOLLOWING, FREE_WORKOUT_TIMER_WORKOUT, CLEAR_DATA } from "../constants";


const initialState = {
    currentUser: null,
    posts: [],
    following: [],
    workoutFollowing: [],
    workouts: [],
    workoutsUserFollowing: [],
    challenges: [],
    subscriptions: [],
    challengesJoined: [],
    onDemandWorkouts: [],
    ondemandWorkoutSaved: [],
    onDemandUserFollowing: [],
    freeWorkoutTimerWorkout: {}
}

export const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_STATE_CHANGE:
            return {
                ...state,
                currentUser: action.currentUser
            }
            
        case USER_POSTS_STATE_CHANGE:
            return {
                ...state,
                posts: action.posts
            }
        case USER_WORKOUTS_STATE_CHANGE:
            return {
                ...state,
                workouts: action.workouts
            }
        case USER_ONDEMAND_WORKOUT_STATE_CHANGE:
            return {
                ...state,
                onDemandWorkouts: action.onDemandWorkouts
            }
        case USER_FOLLOWING_STATE_CHANGE:
            return {
                ...state,
                following: action.following
            }
        case USER_WORKOUT_FOLLOWING_STATE_CHANGE:
            return {
                ...state,
                workoutFollowing: action.workoutFollowing
            }
        case WORKOUTS_USER_FOLLOWING:
            return {
                ...state,
                workoutsUserFollowing: action.workoutsUserFollowing
            }
        case USER_CHALLENGES_STATE_CHANGE:
            return {
                ...state,
                challenges: action.challenges
            }
        case USER_SUBSCRIPTIONS_STATE_CHANGE:
            return {
                ...state,
                subscriptions: action.subscriptions
            }
        case USER_CHALLENGES_JOINED:
            return {
                ...state,
                challengesJoined: action.challengesJoined
            }
        case USER_ONDEMAND_WORKOUT_SAVED:
            return {
                ...state,
                ondemandWorkoutSaved: action.ondemandWorkoutSaved
            }
        case ON_DEMAND_USER_FOLLOWING:
            return {
                ...state,
                onDemandUserFollowing: action.onDemandUserFollowing
            }
        case FREE_WORKOUT_TIMER_WORKOUT:
            return {
                ...state,
                freeWorkoutTimerWorkout: action.freeWorkoutTimerWorkout
            }
        case CLEAR_DATA:
            return initialState
        default:
            return state;
            
    }

}