import { MOVE_URL } from "@env";
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View, Image } from 'react-native';
import { Button, Subheading, Text, TextInput, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUser } from '../../redux/actions/index';
import CurrencyCountrySelect from '../CustomComponents/CurrencyCountrySelect/CurrencyCountrySelect';

function CreateStripeAccount(props) {
    const navigation = useNavigation();
    const route = useRoute();

    const [loading, setLoading] = useState(false);
    const [price, setPrice] = React.useState(null);
    const [message, setMessage] = useState(null);
    const [currency, setCurrency] = useState({ code: 'US', label: 'United States dollar',  currency: 'usd', symbol: '$' });
    const [status, setStatus] = useState('onboarding');
    const [urlSuccess, setUrlSuccess] = useState('');
    const [urlFailed, setUrlFailed] = useState('');
    const [result, setResult] = useState(null);
    const [finished, setFinished] = useState(false);

    useEffect(() => {

        (async () => {
            if (Platform.OS === 'web') {
                setUrlSuccess(`${MOVE_URL}/payment/?acc_created=success`)
                setUrlFailed(`${MOVE_URL}/payment/?acc_created=failed`)
            } else {
                // Stripe does not accept deeplink
                // https://stackoverflow.com/questions/68695786/stripe-connect-account-return-url-link-back-to-expo-application
                // Todo: Create Onboarding on Mobile
                setStatus('mobile')
                // // Create a DeepLink to our App
                // const successDeepLink = Linking.createURL('payment', {
                //     queryParams: { acc_created: 'success' },
                // });
                // const failedDeepLink = Linking.createURL('payment', {
                //     queryParams: { acc_created: 'failed' },
                // });
                // setUrlSuccess(successDeepLink)
                // setUrlFailed(failedDeepLink)
            }
        })();

        const unsubscribe = navigation.addListener('focus', () => {
            // ⚠️ WARNING 
            // You have a critical part of your app with janky logic
            // This code is in place to force a retrigger when you get navigated
            // here erroneously because react navigation web is a pain to deal with
            if (!props.currentUser) {
                console.log('Refreshed!');
                navigation.navigate('Home', {
                    screen: 'Main',
                    params: {
                        screen: 'Feed'
                    },
                });
            }
        })

        if (props.route.params) {
            if (props.route.params.acc_created === 'success') {
                console.log("props.route.params.acc_created === 'success'", props.route.params.acc_created)
                setStatus('success')
                setFinished(true)
                firebase.firestore()
                    .collection("users")
                    .doc(firebase.auth().currentUser.uid)
                    .update({
                        paid: true
                    })
                    .then((result) => {
                        setLoading(false)
                        props.fetchUser()

                        // navigation.reset({
                        //     index: 0,
                        //     routes: [{ name:  'Feed'}],
                        // });
                        // navigation.navigate('Home', {
                        //     screen: 'Main',
                        //     params: {
                        //         screen: 'Profile',
                        //         params: {
                        //         uid: firebase.auth().currentUser.uid,
                        //         },
                        //     },
                        // });
                    })
                    .catch((error) => {
                        setMessage("Error updating please try again")
                        setLoading(false)
                    })
            } else if (props.route.params.acc_created === 'failed') {
                console.log("props.route.params.acc_created === 'failed'", props.route.params.acc_created)
                // setStatus('failed')
                // BUG
                // Return URl coming back twice. Not sure why.
                // Passes first time. Fails the second. 
            } else {
                console.log("account_created had nothing")
                setMessage("There may have been an error setting up your stripe account. Please try again or contact support")
                setLoading(false)
            }
        }

        return unsubscribe;
    }, [navigation]);

    const createAccount = async () => {
        // 1. Create a Stripe Product through Stripe API
        // 2. Create a Stripe Price through Stripe API
        // 3. Update userStripeAccount (Product, Price)
        // 3. Create Stripe Account Link
        setLoading(true)
        setMessage(null)
        const valid = await validate()
        if (valid && !finished) {
            createStripeAccount()
        }
    }

    const createStripeProduct = async () => {
        const createProduct = firebase.functions().httpsCallable('createStripeProduct');
        return createProduct({name: props.currentUser.name})
    }
    const createStripePrice = async (product_id) => {
        const priceInCents = parseInt(Math.round(price * 100))
        const createPrice = firebase.functions().httpsCallable('createStripePrice');
        return createPrice({price_in_cents: priceInCents, currency: currency, product_id: product_id})
    }

    const createStripeAccount = async () => {
        const priceInCents = parseInt(Math.round(price * 100))
        const createAcc = firebase.functions().httpsCallable('createStripeAccount');
        createAcc({ 
            user_id: firebase.auth().currentUser.uid, 
            return_url_success: urlSuccess, 
            return_url_failed: urlFailed, 
            country: currency, 
            user_name: props.currentUser.name,
            price_in_cents: priceInCents
        })
        .then( result => {
            console.log("result", result.data)
            navigation.navigate("Create Stripe", {uri: result.data.url})
            setLoading(false)
        })
        .catch((error) => {
            setMessage("Error creating your stripe account. Please try again later.")
            setLoading(false)
        })
    }

    const updateUser = async (product_id, price_id) => {
        const cleanPrice = parseFloat(price)
        const p = cleanPrice.toFixed(2)
        const parsedPrice = parseFloat(p)
        firebase.firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .update({
                price: parsedPrice,
                product_id,
                price_id
            })
    }

    const validate = () => {
        if (isNaN(parseFloat(price))) {
            setMessage("Not a valid price. Please enter a valid number e.g. 10 or 10.50")
            setLoading(false)
            return false
        } else if(!props.currentUser) {
            setMessage("Not logged in. Please log in and try again")
            setLoading(false)
            return false
        } else {
            return true
        }
    }

    const handleCurrency = (c) => {
        setCurrency(c)
    }

    const handleOpenWithLinking = () => {
        Linking.openURL(url);
    };

    const handleOpenWithWebBrowser = () => {
        WebBrowser.openBrowserAsync(`${MOVE_URL}/login`);
        setResult(result);
    };

    return (
        <View style={styles.container}>
            { 
                status === 'onboarding' &&
                <View>
                    <Title style={{fontSize: 'x-large', marginBottom: 30, textAlign: 'center'}}>Payment Details</Title>
                    <View style={{marginBottom: 30}}>
                        <Subheading style={{fontSize: 'x-large', marginBottom: 10}}>Currency</Subheading>
                        <Text style={{color: "#9e9e9e", marginTop: 5, marginBottom: 10}}>Select currency of bank account you will link</Text>
                        <CurrencyCountrySelect handleCurrencyChange={handleCurrency} />
                    </View>
                    <View style={{marginBottom: 30}}>
                        <Subheading style={{fontSize: 'x-large', marginBottom: 10}}>Price</Subheading>
                        <Text style={{color: "#9e9e9e", marginTop: 5, marginBottom: 5}}>Enter amount people will pay per month to access your content </Text>
                        <TextInput
                            mode="outlined" 
                            placeholder="E.g. 15.50"
                            onChangeText={(text) => setPrice(text)}
                            style={{marginBottom: 20}}
                            value={price}
                        />
                        
                    </View>
                    { message && <Text style={{color: 'red', marginBottom: 10}}>{message}</Text>}

                    <Button 
                        mode="contained" 
                        onPress={() => createAccount()}
                        disabled={loading || price === null}
                        loading={loading}
                    >
                        Setup Payment Details
                    </Button>

                </View>
            }

            {
                status === 'success' &&
                <View>
                    {loading ? (
                        <View style={styles.success}>
                            <Title>Saving your account</Title>
                            <ActivityIndicator size="large" color="#008080"  />
                        </View>
                    ):(
                        <View style={styles.success}>
                            <Image
                                style={{ width: 100, height: 100, marginBottom: 30 }}
                                source={require('../../assets/tick-white-background.png')}
                                /> 
                            <Title style={{marginBottom: 15}}>Premium account created!</Title>
                            <Text style={styles.explainerText} >You can now create premium content only subscribed users can access </Text>
                            <Button
                                mode="contained"
                                onPress={()=> navigation.navigate('Home', {
                                    screen: 'Main',
                                    params: {
                                        screen: 'Profile',
                                        params: {
                                        uid: firebase.auth().currentUser.uid,
                                        },
                                    },
                                })}
                            >Home</Button>
                    </View>
                    )}
                </View>
            }

            {
                status === 'failed' &&
                <View>
                    <Title style={{marginBottom: 15}} >Create Stripe Account Failed</Title>
                    <Text style={{marginBottom: 30}}>There was an error setting up your stripe account. You may not have completed all the details on the stripe form. 
                        Your account is not yet premium. Please try again later</Text>
                    { message && <Text style={{marginTop: 10, marginBottom: 30, color: 'red'}}>{message}</Text>}
                    <Button
                        mode="contained"
                        onPress={() => 
                            navigation.navigate('Home', {
                                screen: 'Main',
                                params: {
                                    screen: 'Feed',
                                },
                            })
                        }
                    >Home</Button>
                </View>
            }

            {
                status === 'mobile' &&
                <View>
                    <Title>Enable Paid Account</Title>
                    <Text>Onboarding the account for us to pay you is not yet available on Mobile</Text>
                    <Text>Please Enable from Move Website</Text>
                    <Button mode="contained" size="large" onPress={handleOpenWithWebBrowser}>Create Payment Details</Button>
                </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'space-around',
        backgroundColor: 'white',
        padding: '5%',
    },
    input: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'flex-end',
        alignItems: 'center'
    },
    success: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    explainerText: {
        color: "#9e9e9e",
        marginBottom: 30,
        justifyContent: 'center'
    },
}); 

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUser }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(CreateStripeAccount);
