import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View, Pressable } from 'react-native';
import { IconButton } from 'react-native-paper';
import ExerciseCard from '../exercises/ExerciseListCard';

export default function SessionExercises({ 
    exercises, 
    selectedExerciseIndex, 
    setSelectedExerciseIndex, 
    isChallenge, 
    challengeId,  
    workoutId, 
    weekIndex, 
    dayIndex, 
    workout, 
    challenge }) {
    const navigation = useNavigation();

    useEffect(() => {
    }, []);

    const handleEditAction = (exercise, exerciseIndex) => {

        const navParams = {
            exercise: exercise,
            exerciseIndex: exerciseIndex,
            isChallenge: isChallenge,
            challengeId: challengeId,
            workoutId: workoutId,
            weekIndex: weekIndex,
            dayIndex: dayIndex,
        }
        if (isChallenge){
            navParams["weeks"] = challenge.weeks
        } else {
            navParams["weeks"] = workout.weeks
        }
        navigation.navigate('Content', {screen: 'Edit Exercise',
            params: navParams,})
    };

    const borderColour = (exercise, exIndex) => {
        if (exIndex === selectedExerciseIndex) {
            return "#2196F3"
        }
        
        if (exercise?.completed) {
            return "#4CAF50"
        }
    }

    const borderWidth = (exercise, exIndex) => {
        if (exIndex === selectedExerciseIndex || exercise?.completed) {
            return 2
        } 
        return 0
    }

    return (
        <View>
            <View>
                {exercises !== null ? (
                    <ScrollView>
                        {exercises.map((ex, index) => (
                            <View style={styles.exerciseCtn} key={index}>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <TouchableOpacity
                                        onPress={() => setSelectedExerciseIndex(index)}
                                        style={{flex: 8, borderColor: borderColour(ex, index), borderWidth: borderWidth(ex, index), borderRadius: 10}}
                                    >
                                        <ExerciseCard exercise={ex} />
                                    </TouchableOpacity>
                                    <TouchableOpacity  style={{flex: 1}}>
                                        <IconButton icon="pencil" color="#008080" size={24} onPress={() => handleEditAction(ex, index)} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        ))}
                    </ScrollView>
                ) : null}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    content: {
        maxWidth: 600,
        width: '100%',
        padding: 20,
    },
    exerciseCtn: {
        marginBottom: 15,
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 20,
    },
});
