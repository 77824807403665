import React  from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { Title, Subheading } from 'react-native-paper';

import Media from '../media/index'

export default function ChallengeCard({challenge}) {

    return (
        <View style={styles.container}>
            <View style={styles.image}>
                { challenge.image && challenge.image.url.length > 0 ? (
                    <View>
                        <Media contentType={challenge.image.metadata.type} url={challenge.image.url} component='exercise' />  
                    </View>
                ):(
                    <View style={{padding: 10}}>
                        <Image
                            style={{ minHeight: 60, minWidth: 60, maxHeight: 60, maxWidth: 60 }}
                            source={require('../../assets/dumbell.png')}
                        /> 
                    </View>
                )}
            </View>
            <View style={styles.name}>
                <Title>{challenge.name}</Title>
                <Subheading style={styles.explainerText}>{challenge.weeks.length} Weeks</Subheading>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "2%"
    },
    imgLoading: {
        justifyContent: 'center'
    },
    name: {
        flex: 2
    },
    image: {
        flex: 1
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
})
