import React, { useState } from 'react';
import { View, Alert } from 'react-native';
import { Button, Dialog, Paragraph, Portal } from 'react-native-paper';
import firebase from 'firebase/app';
import 'firebase/functions';

const DeleteAccount = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      const deleteUserAccount = firebase.functions().httpsCallable('deleteUserAccount');
      await deleteUserAccount();
      Alert.alert('Account set inactive successfully');
      await firebase.auth().signOut();
        navigation.navigate('Home', {
            screen: 'Main',
            params: {
                screen: 'Feed',
            },
        })
    } catch (error) {
      Alert.alert('Error deleting account', error.message);
    }
    setLoading(false);
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', padding: 20 }}>
      <Button
        mode="contained"
        color="#f44336"
        disabled={loading}
        onPress={() => setVisible(true)}
      >
        Delete Account
      </Button>
      <Portal>
        <Dialog visible={visible} onDismiss={() => setVisible(false)}>
          <Dialog.Title>Delete my Move account</Dialog.Title>
          <Dialog.Content>
            <Paragraph>Are you sure you want to delete your account?</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={() => setVisible(false)}>Cancel</Button>
            <Button
                color='red'
                mode='contained'
                onPress={handleDeleteAccount}
            >
                Delete
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default DeleteAccount;
