import { useNavigation } from '@react-navigation/native';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useState } from 'react';
import { Dimensions, Image, StyleSheet, View } from 'react-native';
import { Button, IconButton, Modal, Paragraph, Portal, Provider, Subheading, Title } from 'react-native-paper';
import RestScreen from '../../exercises/RestScreen';
import Media from '../../media/index';
import SessionSelectedWorkout from './SessionSelectedWorkout';

export default function FreeWorkoutTimerSession({ exercises, closeAddEx, restart, dayNum }) {
    const navigation = useNavigation();
    const [challenge, setChallenge] = useState(null)
    const [exerciseSets, setExerciseSets] = useState(1)
    const [exerciseRest, setExerciseRest] = useState(false)
    const [step, setStep] = useState(0)
    const [sessionStep, setSessionStep] = useState(0)
    const [imageLoading, setImageLoading] = useState(true)
    const [complete, setComplete] = useState(false)
    const [visible, setVisible] = React.useState(false);
    const [finishLoading, setFinishLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [started, setStarted] = useState(false);
    const [play, setPlay] = useState(false);

    const imageLoaded = () => {
        setImageLoading(false)
    }

    const increaseStep = () => {

        const newStep = step + 1
        if (newStep === exercises.length) {
            const setsAndRestsComplete = checkSetsAndRests()
            if (setsAndRestsComplete) {
                if (challenge) {
                    completeChallenge()
                }
                setComplete(true)
                setSessionStep(2)
            }
        } else {
            // Sets
            const setsAndRestsComplete = checkSetsAndRests()
            if (setsAndRestsComplete) {
                setStep(newStep)
            }
        }
    }

    const decreaseStep = () => {
        setStep(step - 1)
    }

    const checkSetsAndRests = () => {
        // Yuck.
        if (exercises[step].sets) {
            if (parseInt(exercises[step].sets) == exerciseSets) {
                if (exercises[step].repsRest && exerciseRest) {
                    // Sets complete and Is currently in rest state
                    setExerciseSets(1)
                    setExerciseRest(false)
                    setSessionStep(0)
                    return true
                } else if (exercises[step].repsRest && exerciseRest === false) {
                    // Go to exercise rest
                    setSessionStep(1)
                    setExerciseRest(true)
                    return false
                }
            } else if (exerciseSets < parseInt(exercises[step].sets)) {
                if (exercises[step].repsRest && exerciseRest) {
                    // Sets not complete and Is currently in rest state
                    // Go to next exercise set
                    setExerciseRest(false)
                    const newSet = exerciseSets + 1
                    setExerciseSets(newSet)
                    setSessionStep(0)
                    return false
                } else if (exercises[step].repsRest && exerciseRest === false) {
                    // Go to the exercise rest
                    setSessionStep(1)
                    setExerciseRest(true)
                    return false
                }
            } else {
            }
        } else {
            return true
        }
    }

    const handleStep = (operation) => {
        if (operation === 'add') {
            increaseStep()
        } else if (operation === 'sub') {
            decreaseStep()
        } 
    }

    const initialiseWorkout = () => {
        setErrorMessage(null)
        try {
            const testExerciseValid = exercises[step].name
            setPlay(true)
            closeAddEx()
        } catch (error) {
            console.error(error)
            setErrorMessage("Create at least 1 exercise to start")
        }
    }

    const startWorkout = (dummyVar) => {
        setStarted(true)
    }

    const handleRestart = () => {
        setPlay(false)
        setComplete(false)
        setStarted(false)
        setSessionStep(0)
        setStep(0)
        restart()
    }

    const showModal = () => setVisible(true);

    const hideModal = () => setVisible(false);
    
    const containerStyle = {backgroundColor: 'white', padding: 20};

    return (
        <View style={styles.container}>
            <View style={styles.contents}>
                {
                    started ? (
                        <View style={styles.contentCtn}>
                            {exercises !== null ? (
                                {
                                    0: 
                                        <View style={styles.cardTopCtn}>
                                            <Subheading style={{fontWeight: "bold"}}>Exercise {step + 1} / {exercises.length}</Subheading>
                                            <View style={styles.cardCtn}>
                                                <View style={styles.title}>
                                                    <Title>{exercises[step].name}</Title>
                                                    <View style={styles.titleInfo}>
                                                        { exercises[step].reps && <Subheading style={styles.explainerText}>(Set {exerciseSets} of {exercises[step].sets})</Subheading>}
                                                        { exercises[step].instructions.length > 0 &&   
                                                            <IconButton
                                                                icon="information-outline"
                                                                size={20}
                                                                onPress={showModal}
                                                                color="#008080"
                                                            />
                                                        }
                                                    </View>
                                                </View>
                                                <View style={styles.imgCtn}>
                                                    { exercises[step].image && exercises[step].image.url.length > 0 ? (
                                                        <View>
                                                            <Media contentType={exercises[step].image.metadata.type} url={exercises[step].image.url} component='cover' />  
                                                        </View>
                                                    ):(
                                                        <View>
                                                            <Image
                                                                style={{ width: "100%", height: "auto", minHeight: 200, minWidth: 200 }}
                                                                source={require('../../../assets/dumbell.png')}
                                                            /> 
                                                        </View>
                                                    )}
                                                </View>
                                                <View style={styles.infoCtn}>
                                                    { exercises[step].reps && <Subheading style={styles.explainerText}>{exercises[step].sets} sets x {exercises[step].reps} reps</Subheading>}
                                                    { exercises[step].weight && <Subheading style={styles.explainerText}>{exercises[step].weight} {exercises[step].weightUOM}</Subheading>}
                                                    { exercises[step].distance && <Subheading style={styles.explainerText}>{exercises[step].distance} {exercises[step].distanceUOM}</Subheading>}
                                                    { exercises[step].time && <Subheading style={styles.explainerText}>{exercises[step].time}</Subheading>}
                                                </View>
                                            </View>
                                        </View>
                                        ,
                                    1: 
                                        <View style={styles.rest}>
                                            <View style={{justifyContent: 'center', alignItems: 'center', marginBottom: 20}}>
                                                <Title>REST</Title>
                                            </View>
                                            <RestScreen next={handleStep} rest={exercises[step].repsRest} />
                                        </View>
                                        ,
                                    2:
                                        <View style={styles.complete}>
                                            <Title style={{color: "#008080"}}>Completed!!</Title>
                                            <Subheading>Signup to save & track workout</Subheading>
                                            <Image
                                                style={{ width: 150, height: 150 }}
                                                source={require('../../../assets/moveIcons/koala_victory_transparent_1.gif')}
                                            /> 
                                            <Button
                                                style={{color: "#008080", backgroundColor: "white"}}
                                                onPress={()=> handleRestart()}
                                            >Home</Button>
                                            <View style={styles.footerCtn}>
                                                <Subheading>Made with ❤️ by </Subheading>
                                                    <Subheading
                                                        onPress={() => navigation.navigate("SellFitnessContentOnline")}
                                                        style={{textDecorationLine: 'underline'}}
                                                    >Move Fitness App</Subheading>
                                            </View>
                                            <Button
                                                style={{}}
                                                mode="contained"
                                                onPress={()=> navigation.navigate("SellFitnessContentOnline")}
                                                disabled={finishLoading}
                                                loading={finishLoading}
                                            >Signup to save</Button>
                                        </View>
                                }[sessionStep]
                            ):(
                                null
                            )}
                            <View style={styles.buttonsCtn}>
                                <IconButton 
                                    icon="rewind" 
                                    onPress={() => handleStep('sub')} 
                                    disabled={step === 0} 
                                    style={styles.iconButton}
                                    color="#008080"
                                    size={40} 
                                />
                                <IconButton 
                                    icon="pause" 
                                    onPress={() => console.log('Pressed')} 
                                    style={styles.iconButton}
                                    color="#008080"
                                    size={40}  
                                />
                                <IconButton 
                                    icon="fast-forward" 
                                    onPress={() => handleStep('add')}
                                    disabled={step === exercises.length || complete} 
                                    style={styles.iconButton}
                                    color="#008080"
                                    size={40}  
                                />
                            </View>
                            <Provider>
                                <Portal>
                                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                                        <Title>Exercise Description</Title>
                                        <Paragraph>{exercises[step].instructions}</Paragraph>
                                    </Modal>
                                </Portal>
                            </Provider>
                        </View>
                    ):(
                        <View style={{marginBottom: 50}}>
                                {
                                    play ? (
                                        <View>
                                            <RestScreen next={() => startWorkout()} rest={5} play={play} />
                                            <Title style={{textAlign: "center"}}>Starting workout</Title>
                                        </View>
                                    ):(
                                        <SessionSelectedWorkout
                                            dayNum={dayNum}
                                            exerciseNum={exercises.length}
                                            play={play}
                                            errorMessage={errorMessage}
                                            startWorkout={initialiseWorkout}
                                        />
                                )} 
                        </View>
                )}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    buttonsCtn: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-evenly"
    },
    cardCtn: {
        marginTop: 20,
        minHeight: Dimensions.get('window').height * 0.65,
        justifyContent: 'space-around',
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center'
    },
    rest: {
        minHeight: Dimensions.get('window').height * 0.65,
        justifyContent: 'center'
    },
    complete: {
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: Dimensions.get('window').height * 0.75,
    },

    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
    imageStyle: {
        width: 150, height: 150
    },
    imageStyleDesktop: {
        width: 300, height: 300
    }

})

