import * as Analytics from 'expo-firebase-analytics';
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Divider, Subheading, Title } from 'react-native-paper';
import SocialMediaLinks from "../Socials/SocialMediaLinks";
import DefaultBlog from "./DefaultBlog";
import InterviewBlog from "./InterviewBlog";

export default function Blog(props) {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async() => {
      await Analytics.logEvent(`[Blog]: ${props.route.params.slug}`);
    })();
    getBlog()
  }, [props.route.params.slug]);

  const getBlog = async() => {
    try {
      const db = getFirestore();
      const q = query(collection(db, "blogs"), where("active", "==", true), where("slug", "==", `${props.route.params.slug}`), limit(1));
      const querySnapshot = await getDocs(q);
      let resp = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;
        return{id, ...data}
      })
      // Set the first item 
      // Should only ever be 1 anyways
      setBlog(resp[0]);
      setLoading(false);      
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ScrollView>


    <View style={styles.root}>
      <View style={styles.setCtnWidth}>
        <View style={styles.btn}>
          <Button 
            onPress={() => props.navigation.navigate("Blogs")}
            icon="arrow-left"
          >
              ALL POSTS
          </Button>
        </View>
          {loading ? (
            <View style={styles.loadingCtn}>
              <ActivityIndicator size="large" color="#008080" />
            </View>
          ) : (
          <View>
            {blog.blogType === 'interview' && <InterviewBlog blog={blog} navigation={props.navigation} />}
            {blog.blogType === 'default' && <DefaultBlog blog={blog} navigation={props.navigation} />}
          </View>
        )}
        <Divider style={styles.divider} />
        <View style={styles.otherBlogs}>

        </View>
        <View style={styles.marketing}>
          
          <Title style={{marginBottom: 30, fontSize: 'x-large', textAlign: 'center'}}>Looking to make more money selling fitness content online?</Title>
          <Subheading style={{marginBottom: 30, textAlign: 'center'}} >Move Fitness is a free online fitness content marketplace for fitness creators. Earn more online and grow your audience with Move</Subheading>
          <Button 
            onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
            mode="contained"
            style={{marginBottom: 30}}
          >
              Find out more
          </Button>
        </View>
        <View style={styles.socials}>
          <SocialMediaLinks />
        </View>
      </View>
    </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  setCtnWidth: {
      maxWidth: 800,
      width: "100%",
      padding: 20,
      
  },
  btn: {
    marginTop: 10,
    marginBottom: 10
  },
  divider: {
      marginTop: 100,
      marginBottom: 100
  },
  sectionHeaderDesktop: {
      marginTop: 30,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
  },
  headlineTitle: {
      fontSize: 30,
      marginBottom: 30,
      textAlign: 'center'
  },
  headlineTitleDesktop: {
      fontSize: 50,
      marginBottom: 35,
      textAlign: 'center'
  },
  subTitleDesktop: {
    fontWeight: "bold",
    fontSize: "x-large",
    // fontSize: 32,
    marginTop: 20,
    marginBottom: 20
  },
  subTitle: {
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20
  },
  heading: {
    marginTop: 20,
    marginBottom: 20,
  },
  img: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    marginBottom: 30,
  },
  image: {
    height: 300,
    width: 300,
    borderRadius: 50
  },
  blogCtn: {
    marginTop: 30,
    marginBottom: 30,
  },
  questionCtn: {
    marginTop: 30,
    marginBottom: 30,
  },
  marketing: {
    marginTop: 30,
    marginBottom: 30,
  },
  socials: {
    marginTop: 30,
    marginBottom: 30,
  },
  loadingCtn: {
    height: Dimensions.get('window').height * 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

})
