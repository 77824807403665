import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';

export function updateUserChallengeExercises(userChallengeId, updatedWeeks) {
    return ((dispatch) => {
        firebase.firestore()
            .collection("userChallenges")
            .doc(firebase.auth().currentUser.uid)
            .collection("challenges")
            .doc(userChallengeId)
            .update({
                weeks: updatedWeeks
            })
            .then((result) => {
                console.log("result update challenge ", result)
                return result
            })
            .catch((error) => {
                console.log("error update challenge ", error)
                return error
            })
    })
}