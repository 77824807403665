import Constants from 'expo-constants';
import React, { useState, useEffect, } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';

export default function RestScreen({rest, next, play}) {
    const [isPlaying, setIsPlaying] = useState(false)
    useEffect(() => {

      if (play === false) {
        setIsPlaying(play)
      } else {
        // Set default to True
        setIsPlaying(true)
      }
    }, [play])
    return (
      <View style={styles.container}>
        <CountdownCircleTimer
          isPlaying={isPlaying}
          duration={rest}
          colors={[
            ['#008080', 0.4],
            ['#F7B801', 0.4],
            ['#A30000', 0.2],
          ]}
          onComplete={() => next('add')}
      >
        {({ remainingTime, animatedColor }) => (
          <Animated.Text style={{ color: animatedColor, fontSize: 40 }}>
            {remainingTime}
          </Animated.Text>
        )}
      </CountdownCircleTimer>
    </View>
    )
}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: Constants.statusBarHeight,
        padding: 8,
      }
})
