import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Subheading } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUserWorkouts } from '../../../redux/actions';
import CreateExercises from '../../workout_v2/CreateExercises/CreateExercises';
import CreateWorkoutCategory from './CreateWorkoutCategory';
import CreateWorkoutCoverImg from './CreateWorkoutCoverImg';
import CreateWorkoutDescription from './CreateWorkoutDescription';
import CreateWorkoutPaid from './CreateWorkoutPaid';
import CreateWorkoutPublic from './CreateWorkoutPublic';
import CreateWorkoutTitle from './CreateWorkoutTitle';
import SubmitCreateWorkout from './SubmitCreateWorkout';

let selectCategories = require('./Categories');
const _ = require('lodash');

function CreateWorkout(props) {

    const [step, setStep] = useState(0)
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(false);
    const [publicWorkout, setPublicWorkout] = useState(true);
    const [paid, setPaid] = useState(false);
    const [paidMessage, setPaidMessage] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [categorySelected, setCategorySelected] = useState([]);
    const [categories, setCategories] = useState([]);
    const SPORTS_CATEGORIES = selectCategories.SPORTS_CATEGORIES
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    
    const [workout, setWorkout] = useState({
        "weeks": [
            {
                "week": "1",
                "completed": false,
                "days": [
                    {
                        "completed": false,
                        "exercises": [],
                        "name": ""
                    },
                ]
            }
        ]
    })

    const decreaseStep = () => {
        setStep(step - 1)
    }

    const increaseStep = async () => {
        switch (step) {
            case 0:
                const titleValid = await validateTitle()
                if (titleValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 1:
                setStep(step + 1)
                break;
            case 2:
                setStep(step + 1)
                break;
            case 3:
                setStep(step + 1)
                setPaidMessage(null)
                setErrorMessage(null)
                setError(false)
                break;
            case 4:
                const catValid = await validateCategory()
                if (catValid) {
                    setStep(step + 1)
                    setErrorMessage(null)
                    setError(false)
                }
                break;
            case 5:
                setStep(step + 1)
                break;
            case 6:
                setStep(step + 1)
                break;
            case 6:
                setStep(step + 1)
                break;
        }
    }

    const onTogglePublic = () => setPublicWorkout(!publicWorkout);

    const onTogglePaid = () => {
        setPaidMessage(null)
        if (!paid) {
            if (props.currentUser.paid) {
                setPaid(!paid)
            } else {
                setPaidMessage("Must setup your account as a paid account to charge for workouts. Visit your edit profile page to set up. It only takes 3 minutes!")
            }
        } else {
            setPaid(!paid)
        }
    };

    const validateTitle = () => {
        if (name.length > 1) {
            return true
        } else {
            setErrorMessage("Name not long enough")
            setError(true)
            return false
        }
    }

    const validateCategory = () => {
        if (categories.length > 0) {
            return true
        } else {
            setErrorMessage("Please select category")
            setError(true)
            return false
        }
    }

    const handleEditProfile = () => {
        props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
    }

    const onChangeSearch = query => {
        setSearchQuery(query)
        searchCategory(query)
    }

    const searchCategory = (text) => {
        let regex = new RegExp(`(?:${text})`, 'gi');
        const filteredSearch = SPORTS_CATEGORIES.filter( el => el.id.match(regex))
        const displaySearch = filteredSearch.slice(0, 4)
        setCategorySelected(displaySearch)
    }

    const editCategory = (cat) => {
        // Add in function to remove here. 
        const newCategories = [...categories]
        newCategories.push(cat)
        setCategories(newCategories)
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.content}>
                {
                    props.currentUser !== null ? (
                        {
                            0: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutTitle name={name} updateName={setName} submit={() => increaseStep()} error={error} />
                                </View>
                                ,
                            1: 
                                <ScrollView style={styles.workoutStepCtn}>
                                    <CreateExercises workout={workout} setWorkout={setWorkout} />
                                </ScrollView>
                                ,
                            2: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutPublic publicWorkout={publicWorkout} onTogglePublic={() => onTogglePublic()} />
                                </View>
                                ,
                            3: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutPaid paid={paid} onTogglePaid={onTogglePaid} />
                                </View>
                                ,
                            4: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutCategory onChangeSearch={onChangeSearch} searchQuery={searchQuery} categories={categories} categorySelected={categorySelected} editCategory={editCategory} />
                                </View>
                                ,
                            5: 
                                <View style={styles.stepCtn}>
                                    <CreateWorkoutDescription description={description} setDescription={setDescription} />
                                </View>
                                ,
                            6: 
                                <ScrollView style={styles.workoutStepCtn}>
                                    <CreateWorkoutCoverImg determineFileType={determineFileType} image={image} setImage={setImage} />
                                </ScrollView>
                                ,
                            7:
                                <View style={styles.stepCtn}>
                                    <SubmitCreateWorkout 
                                        username={props.currentUser.name}
                                        fetchUserWorkouts={props.fetchUserWorkouts}
                                        workout={workout}
                                        image={image}
                                        publicWorkout={publicWorkout}
                                        paid={paid}
                                        categories={categories}
                                        name={name}
                                        description={description}
                                        navigation={props.navigation}
                                    />
                                </View>
                        }[step]
                    ):(
                        <ActivityIndicator size="large" color="#008080" />
                )}
            </View>

            <View>
                { errorMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 10}}>{errorMessage}</Subheading>}
                { paidMessage && <Subheading style={{marginTop: 10, color: 'red', marginBottom: 10}}>{paidMessage}</Subheading>}
                { paidMessage && <Button mode="contained" onPress={() => handleEditProfile()}>Edit Profile</Button>}
            </View>

            <View style={styles.buttonsCtn}>
                <Button
                    mode="outlined"
                    onPress={() => decreaseStep()} 
                    disabled={step === 0} 
                >
                    Back
                </Button>
                <Button
                    mode="contained"
                    onPress={() => increaseStep()} 
                    disabled={step === 7} 
                >
                    Next
                </Button>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'space-around', 
        alignItems: 'center',
    },
    contents: {
        maxWidth: 600,
        width: "100%",
        padding: 10,
        maxHeight: "100%",
        height: "100%",
    },
    stepCtn: {
        minHeight: Dimensions.get('window').height * 0.65,
        width: Dimensions.get('window').width * 1,
        maxWidth: 600,
        justifyContent: "center",
        padding: 20
    },
    workoutStepCtn: {
        minHeight: Dimensions.get('window').height * 0.75,
        maxHeight: Dimensions.get('window').height * 0.75,
        width: Dimensions.get('window').width * 1,
        maxWidth: 600,
        flex: 1
    },
    buttonsCtn: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-around",
        width: "100%"
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
});


const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUserWorkouts }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(CreateWorkout);
