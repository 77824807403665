import { Picker } from '@react-native-picker/picker';
import * as ImagePicker from 'expo-image-picker';
import { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Chip, Subheading, Text, TextInput, Title } from 'react-native-paper';
import NumberSelect from '../CustomComponents/NumberSelect/NumberSelect';
import Media from '../media/index';

export default function CreateEditExercise({ workout, setWorkout, weekIndex, dayIndex, exIndex, display, edit, exercise, deleteExercise, close }) {

    const [step, setStep] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedWeek, setSelectedWeek] = useState(0)
    const [selectDeleteWeek, setSelectDeleteWeek] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);

    const [exName, setExName] = useState('');
    const [exReps, setExReps] = useState('1');
    const [exRepsRest, setExRepsRest] = useState('10');
    const [exSets, setExSets] = useState('1');
    const [exTime, setExTime] = useState('');
    const [exDistance, setExDistance] = useState('3');
    const [exWeight, setExWeight] = useState('5');
    const [exInstructions, setExInstructions] = useState('');
    const [exImage, setExImage] = useState('');
    const [restTime, setRestTime] = useState();
    const [addRestIndex, setAddRestIndex] = useState();

    const [showReps, setShowReps] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showWeight, setShowWeight] = useState(false);
    const [showDistance, setShowDistance] = useState(false);

    const [repsEdited, setRepsEdited] = useState(false);
    const [timeEdited, setTimeEdited] = useState(false);
    const [weightEdited, setWeightEdited] = useState(false);
    const [distanceEdited, setDistanceEdited] = useState(false);

    const [selectedWeight, setSelectedWeight] = useState('kg');
    const [selectedDistance, setSelectedDistance] = useState('km');

    useEffect(() => {
        if (edit) {
            setIsEdit(true)
            populateExerciseFromEdit(exercise)
        } else {
            // in case of previous edits
            resetExerciseState()
        }
    }, [])

    const populateExerciseFromEdit = (parentExercise) => {
        setExName(exercise.name)
        setExInstructions(exercise.instructions)
        // if (exercise.image) {
        //     setExImage(exercise.image)
        // }
        if (exercise.reps) {
            setExReps(exercise.reps)
            setExRepsRest(exercise.repsRest)
            setExSets(exercise.sets)
            setShowReps(true)
            setRepsEdited(true)
        }
        if (exercise.time) {
            setExTime(exercise.time)
            setShowTime(true)
            setTimeEdited(true)
        }
        if (exercise.distance) {
            setExDistance(exercise.distance)
            setSelectedDistance(exercise.distanceUOM)
            setShowDistance(true)
            setDistanceEdited(true)
        }
        if (exercise.weight) {
            setExWeight(exercise.weight)
            setSelectedWeight(exercise.weightUOM)
            setShowWeight(true)
            setWeightEdited(true)
        }
    }

    const validateExercise = () => {
        if (exName.length <= 2) {
            setErrorMessage("Please add name")
            return false
        } else {
            return true
        }
    }

    const createNewExercise = async () => {
        try {
            setErrorMessage(null)
            const validate = await validateExercise()
            if (validate) {
                const newExercise = {      
                    "image": exImage,
                    "completed": false,
                    "instructions": exInstructions,
                    "name": exName
                }
                if (repsEdited) {
                    newExercise.reps = exReps
                    newExercise.repsRest = exRepsRest
                    newExercise.sets = exSets
                }
                if (timeEdited) newExercise.time = exTime
                if (distanceEdited) {
                    newExercise.distance = exDistance
                    newExercise.distanceUOM = selectedDistance
                }
                if (weightEdited) {
                    newExercise.weight = exWeight
                    newExercise.weightUOM = selectedWeight
                }

                let newWorkout = _.cloneDeep(workout);
        
                if (newWorkout.weeks[weekIndex].days[dayIndex].exercises) {
                    const oldDayExercises = newWorkout.weeks[weekIndex].days[dayIndex].exercises
                    oldDayExercises.push(newExercise)
                    newWorkout.weeks[weekIndex].days[dayIndex].exercises = oldDayExercises
                    setWorkout(prevState => ({
                        ...prevState, ...newWorkout
                    }))
                }
                resetExerciseState()
                display()
            } 
        } catch (error) {
            setErrorMessage("Error saving exercise")
            console.error(error)
        }
    }

    const editExercise = async () => {
        try {
            setErrorMessage(null)
            const validate = await validateExercise()
            console.log("Validate ", validate)
            if (validate) {
                const newExercise = {      
                    "image": exImage,
                    "completed": false,
                    "instructions": exInstructions,
                    "name": exName
                }
                if (repsEdited) {
                    newExercise.reps = exReps
                    newExercise.repsRest = exRepsRest
                    newExercise.sets = exSets
                }
                if (timeEdited) newExercise.time = exTime
                if (distanceEdited) {
                    newExercise.distance = exDistance
                    newExercise.distanceUOM = selectedDistance
                }
                if (weightEdited) {
                    newExercise.weight = exWeight
                    newExercise.weightUOM = selectedWeight
                }

                let newWorkout = _.cloneDeep(workout);

                newWorkout.weeks[weekIndex].days[dayIndex].exercises[exIndex] = newExercise
                setWorkout(prevState => ({
                    ...prevState, ...newWorkout
                }))

                display()
            } 
        } catch (error) {
            console.error(error)
            setErrorMessage("Error saving edit")
        }

    }
    
    const resetExerciseState = () => {
        // Todo:
        // Will need to find a way to reset
        // Without adding to new exercise
        setExName('')
        setExReps('1')
        setExRepsRest('10')
        setExSets('1')
        setExTime('')
        setExDistance('3')
        setExWeight('5')
        setExInstructions('')
        setExImage('')
        setShowReps(false)
        setShowTime(false)
        setShowWeight(false)
        setShowDistance(false)
    }

    const increaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) + 1
                // Need React Native paper TextInput value only accepts type string :/
                const setsString = newSets.toString()
                setExSets(setsString);
                break;
            case 'reps':
                const newReps = parseInt(exReps) + 1
                const repsString = newReps.toString()
                setExReps(repsString);
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) + 1
                const restString = newRest.toString()
                setExRepsRest(restString);
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) + 1
                const weightString = newWeight.toString()
                setExWeight(weightString);
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) + 1
                const distanceString = newDistance.toString()
                setExDistance(distanceString);
                break;
            default:
                break;
        }
    }

    const decreaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) - 1
                // Need React Native paper TextInput value only accepts type string :/
                if (newSets > 0) {
                    const setsString = newSets.toString()
                    setExSets(setsString);
                }
                break;
            case 'reps':
                const newReps = parseInt(exReps) - 1
                if (newReps > 0) {
                    const repsString = newReps.toString()
                    setExReps(repsString);
                }
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) - 1
                if (newRest > 0) {
                    const restString = newRest.toString()
                    setExRepsRest(restString);
                }
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) - 1
                if (newWeight > 0) {
                    const weightString = newWeight.toString()
                    setExWeight(weightString);
                }
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) - 1
                if (newDistance > 0) {
                    const distanceString = newDistance.toString()
                    setExDistance(distanceString);
                }
                break;
            default:
                break;
        }
    }

    const handleNumberInput = (e, inputType) => {
        switch (inputType) {
            case 'sets':
                // Check only a number has been entered
                if(!isNaN(e)){
                    setExSets(e);
                }
                break;
            case 'reps':
                if(!isNaN(e)){
                    setExReps(e);
                }
                break;
            case 'exRest':
                if(!isNaN(e)){
                    setExRepsRest(e);
                }
                break;
            case 'exWeight':
                if(!isNaN(e)){
                    setExWeight(e);
                }
                break;
            case 'exDistance':
                if(!isNaN(e)){
                    setExDistance(e);
                }
                break;
            default:
                break;
        }
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    const handleAddExerciseOptionals = (numberToEdit) => {
        switch (numberToEdit) {
            case 'reps':
                if (showReps) {
                    setExSets('1')
                    setExReps('1')
                    setExRepsRest('10')
                    setShowReps(!showReps)
                    setRepsEdited(!repsEdited)
                } else {
                    setShowReps(!showReps)
                    setRepsEdited(!repsEdited)
                }
                break;
            case 'weight':
                if (showWeight) {
                    setExWeight('5')
                    setShowWeight(!showWeight)
                    setWeightEdited(!weightEdited)
                } else {
                    setShowWeight(!showWeight)
                    setWeightEdited(!weightEdited)
                }
                break;
            case 'distance':
                if (showDistance) {
                    setExDistance('3')
                    setShowDistance(!showDistance)
                    setDistanceEdited(!distanceEdited)
                } else {
                    setShowDistance(!showDistance)
                    setDistanceEdited(!distanceEdited)
                }
                break;
            case 'time':
                if (showTime) {
                    setExTime('')
                    setShowTime(!showTime)
                    setTimeEdited(!timeEdited)
                } else {
                    setShowTime(!showTime)
                    setTimeEdited(!timeEdited)
                }
                break;
            default:
                break;
        }
    }

    const pickImageWorkout = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            // mediaTypes: ImagePicker.MediaTypeOptions.All,
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.8,
        });
        if (!result.cancelled) {
            let uri = result.uri;
            let fileExtension = uri.substring(uri.indexOf('/') + 1, uri.indexOf(';base64'));
            let mediaType = determineFileType(fileExtension)
            const img = {
                url: uri,
                metadata: {"type": mediaType}
            }
            setExImage(img);
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.contents}>
                {
                    edit ? (
                        <View style={styles.editTitleCtn}>
                            <Subheading>Edit Exercise</Subheading>
                            <View style={styles.editBtnsCtn}>
                                <Button 
                                    labelStyle={{ fontSize: 10 }} 
                                    color="red" 
                                    icon="delete" 
                                    mode="contained" 
                                    onPress={() => deleteExercise(dayIndex, exIndex)}
                                >
                                    Delete
                                </Button>
                                <Button 
                                    style={{ marginLeft: 5}} 
                                    labelStyle={{ fontSize: 10 }} 
                                    icon="window-close" 
                                    mode="outlined" 
                                    onPress={() => close()}
                                >
                                    Close
                                </Button>
                            </View>
                        </View>
                    ):(
                        <View style={styles.editTitleCtn}>
                            <Subheading style={{marginBottom: 5}}>Add Exercise</Subheading>
                            <Button 
                                style={{ marginLeft: 5}} 
                                labelStyle={{ fontSize: 10 }} 
                                icon="window-close" 
                                mode="outlined" 
                                onPress={() => close()}
                            >
                                Close
                            </Button>
                        </View>
                    )
                }
                <View style={styles.contentView}>
                    <TextInput
                        label="Exercise name"
                        value={exName}
                        onChangeText={text => setExName(text)}
                    />
                    <Text style={styles.explainerText}>Name of exercise e.g. Incline Bench Press</Text>
                </View>

                <View style={styles.contentView}>
                    <TextInput
                        label="Instructions"
                        value={exInstructions}
                        onChangeText={text => setExInstructions(text)}
                    />
                    <Text style={styles.explainerText}>Optionally enter description of exercise</Text>
                </View>

                <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <Chip selected={showReps} style={{margin: 3}} icon="plus" onPress={() => handleAddExerciseOptionals('reps')}>Add Reps</Chip>
                    <Chip selected={showWeight} style={{margin: 3}} icon="plus" onPress={() => handleAddExerciseOptionals('weight')}>Add Weight</Chip>
                    <Chip selected={showDistance} style={{margin: 3}} icon="plus" onPress={() => handleAddExerciseOptionals('distance')}>Add Distance</Chip>
                    <Chip selected={showTime} style={{margin: 3}} icon="plus" onPress={() => handleAddExerciseOptionals('time')}>Add Time</Chip>
                </View>

                <View style={styles.contentView}>
                    {showReps && 
                        <View style={{marginTop: 20, marginBottom: 20}}>
                            <Text style={styles.explainerText}>Enter Sets & Reps e.g. 3 x 12</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 10, marginBottom: 20}}>
                                <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                    <NumberSelect increase={() => increaseNumberSelect('sets')} decrease={() => decreaseNumberSelect('sets')} count={exSets} handleName='sets' handleWeeks={handleNumberInput} />
                                    <Text>Sets</Text>
                                </View>
                                <View>
                                    <Text style={{fontSize: 24}}> X </Text>
                                </View>
                                <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                    <NumberSelect increase={() => increaseNumberSelect('reps')} decrease={() => decreaseNumberSelect('reps')} count={exReps} handleName='reps' handleWeeks={handleNumberInput} />
                                    <Text>Reps</Text>
                                </View>
                            </View>

                            <Text style={styles.explainerText}>Enter rest between sets in seconds if any</Text>
                            <View style={{marginTop: 10, marginBottom: 20, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center',}}>
                                <Title>Rest: </Title>
                                <NumberSelect increase={() => increaseNumberSelect('exRest')} decrease={() => decreaseNumberSelect('exRest')} count={exRepsRest} handleName='exRest' handleWeeks={handleNumberInput} />
                                <Subheading>seconds</Subheading>
                            </View>
                        </View>
                    }

                    {showWeight && 
                        <View style={{marginTop: 10, marginBottom: 10}}>
                            <Text style={styles.explainerText}>Enter weight for exercise</Text>
                            <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center', marginTop: 10}}>
                                <NumberSelect increase={() => increaseNumberSelect('exWeight')} decrease={() => decreaseNumberSelect('exWeight')} count={exWeight} handleName='exWeight' handleWeeks={handleNumberInput} />
                                <Picker
                                    style={{width: 100}}
                                    selectedValue={selectedWeight}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setSelectedWeight(itemValue)
                                    }>
                                    <Picker.Item label="kg" value="kg" />
                                    <Picker.Item label="lbs" value="lbs" />
                                </Picker>
                            </View>
                        </View>
                    }

                    {showDistance && 
                        <View style={{marginTop: 10, marginBottom: 10}}>
                            <Text style={styles.explainerText}>Enter distance for exercise</Text>
                            <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center', marginTop: 10}}>
                                <NumberSelect increase={() => increaseNumberSelect('exDistance')} decrease={() => decreaseNumberSelect('exDistance')} count={exDistance} handleName='exDistance' handleWeeks={handleNumberInput} />
                                <Picker
                                    style={{width: 100}}
                                    selectedValue={selectedDistance}
                                    onValueChange={(itemValue, itemIndex) =>
                                        setSelectedDistance(itemValue)
                                    }>
                                    <Picker.Item label="km" value="km" />
                                    <Picker.Item label="mi" value="mi" />
                                </Picker>
                            </View>
                        </View>
                    }

                    {showTime && 
                        <View style={{marginTop: 10, marginBottom: 10}}>
                            <TextInput
                                style={{width: "100%"}}
                                label="Time"
                                placeholder="20 - 30 mins"
                                value={exTime}
                                onChangeText={text => setExTime(text)}
                            />
                            <Text style={styles.explainerText}>Enter time for exercise e.g. 10mins</Text>
                        </View>
                    }
                </View>

                <View style={styles.contentView}>
                    <Text style={styles.explainerText}>Optionally select photo for this exercise *</Text>
                    <Button 
                        onPress={pickImageWorkout}
                        mode="outlined"
                        icon="file-image"
                        style={{marginTop: 10, marginBottom: 10}}
                    >
                        Pick an image from camera roll
                    </Button>
                    {exImage ? <Media contentType={exImage.metadata.type} url={exImage.url} component='cover' />  : null}
                </View>

                { errorMessage && <Text style={{marginTop: 10, marginBottom: 10, color: 'red'}}>{errorMessage}</Text>}
                {edit ? (
                        <Button mode="contained" color="#008080" style={{flex: 1, marginBottom: 20, marginTop: 10}} onPress={() => editExercise()}>
                            Save edit
                        </Button>
                    ):(
                        <Button mode="contained" color="#008080" style={{flex: 1, marginBottom: 20, marginTop: 10}} onPress={() => createNewExercise()}>
                            Save exercise
                        </Button>
                )}
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 15,
        borderWidth:  1,
        borderColor: 'black'
    },
    contents: {
        padding: 20
    },
    contentView: {
        marginBottom: 30
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
    editTitleCtn: {
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    editBtnsCtn: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});