import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import Media from '../media/index';

export default function WorkoutCard({workout}) {
    return (
        <View style={styles.container}>
            <View style={styles.image}>
                { workout.image && workout.image.url.length > 0 ? (
                    <View>
                        <Media contentType={workout.image.metadata.type} url={workout.image.url} component='exercise' />  
                    </View>
                ):(
                    <View style={{padding: 10}}>
                        <Image
                            style={{ width: 60, height: 60 }}
                            source={require('../../assets/dumbell.png')}
                        /> 
                    </View>
                )}
            </View>
            <View style={styles.name}>
                <Title>{workout.name}</Title>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "2%"
    },
    name: {
        flex: 2
    },
    image: {
        flex: 1
    },
})
