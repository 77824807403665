import { useNavigation } from '@react-navigation/native';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { Text, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUser } from '../../redux/actions/index';

function CreateStripeAccountSuccess(props) {
    const navigation = useNavigation();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        firebase.firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .update({
                paid: true
            })
            .then((result) => {
                setLoading(false)
                props.fetchUser()
                navigation.navigate("Main")
            })
            .catch((error) => {
                setMessage("Error updating please try again")
                setLoading(false)
            })
    }, []);

    return (
        <View>
            <Title>Saving your account</Title>
            <ActivityIndicator size="large" color="#008080"  />
            { message && <Text style={{marginTop: 10, color: 'red'}}>{message}</Text>}
        </View>
    )
}

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})

const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUser }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(CreateStripeAccountSuccess);



