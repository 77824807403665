import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import React, { Component } from 'react';
import { StyleSheet, View, Dimensions, Image, Platform } from 'react-native';
import { Button, Subheading, Text, TextInput, IconButton, Title } from 'react-native-paper';
import EditProfilePicture from '../media/ProfilePicture/EditProfilePicture';
import AppStoreBadges from '../appstoreBadge';

export class Register extends Component {
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            paid: false,
            name: '',
            loading: false,
            message: null,
            profileImage: null,
            step: 0,
            welcomeStep: 0,
            buttonStep: 0
        }
        this.onSignUp = this.onSignUp.bind(this)
    }


    validateEmailAddress(elementValue){      
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue); 
    }

    setProfileImage = (image) => {
        this.setState({
            profileImage: image
        })
    }

    validateRegister() {
        if (!this.state.name.length > 0) {
            this.setState({
                loading: false,
                message: "Error on signup. Cannot have empty username"
            })
            return false
        } else if (this.state.password.length < 5) {
            this.setState({
                loading: false,
                message: "Password must be at least length of 6"
            })
            return false
        } else if (!this.validateEmailAddress(this.state.email)) {
            this.setState({
                loading: false,
                message: "Email address not valid"
            })
            return false
            
        } else {
            return true
            
        }
    }

    queryUserNames() {

        firebase.firestore()
        .collection("users")
        .where("name", "==", this.state.name)
        .get()
        .then((snapshot) => {
            const usernameResults = snapshot.docs
            if (usernameResults.length > 0) {
                this.setState({
                    loading: false,
                    message: "Sorry username taken! Please choose another"
                })
                return false
            } else {
                return true
            }
        })

    }

    async validateUserName(){
        const usersData = await firebase.firestore()
        .collection("users")
        .where("name", "==", this.state.name)
        .get()
        .then((snapshot) => {
            const usernameResults = snapshot.docs
            if (usernameResults.length > 0) {
                this.setState({
                    loading: false,
                    message: "Sorry username taken! Please choose another"
                })
                return false
            } else {
                return true
            }
        })
        return usersData
    }

    async validateEmail(){
        const usersData = await firebase.firestore()
        .collection("users")
        .where("email", "==", this.state.email)
        .get()
        .then((snapshot) => {
            const usernameResults = snapshot.docs
            if (usernameResults.length > 0) {
                this.setState({
                    loading: false,
                    message: "Sorry email taken! Please choose another"
                })
                return false
            } else {
                return true
            }
        })
        return usersData
    }

    prepareImageUpload = async () => {
        // Prepares image for upload
        const uri = this.state.profileImage
        const childPath = `profilePicture/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath, metadata: this.state.profileImage.metadata}
        return coverMedia
    }

    uploadCoverImage = async(imgData) => {
        const task = firebase
            .storage()
            .ref()
            .child(imgData.childPath)
            .put(imgData.blob)

        task.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            console.error(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return task
    }

    prepareProfileImage = async (body) => {
        if (this.state.profileImage) {
            const createImg = await this.prepareImageUpload()
            const coverImg = await this.uploadCoverImage(createImg)
            const imgUrl = await coverImg.ref.getDownloadURL()
            const coverMedia = {"url": imgUrl, metadata: createImg.metadata}
            if (coverMedia) {
                body.profileImage = coverMedia.url
                return true
            }
        } else {
            return true
        }
    }


    async onSignUp(){

        // 1. Validate username & email
        // 2. Check to see if username is free
        // 3. Create user
        // 4. Redirect to homepage

        this.setState({
            loading: true,
            message: null
        })

        const valid = await this.validateRegister()
        const validUsername = await this.validateUserName()
        const validEmail = await this.validateEmail()

        if (valid && validUsername && validEmail) {
            const { email, password, name, paid } = this.state;
            firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((result) => {
                this.createUser()
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    message: `Error signing up. ${error.message}`
                })
            })
        }

    }

    createUser = async () => {
        // Need to wait until the user is created
        // To make call to storage
        // Yuck.
        const { email, password, name, paid } = this.state;
        let body = {
            name: name,
            paid: paid,
            email: email,
            active: true,
            featureFlags: {}
        }
        const preparedImage = await this.prepareProfileImage(body)
        if (preparedImage) {
            firebase.firestore().collection("users")
                .doc(firebase.auth().currentUser.uid)
                .set(body)
                .then((res) => {
                    // this.props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
                    this.setState({
                        loading: false
                    })
                    this.setState(prevState => {
                        return {step: prevState.step + 1}
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        message: `Error signing up. ${error.message}`
                    })
                })
        }
    }

    navigateUserProfile = () => {
        this.setState({
            loading: true,
        })
        // this.props.navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
        // This should be correct but wasn't working when already come from Home route
        this.props.navigation.navigate('Home', {
            screen: 'Main',
            params: {
                screen: 'Profile',
                params: {
                uid: firebase.auth().currentUser.uid,
                },
            },
        })
    }

    handleNext = () => {
        // Gross.
        if (Platform.OS === 'web') {
            this.setState(prevState => {
                return {welcomeStep: prevState.welcomeStep + 1}
            })
            this.setState(prevState => {
                return {buttonStep: prevState.buttonStep + 1}
            })
        } else {
            if (buttonStep === 3) {
                this.setState(prevState => {
                    return {welcomeStep: prevState.welcomeStep + 1}
                })
                this.setState(prevState => {
                    return {buttonStep: prevState.buttonStep + 2}
                })
            } else {
                this.setState(prevState => {
                    return {welcomeStep: prevState.welcomeStep + 1}
                })
                this.setState(prevState => {
                    return {buttonStep: prevState.buttonStep + 1}
                })
            }
        }
    }

    render(){
        return (
            <View style={styles.container}>
                <View style={[Dimensions.get('window').width > 900 ? styles.contentDesktop : styles.content]}>

                {this.state.step !== null ? (
                    {
                        0:
                        <View>
                            <View>
                                <EditProfilePicture image={this.state.profileImage} updateImage={this.setProfileImage} />
                            </View>
                            <TextInput 
                                placeholder="Username"
                                onChangeText={(name) => this.setState({ name })}
                                style={{marginBottom: 10}}
                                left={<TextInput.Icon name="account-circle-outline" />}
                            />
                            <TextInput 
                                placeholder="Email"
                                onChangeText={(email) => this.setState({ email })}
                                style={{marginBottom: 10}}
                                left={<TextInput.Icon name="email-outline" />}

                            />
                            <TextInput 
                                placeholder="Password"
                                secureTextEntry={true}
                                onChangeText={(password) => this.setState({ password })}
                                style={{marginBottom: 10}}
                                left={<TextInput.Icon name="lock-outline" />}
                            />

                            <Button 
                                onPress={() => this.onSignUp()}
                                mode="contained"
                                disabled={this.state.loading}
                                loading={this.state.loading}
                            >
                                Sign Up
                            </Button>

                            { this.state.message && <Text style={{marginTop: 10, marginBottom: 15, color: 'red'}}>{this.state.message}</Text>}

                            <View style={styles.subhead}>
                                <Subheading>Already have an account? </Subheading>
                                <Subheading 
                                    onPress={() => this.props.navigation.navigate("Login")}
                                    style={{textDecoration: 'underline'}} 
                                >
                                    Login
                                </Subheading>
                            </View>
                        </View>
                        ,
                        1:
                        <View style={styles.welcomeCtn}>
                            <View style={styles.welcomeHeader}>
                                <IconButton
                                    icon="close"
                                    size={20}
                                    onPress={() => this.navigateUserProfile()}
                                />
                            </View>
                            {this.state.welcomeStep !== null ? (
                                {
                                    0:
                                        <View style={styles.welcomeBody}>
                                            <Image
                                                style={{ width: 150, height: 150, margin: 10 }}
                                                source={require('../../assets/moveIcons/koala_victory_transparent_1.gif')}
                                            />
                                            <Title style={{margin: 10}}>Welcome!</Title> 
                                            <Subheading style={{margin: 10, textAlign: 'center'}}>Find out more about some of the features on Move</Subheading>
                                        </View>
                                    ,
                                    1:
                                        <View style={styles.welcomeBody}>
                                            <Image
                                                style={{ width: 150, height: 150 }}
                                                source={require('../../assets/landingIcons/checklist.gif')}
                                            />
                                            <Title style={{margin: 10}}>Create</Title> 
                                            <Subheading style={{margin: 10, textAlign: 'center'}}>Create your own workouts, challenges or on demand videos</Subheading>
                                        </View>
                                    ,
                                    2:
                                        <View style={styles.welcomeBody}>
                                            <Image
                                                style={{ width: 150, height: 150 }}
                                                source={require('../../assets/landingIcons/like.gif')}
                                            />
                                            <Title style={{margin: 10}}>Follow</Title> 
                                            <Subheading style={{margin: 10, textAlign: 'center'}}>Or join content by your friends or favourite content creator</Subheading>
                                        </View>
                                    ,
                                    3:
                                        <View style={styles.welcomeBody}>
                                            <Image
                                                style={{ width: 150, height: 150 }}
                                                source={require('../../assets/landingIcons/video.gif')}
                                            />
                                            <Title style={{margin: 10}}>Play</Title> 
                                            <Subheading style={{margin: 10, textAlign: 'center'}}>Play through workouts, challenges or on demand videos</Subheading>
                                        </View>
                                    ,
                                    4:
                                        <View style={styles.welcomeBody}>
                                            <Image
                                                style={{ width: 150, height: 150 }}
                                                source={require('../../assets/landingIcons/social-media.gif')}
                                            />
                                            <Title style={{margin: 10}}>Connect</Title> 
                                            <Subheading style={{margin: 10, textAlign: 'center'}}>Join a workout or challenge and be part of a community working together</Subheading>
                                        </View>
                                    ,
                                    5:
                                        <View style={styles.welcomeBodyAppStore}>
                                            <AppStoreBadges />
                                            <Title>Get the app</Title> 
                                        </View>

                                }[this.state.welcomeStep]
                            ):(null)}
                            <View>
                                {this.state.welcomeStep !== 5 ? (
                                    <Button 
                                        onPress={ () => this.handleNext()}
                                        mode="contained"
                                    >
                                        Next
                                    </Button>
                                ):(
                                    <Button 
                                        onPress={() => this.navigateUserProfile()}
                                        mode="contained"
                                        disabled={this.state.loading}
                                        loading={this.state.loading}
                                    >
                                        Let's start
                                    </Button>
                                )}
                            </View>
                        </View>
                    }[this.state.step]
                    ):(null)}
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1, 
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '5%',
    },
    content: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    contentDesktop: {
        padding: "5%",
        maxWidth: Dimensions.get('window').width * 0.5,
        width: "100%",
        padding: 20
    },
    subhead:{
        flexDirection: 'row',
        marginTop: 30
    },
    welcomeCtn: {
        height: Dimensions.get('window').width * 0.7,
        flexDirection: 'column',
    },
    welcomeHeader: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    welcomeBody: {
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 300
    },
    welcomeBodyAppStore: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        minHeight: 300
    }
})

export default Register
