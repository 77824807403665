import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { Component } from 'react';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearData, fetchUser, fetchUserChallenges, fetchUserChallengesJoined, fetchUserSubscriptions, fetchUserWorkouts, fetchUserWorkoutsFollowing } from '../redux/actions/index';
import FindScreen from './find/find';
import AddScreen from './main/Add';
import FeedScreen from './main/Feed';
import ProfileScreen from './profile/Profile'

import Dummy from '../navigation/DummyNav'

const Tab = createMaterialBottomTabNavigator();

export class Main extends Component {

    constructor(props) {
        super()
        this.state = {
            loaded: false,
            uid: null
        }
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if(user){
                // Below not working, needs to set loaded to true only once we've fetched the user data
                this.setState({
                    uid: user.uid
                })
            }
        })
    }
    render() {
        return (
            <Tab.Navigator initialRouteName="Feed" labeled={false}>
                <Tab.Screen name="Feed" component={FeedScreen}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                            <MaterialCommunityIcons name="home" color={color} size={26} />
                        ),
                    }}
                    listeners={({ navigation }) => ({
                        tabPress: event => {
                            event.preventDefault();
                            navigation.navigate('Home', {
                                screen: 'Main',
                                params: {
                                    screen: 'Feed',
                                },
                            })
                        }
                    })} 
                
                />
                <Tab.Screen name="Search" component={FindScreen}
                    listeners={({ navigation }) => ({
                        tabPress: event => {
                            event.preventDefault();
                            navigation.navigate("Search")
                        }
                    })}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                            <MaterialCommunityIcons name="magnify" color={color} size={26} />
                        ),
                    }} />
                <Tab.Screen name="Add" component={AddScreen}
                    listeners={({ navigation }) => ({
                        tabPress: event => {
                            event.preventDefault();
                            navigation.navigate("Add")
                        }
                    })}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                            <MaterialCommunityIcons name="plus-box" color={color} size={26} />
                        ),
                    }} />
                <Tab.Screen name="Profile" component={ProfileScreen}
                    listeners={({ navigation }) => ({
                        tabPress: event => {
                            event.preventDefault();
                            navigation.navigate("Profile", {uid: this.state.uid})
                        }
                    })}
                    options={{
                        tabBarIcon: ({ color, size }) => (
                            <MaterialCommunityIcons name="account-circle" color={color} size={26} />
                        ),
                    }} />
            </Tab.Navigator>
        )
    }
}


const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUser, fetchUserWorkouts, fetchUserWorkoutsFollowing, fetchUserChallenges, fetchUserSubscriptions, fetchUserChallengesJoined, clearData }, dispatch);


export default connect(mapStateToProps, mapDispatchProps)(Main);

