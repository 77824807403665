import { createDrawerNavigator } from "@react-navigation/drawer";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Text, View, Platform } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Contact from '../components/contact/contact';
import FaqScreen from '../components/faq/faq';
import HowItWorks from "../components/howitworks/HowItWorks";
import SellFitnessContentOnline from "../components/landing/SellFitnessContentOnline";
import ConvertWorkoutNotesToApp from "../components/MarketingEngineering/ConvertWorkoutNotesToApp";
import FreeWorkoutTimer from "../components/MarketingEngineering/FreeWorkoutTimer/FreeWorkoutTimer";
import DummyScreen from '../navigation/DummyNav';
import PricingScreen from '../components/Pricing/Pricing'
import { ContentStackNavigator, LegalStackNavigator, MainStackNavigator, DiscoverCreatorStackNavigator, BlogStackNavigator } from '../navigation/StackNavigation';
import { clearData, fetchUser, fetchUserChallenges, fetchUserChallengesJoined, fetchUserFollowing, fetchUserOnDemandWorkouts, fetchUserOnDemandWorkoutSaved, fetchUserSubscriptions, fetchUserWorkouts, fetchUserWorkoutsFollowing } from '../redux/actions/index';

const Drawer = createDrawerNavigator();

const isWeb = Platform.OS === 'web';


const DrawerNavigator = (props) => {

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        props.clearData();

        firebase.auth().onAuthStateChanged((user) => {
            if(user){
                props.fetchUser()
                props.fetchUserWorkouts();
                props.fetchUserWorkoutsFollowing();
                props.fetchUserChallenges();
                props.fetchUserSubscriptions();
                props.fetchUserChallengesJoined();
                props.fetchUserFollowing()
                props.fetchUserOnDemandWorkouts();
                props.fetchUserOnDemandWorkoutSaved();
                // Below not working, needs to set loaded to true only once we've fetched the user data
                setLoaded(true)
            } else {
                setLoaded(true)

            }
        })


    }, [])

    if (!loaded) {
        return(
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator size="large" color="#008080" />
            </View>
        )
    }


    return (
    <Drawer.Navigator initialRouteName="Home">
        {/* Needed to fix bug */}
        <Drawer.Screen 
            name="Return" 
            component={DummyScreen} 
            navigation={props.navigation} 
            options={{
                drawerLabel: () => null,
                title: null,
                drawerIcon: () => null,
                // headerShown: false
            }}
        />
        <Drawer.Screen 
            name="Home"  
            component={MainStackNavigator} 
            navigation={props.navigation}          
            options={{
                drawerLabel: () => <Text>Home</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            // options={{ tabBarLabel: 'Home' }}
            onPress={() => {
                // Navigate using the `navigation` prop that you received
                props.navigation.navigate('Main');
            }}
        />
        <Drawer.Screen 
            name="SellFitnessContentOnline" 
            component={SellFitnessContentOnline}
            options={{
                drawerLabel: () => <Text>Sell On Move</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("sell-fitness-workouts-online")
            }}
            navigation={props.navigation} 
        />
        {isWeb && (
            <Drawer.Screen 
                name="Pricing" 
                component={PricingScreen}
                navigation={props.navigation}
                options={{
                    drawerLabel: () => <Text>Pricing</Text>,
                    title: '',                
                    drawerIcon: () => null,
                }} 
                onPress={() => {
                    props.navigation.navigate("Pricing")
                }}
            />
        )}
        <Drawer.Screen 
            name="faq" 
            component={FaqScreen}
            navigation={props.navigation}
            options={{
                drawerLabel: () => <Text>FAQs</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("faq")
            }}
        />
        <Drawer.Screen 
            name="about" 
            component={HowItWorks}
            options={{
                drawerLabel: () => <Text>How it works</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("about")
            }}
        />
        <Drawer.Screen 
            name="legals" 
            component={LegalStackNavigator}
            options={{
                drawerLabel: () => <Text>Legal</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("legals")
            }}
        />
        <Drawer.Screen 
            name="contact-us" 
            component={Contact}
            navigation={props.navigation}
            options={{
                drawerLabel: () => <Text>Contact</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("contact-us")
            }}
        />
        <Drawer.Screen 
            name="FreeWorkoutTimer" 
            component={FreeWorkoutTimer}
            options={{
                drawerLabel: () => <Text>Free workout timer</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("best-free-workout-timer")
            }}
            navigation={props.navigation} 
        />
        <Drawer.Screen 
            name="ConvertWorkoutNotesToApp" 
            component={ConvertWorkoutNotesToApp}
            options={{
                drawerLabel: () => <Text>Convert workout to app</Text>,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("convert-workout-notes-to-playable-app")
            }}
            navigation={props.navigation} 
        />
        <Drawer.Screen 
            name="DiscoverCreators" 
            component={DiscoverCreatorStackNavigator}
            options={{
                drawerLabel: () => <Text>Discover</Text>,
                // drawerLabel: () => null,
                title: '',                
                drawerIcon: () => null,
            }} 
            onPress={() => {
                props.navigation.navigate("DiscoverCreators")
            }}
        />
        <Drawer.Screen 
            name="Blogs" 
            component={BlogStackNavigator} 
            navigation={props.navigation} 
            onPress={() => {
                props.navigation.navigate("Blogs")
            }}
            options={{
                drawerLabel: () => <Text>Blog</Text>,
                title: null,
                drawerIcon: () => null,
                // headerShown: false
            }}
        />
        <Drawer.Screen 
            name="Content" 
            component={ContentStackNavigator} 
            navigation={props.navigation} 
            onPress={() => {
                props.navigation.navigate("Challenge")
            }}
            options={{
                drawerLabel: () => null,
                title: null,
                drawerIcon: () => null,
                // headerShown: false
            }}
        />
        </Drawer.Navigator>
    );
};

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUser, fetchUserWorkouts, fetchUserWorkoutsFollowing, fetchUserChallenges, fetchUserSubscriptions, fetchUserChallengesJoined, fetchUserFollowing, fetchUserOnDemandWorkouts, fetchUserOnDemandWorkoutSaved, clearData }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(DrawerNavigator);