import React from 'react';
import { View, StyleSheet, TouchableOpacity, TextInput, ActivityIndicator } from 'react-native'
import { Text } from 'react-native-paper';

export default function NumberSelect(props) {

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.row} onPress={() => props.decrease()}>
                <Text style={styles.text}>-</Text>
            </TouchableOpacity>
            <View style={styles.row}>
                <TextInput style={styles.number} keyboardType="number-pad" value={props.count} onChangeText={e => props.handleWeeks(e, props.handleName)}/>
            </View>
            <TouchableOpacity style={styles.row} onPress={() => props.increase()}>
                <Text style={styles.text}>+</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',

    },
    row: {
        width: 50,
        height: 50,
        borderRadius: 25
    },
    text: {
        fontSize: 30,
        textAlign: 'center',
        backgroundColor: '#e7776b',
        color: 'white',
        borderRadius: 25
        
    },
    number: {
        fontSize: 30,
        textAlign: 'center',
    }
});

