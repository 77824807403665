import * as ImagePicker from 'expo-image-picker';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { Button } from 'react-native-paper';


export default function NativeFilePicker({ setMediaFile }) {
    const [platform, setPlatform] = useState(null)
    const [image, setImage] = useState(null);

    useEffect(() => {

        (async () => {
            if (Platform.OS === 'web') {
                console.log("We're in web view")
                setPlatform('web')
            } else {
                // If mobile, create web URL
                // TODO: In future needs to create IOS URI
                console.log("we in native view")
                setPlatform('native')
            }
        })();
        
    }, [])

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Videos,
          allowsEditing: true,
          aspect: [4, 3],
          quality: 1,
        });
    
        console.log(result);
    
        if (!result.cancelled) {
            console.log(result.uri)
            setMediaFile(result.uri)
        }
      };


      const prepareVideo = async (videoUri) => {
        const uri = videoUri.replace("file:", "");
        const response = await fetch(videoUri);
        const blob = await response.blob()
        if (blob) {
            setMediaFile(blob)
        }
        return blob
    }

   const uriToBlob = (uri) => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function() {
            // return the blob
            resolve(xhr.response);
          };
          
          xhr.onerror = function() {
            // something went wrong
            reject(new Error('uriToBlob failed'));
          };
          // this helps us get a blob
          xhr.responseType = 'blob';
          xhr.open('GET', uri, true);
          
          xhr.send(null);
        });
      }


    return (
      <View>
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Button title="Pick an image from camera roll" onPress={pickImage}>Pick video</Button>
        </View>
      </View>
    )
}

const styles = StyleSheet.create({

});

