import * as Analytics from 'expo-firebase-analytics';
import React, { useEffect } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Subheading, Title } from 'react-native-paper';

export default function Legals(props) {
    useEffect(() => {
        (async() => {
            await Analytics.logEvent('[Legals]: Accessed');
        })();
    }, [])
    
    return (
        <View style={styles.header}>
            <ScrollView>
                <View style={styles.welcome}>
                    <Title style={{marginBottom: 30, color: 'white', fontSize: 36}}>Legals</Title>
                    <Subheading style={{textAlign: 'center', color: 'white', marginBottom: 15}}>If you can't find an answer that you're looking for, feel free to drop us a line.</Subheading>
                    <Button 
                        mode='outlined' 
                        onPress={() => props.navigation.navigate('contact-us')}
                        style={{color: "#008080", backgroundColor: "white"}}
                    >
                        Contact Us
                    </Button>
                </View>
                <View style={styles.container}>
                    <View style={styles.content}>
                            <Button
                                mode="contained"  
                                onPress={() => props.navigation.navigate('privacy')}
                            >
                                Privacy Policy
                            </Button>
                            <Button
                                mode="contained"  
                                onPress={() => props.navigation.navigate('terms')}
                            >
                                Terms of use
                            </Button>
                            <Button
                                mode="contained"  
                                onPress={() => props.navigation.navigate('gdpr')}
                            >
                                GDPR policy
                            </Button>
                    </View>
                </View>

            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        margin: 0,
    },
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    content: {
        maxWidth: 600,
        width: "100%",
        minHeight: Dimensions.get('window').height * 0.6,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    welcome: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.4,
        backgroundColor: 'rgba(0,128,128, 1)',
        color: 'white',
    },
})