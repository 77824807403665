import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { TextInput, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { Button, Text } from 'react-native-paper';

export default function workoutComments(props) {
    const [comments, setComments] = useState([])
    const [text, setText] = useState("")
    useEffect(() => {

        getComments()
    }, [props.route.params.workoutId])

    const getComments = () => {
        firebase.firestore()
        .collection('workouts')
        .doc(props.route.params.workoutId)
        .collection('comments')
        .get()
        .then((snapshot) => {
            let comments = snapshot.docs.map(doc => {
                const data = doc.data();
                const id = doc.id;
                return {id, ...data}
            })
            setComments(comments)

        })
    }

    const onCommentSend = () => {
        firebase.firestore()
        .collection('workouts')
        .doc(props.route.params.workoutId)
        .collection('comments')
        .add({
            creator: firebase.auth().currentUser.uid,
            text
        })

    }

    return (
        <View>
            <FlatList
                numColumns={1}
                horizontal={false}
                data={comments}
                renderItem={({item}) => (
                    <View>
                        {item.user !== undefined ? (
                            <Text>{item.user.name}</Text>
                        ) : null}
                        <Text>{item.text}</Text>
                    </View>
                )}
            >

            </FlatList>

            <View>
                    <TextInput 
                        placeholder='comment...'
                        onChangeText={(text) => setText(text)}
                    />
                    <Button 
                        onPress={() => onCommentSend()}
                    >Comment</Button>
                </View>
            
        </View>
    )
}
