import { Picker } from '@react-native-picker/picker';
import * as ImagePicker from 'expo-image-picker';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { Dimensions, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Chip, IconButton, List, Modal, Portal, ProgressBar, Provider, Snackbar, Subheading, Text, TextInput, Title } from 'react-native-paper';
import NumberSelect from '../CustomComponents/NumberSelect/NumberSelect';
import ExerciseCard from '../exercises/ExerciseListCard';
import Media from '../media/index';

export default function CreateEditViewWorkout({ workout, setWorkout, editable, create, navigation, following, workoutId }) {
    const [errorMessage, setErrorMessage] = useState(null);
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [exercises, setExercises] = useState([]);
    const [dayIndex, setDayIndex] = useState(null);
    const [edit, setEdit] = useState(false);
    const [weekIndexSelected, setWeekIndexSelected] = useState(null);
    const [visible, setVisible] = useState(false);
    const [dayName, setDayName] = useState('');
    const [exName, setExName] = useState('');
    const [exReps, setExReps] = useState('1');
    const [exRepsRest, setExRepsRest] = useState('1');
    const [exSets, setExSets] = useState('1');
    const [exTime, setExTime] = useState('');
    const [exDistance, setExDistance] = useState('5');
    const [exWeight, setExWeight] = useState('5');
    const [exInstructions, setExInstructions] = useState('');
    const [exImage, setExImage] = useState('');
    const [restTime, setRestTime] = useState();
    const [addRestIndex, setAddRestIndex] = useState();
    const [editExercise, setEditExercise] = useState(false);
    const [editSelected, setEditSelected] = useState(false);

    const [showReps, setShowReps] = useState(false);
    const [showTime, setShowTime] = useState(false);
    const [showWeight, setShowWeight] = useState(false);
    const [showDistance, setShowDistance] = useState(false);
    const [exerciseModal, setExerciseModal] = useState(false);
    const [exerciseIndex, setExerciseIndex] = useState(null);

    const [selectedWeight, setSelectedWeight] = useState('kg');
    const [selectedDistance, setSelectedDistance] = useState('km');

    const containerStyle = {backgroundColor: 'white', padding: 20};

    const exerciseContainerStyle = {backgroundColor: 'white', padding: 20};

    useEffect(() => {
        if (create) {
            setEditSelected(true)
        }
    }, [])

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const editWorkout = () => {
        setEditSelected(!editSelected)
    }

    const updateWorkout = async () => {
        setSaveLoading(true)
        setErrorMessage(null)
        setSnackbarMessage(null)
        const isValidWorkout = await validateWorkout()
        if (isValidWorkout) {
            firebase.firestore()
            .collection('workouts')
            .doc(workoutId)
            .update({
                weeks: workout.weeks
            })
            .then((result) => {
                setSaveLoading(false)
                setEditSelected(!editSelected)
            })
            .catch((error) => {
                setSaveLoading(false)
                setErrorMessage("Error updating workout")
                setSnackbarVisible(true)
                setSnackbarMessage('Error updating workout')
            })
        }
    }

    const saveWorkout = () => {
        if (create) {
            setWorkout(workout)
        } else {
            updateWorkout()
        }
    }

    const validateWorkout = () => {
        if (workout.name.length <= 2) {
            setErrorMessage("Invalid workout name")
            setSnackbarVisible(true)
            setSnackbarMessage('Upload error')
            return false
        } else {
            return true
        }
    }

    const editDay = (weekIndex, day, indexDay) => {
        setDayIndex(indexDay)
        setEdit(true)
        showModal(weekIndex, indexDay)
        setExercises(day.exercises)
        setDayName(day.name)
    }

    const showModal = (indexWeek, indexDay) => {
        setWeekIndexSelected(indexWeek)
        setDayIndex(indexDay)
        setVisible(true)
    }

    const hideModal = () => { 
        setVisible(false) 
        setEdit(false)
        setDayIndex(null)
        resetExerciseState()
        setExercises([])
        setDayName('')
    }

    const showExerciseModal = (edit, indexWeek, indexDay) => {
        setWeekIndexSelected(indexWeek)
        setDayIndex(indexDay)
        setExerciseModal(true)
        if (edit) {
            setEditExercise(true)
        } else {
            setEditExercise(false)
        }
    }

    const showExerciseModalFromDay = (edit) => {
        setExerciseModal(true)
        if (edit) {
            setEditExercise(true)
        } else {
            setEditExercise(false)
        }
    }

    const hideExerciseModal = () => {
        resetExerciseState()
        setExerciseModal(false)
    }

    const resetExerciseState = () => {
        setExName('')
        setExReps('')
        setExRepsRest('1')
        setExSets('')
        setExTime('')
        setExDistance('')
        setExWeight('')
        setExInstructions('')
        setExImage('')
        setShowReps(false)
        setShowTime(false)
        setShowWeight(false)
        setShowDistance(false)
        setRestTime(null)
        setAddRestIndex(null)
    }

    const createNewExercise = async () => {
        const newExercise = {      
            "image": exImage,
            "completed": false,
            "instructions": exInstructions,
            "name": exName
        }
        if (exReps.length > 0) {
            newExercise.reps = exReps
            newExercise.repsRest = exRepsRest
        }
        if (exSets.length > 0) newExercise.sets = exSets
        if (exTime.length > 0) newExercise.time = exTime
        if (exDistance.length > 0) {
            newExercise.distance = exDistance
            newExercise.distanceUOM = selectedDistance
        }
        if (exWeight.length > 0) {
            newExercise.weight = exWeight
            newExercise.weightUOM = selectedWeight
        }
        // let newWorkout = workout
        let newWorkout = _.cloneDeep(workout);

        try {
            if (newWorkout.weeks[weekIndexSelected].days[dayIndex].exercises) {
                setExercises(ex => [...ex, newExercise])
                const oldDayExercises = newWorkout.weeks[weekIndexSelected].days[dayIndex].exercises
                oldDayExercises.push(newExercise)
                newWorkout.weeks[weekIndexSelected].days[dayIndex].exercises = oldDayExercises
                setWorkout(prevState => ({
                    ...prevState, ...newWorkout
                }))
            }
        } catch (error) {
            setExercises(ex => [...ex, newExercise])
        }

        resetExerciseState()
        hideExerciseModal()
    } 

    const editExerciseAction = (ex, iWeek, iDay, iExercise) => {
        setWeekIndexSelected(iWeek)
        setDayIndex(iDay)
        setExerciseIndex(iExercise)
        setExName(ex.name)
        setExInstructions(ex.instructions)
        setExImage(ex.image)
        if (ex.reps) {
            setShowReps(true)
            setExReps(ex.reps)
            setExSets(ex.sets)
            setExRepsRest(ex.repsRest)
        } else {
            setShowReps(false)
        }
        if (ex.time) {
            setShowTime(true)
            setExTime(ex.time)
        } else {
            setShowTime(false)
        }
        if (ex.distance) {
            setShowDistance(true)
            setExDistance(ex.distance)
            setSelectedDistance(ex.distanceUOM)
        } else {
            setShowDistance(false)
        }
        if (ex.weight) {
            setShowWeight(true)
            setExWeight(ex.weight)
            setSelectedWeight(ex.weightUOM)
        } else {
            setShowWeight(false)
        }
        showExerciseModal(true, iWeek, iDay)
    }

    const editExerciseActionFromDay = (ex) => {
        const exIndex = exercises.indexOf(ex)
        setExerciseIndex(exIndex)
        setExName(ex.name)
        setExInstructions(ex.instructions)
        setExImage(ex.image)
        if (ex.reps) {
            setShowReps(true)
            setExReps(ex.reps)
            setExSets(ex.sets)
            setExRepsRest(ex.repsRest)
        } else {
            setShowReps(false)
        }
        if (ex.time) {
            setShowTime(true)
            setExTime(ex.time)
        } else {
            setShowTime(false)
        }
        if (ex.distance) {
            setShowDistance(true)
            setExDistance(ex.distance)
            setSelectedDistance(ex.distanceUOM)
        } else {
            setShowDistance(false)
        }
        if (ex.weight) {
            setShowWeight(true)
            setExWeight(ex.weight)
            setSelectedWeight(ex.weightUOM)
        } else {
            setShowWeight(false)
        }
        showExerciseModalFromDay(true)
    }

    const saveEditExercise = () => {
        const newExercise = {      
            "image": exImage,
            "completed": false,
            "instructions": exInstructions,
            "name": exName
        }
        if (exReps.length > 0) {
            newExercise.reps = exReps
            newExercise.repsRest = exRepsRest
        }
        if (exSets.length > 0) newExercise.sets = exSets
        if (exTime.length > 0) newExercise.time = exTime
        if (exDistance.length > 0) {
            newExercise.distance = exDistance
            newExercise.distanceUOM = selectedDistance
        }
        if (exWeight.length > 0) {
            newExercise.weight = exWeight
            newExercise.weightUOM = selectedWeight
        }

        let newWorkout = workout
        newWorkout.weeks[weekIndexSelected].days[dayIndex].exercises[exerciseIndex] = newExercise
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        resetExerciseState()
        hideExerciseModal()
        setEditExercise(false)
    }

    const deleteExercise = (ex) => {
        const exIndex = exercises.indexOf(ex)
        let cloneExercise = [...exercises]
        cloneExercise.splice(exIndex, 1);
        setExercises(cloneExercise)
    }

    const saveDayEdit = () => {
        // let newWorkout = workout
        let newWorkout = _.cloneDeep(workout);
        newWorkout.weeks[weekIndexSelected].days[dayIndex].exercises = exercises
        newWorkout.weeks[weekIndexSelected].days[dayIndex].name = dayName
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        hideModal()
    }

    const createNewWeek = (i) => {
        let newWeeks = _.cloneDeep(workout);
        const nextWeekNum = workout["weeks"].length + 1
        const newWeek = {
            "week": nextWeekNum.toString(),
            "completed": false,
            "days": [
                {
                    "completed": false,
                    "exercises": []
                }
            ]
        }
        newWeeks.weeks.splice(i + 1, 0, newWeek)
        setWorkout(prevState => ({
            ...prevState, ...newWeeks
        }))
    }
    
    const duplicateWeek = (i) => {
        let newWorkout = _.cloneDeep(workout);
        let newWeeks = [...newWorkout.weeks]
        let newWeekCopy = newWeeks.slice(-1)[0]
        let newCopyAgain = _.cloneDeep(newWeekCopy);
        newWorkout.weeks.splice(i + 1, 0, newCopyAgain)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
    }

    const deleteWeek = (i) => {
        let newWorkout = _.cloneDeep(workout);
        newWorkout.weeks.splice(i, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
    }

    const deleteDay = () => {
        let newWorkout = workout
        newWorkout.weeks[weekIndexSelected].days.splice(dayIndex, 1)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        hideModal()
    }

    const createNewDay = () => {
        // let newWorkout = workout
        let newWorkout = _.cloneDeep(workout);

        const newWeek = workout.weeks[weekIndexSelected]
        const nextDayNum = newWeek.days.length + 1
        const newDay =  {
            "name": dayName,
            "completed": false,
            "exercises": exercises,

        }
        newWorkout.weeks[weekIndexSelected].days.splice(dayIndex + 1, 0, newDay)
        setWorkout(prevState => ({
            ...prevState, ...newWorkout
        }))
        hideModal()
    }

    const pickImageWorkout = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: 0.8,
        });
        if (!result.cancelled) {
            let uri = result.uri;
            let fileExtension = uri.substring(uri.indexOf('/') + 1, uri.indexOf(';base64'));
            let mediaType = determineFileType(fileExtension)
            const img = {
                url: uri,
                metadata: {"type": mediaType}
            }
            setExImage(img);
        }
    };

    const increaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) + 1
                // Need React Native paper TextInput value only accepts type string :/
                const setsString = newSets.toString()
                setExSets(setsString);
                break;
            case 'reps':
                const newReps = parseInt(exReps) + 1
                const repsString = newReps.toString()
                setExReps(repsString);
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) + 1
                const restString = newRest.toString()
                setExRepsRest(restString);
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) + 1
                const weightString = newWeight.toString()
                setExWeight(weightString);
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) + 1
                const distanceString = newDistance.toString()
                setExDistance(distanceString);
                break;
            default:
                break;
        }
    }

    const decreaseNumberSelect = (numberToEdit) => {
        switch (numberToEdit) {
            case 'sets':
                const newSets = parseInt(exSets) - 1
                // Need React Native paper TextInput value only accepts type string :/
                const setsString = newSets.toString()
                setExSets(setsString);
                break;
            case 'reps':
                const newReps = parseInt(exReps) - 1
                const repsString = newReps.toString()
                setExReps(repsString);
                break;
            case 'exRest':
                const newRest = parseInt(exRepsRest) - 1
                const restString = newRest.toString()
                setExRepsRest(restString);
                break;
            case 'exWeight':
                const newWeight = parseInt(exWeight) - 1
                const weightString = newWeight.toString()
                setExWeight(weightString);
                break;
            case 'exDistance':
                const newDistance = parseInt(exDistance) - 1
                const distanceString = newDistance.toString()
                setExDistance(distanceString);
                break;
            default:
                break;
        }
    }

    const handleNumberInput = (e, inputType) => {
        switch (inputType) {
            case 'sets':
                // Check only a number has been entered
                if(!isNaN(e)){
                    setExSets(e);
                }
                break;
            case 'reps':
                if(!isNaN(e)){
                    setExReps(e);
                }
                break;
            case 'exRest':
                if(!isNaN(e)){
                    setExRepsRest(e);
                }
                break;
            case 'exWeight':
                if(!isNaN(e)){
                    setExWeight(e);
                }
                break;
            case 'exDistance':
                if(!isNaN(e)){
                    setExDistance(e);
                }
                break;
            default:
                break;
        }
    }

    const determineFileType = (file) => {
        if (file == 'mp4' || file == 'm4v' || file == 'mov') {
            return 'video'
        } else if(file == 'svg+xml' || file == 'jpg' || file == 'jpeg' || file == 'png'|| file == 'webp'){
            return 'image'
        }
    }

    const navigateExercises = (exercises, weekIndex, dayIndex) => {
        if (!create) {
            navigation.navigate("Exercises", {
                exercises: exercises, 
                following: following,
                workout: workout,
                workoutId: workoutId,
                weekIndex: weekIndex,
                dayIndex: dayIndex

            })
        }
    }

    return (
        <View style={styles.container}>
            { 
                !workout ? (
                    <ProgressBar indeterminate={true} />
                ):(
                    <View>
                        {
                            editable ? (
                                <View style={styles.editBtns}>
                                    { !editSelected && <Button mode='outlined' onPress={() => editWorkout()}>Edit</Button>}
                                    { editSelected && <Button mode='contained' disabled={saveLoading} loading={saveLoading} onPress={() => saveWorkout()}>Save Changes</Button>}
                                </View>
                            ):(
                                null
                            )
                        }
                        <View>
                            {
                                workout.weeks.map((week, idx) => (
                                    <View>
                                        <View>
                                        <List.Section>
                                            <List.Accordion
                                                title={`Week ${idx + 1}`}
                                            >
                                                {
                                                    week.days.map((day, ix) => (
                                                        <View style={styles.dayCtn}>
                                                            <TouchableOpacity 
                                                                onPress={() => navigateExercises(day, idx, ix)}
                                                            >
                                                            <View style={styles.dayTitle}>
                                                                <Title>Day {ix + 1}: {day.name}</Title>
                                                                <View style={{flexDirection: 'row'}}>
                                                                    {
                                                                        editSelected ? (
                                                                            <View style={{flexDirection: 'row'}}>
                                                                                <IconButton
                                                                                    icon="pencil"
                                                                                    size={20}
                                                                                    onPress={() => editDay(idx, day, ix)}
                                                                                />
                                                                                <IconButton
                                                                                    icon="plus-circle"
                                                                                    size={20}
                                                                                    onPress={() => showModal(idx, ix)}
                                                                                    color="#008080"
                                                                                />
                                                                            </View>
                                                                        ) : (
                                                                            <Button onPress={() => navigateExercises(day, idx, ix)}>
                                                                                View workout
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </View>
                                                            </View>
                                                            {
                                                                editSelected ? (
                                                                    
                                                                        day.exercises.map((e, eidx) => (
                                                                            <TouchableOpacity style={styles.exerciseCtn} onPress={() => editExerciseAction(e, idx, ix, eidx)}>
                                                                                <ExerciseCard exercise={e} />
                                                                            </TouchableOpacity>
                                                                        ))
                                                                ) : (
                                                                        day.exercises.map((e, eidx) => (
                                                                            <TouchableOpacity style={styles.exerciseCtn} onPress={() => navigateExercises(day, idx, ix)}>
                                                                                <ExerciseCard exercise={e} />
                                                                            </TouchableOpacity>
                                                                        ))
                                                                )
                                                            }

                                                            {
                                                                editSelected &&
                                                                    <View>
                                                                        <Button
                                                                            mode='outlined'
                                                                            icon="plus-circle"
                                                                            onPress={() => showExerciseModal(false, idx, ix)}
                                                                            style={{marginBottom: 15}}
                                                                        >
                                                                            Add Exercise
                                                                        </Button>
                                                                        <Button
                                                                            mode='outlined'
                                                                            icon="plus-circle"
                                                                            onPress={() => showModal(idx, ix)}
                                                                            style={{marginBottom: 15}}
                                                                        >
                                                                            Add Day
                                                                        </Button>
                                                                    </View>
                                                            }
                                                            </TouchableOpacity>
                                                        </View>
                                                        )
                                                    )
                                                }
                                            </List.Accordion>
                                            {
                                                workout.weeks[0].days.length === 0 && 
                                                    <Button
                                                        mode='outlined'
                                                        icon="plus-circle"
                                                        onPress={() => showModal(0, 0)}
                                                        style={{marginBottom: 15, marginTop: 15}}
                                                    >
                                                        Add Day
                                                    </Button>
                                            }
                                            {
                                                editSelected  && 
                                                    <View>
                                                        <Button
                                                            icon="content-copy"
                                                            size={20}
                                                            onPress={() => duplicateWeek(idx)}
                                                        >
                                                            Duplicate week
                                                        </Button>
                                                        <Button
                                                            icon="plus-circle"
                                                            size={20}
                                                            onPress={() => createNewWeek(idx)}
                                                            color="#008080"
                                                        >
                                                            Add week
                                                        </Button>
                                                        <Button
                                                            icon="minus-circle-outline"
                                                            size={20}
                                                            onPress={() => deleteWeek(idx)}
                                                            color="#008080"
                                                        >
                                                            Delete week
                                                        </Button>
                                                    </View>
                                            }
                                        </List.Section>
                                        </View>
                                    </View>
                            ))}  
                        </View>
                    </View>
            )}

            <View style={styles.footer}>
            </View>

            <Provider>
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                    <ScrollView>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            { edit && <Title>Edit Day</Title>}
                            { !edit && <Title>Add Day</Title>}
                            <Button
                                icon="delete" 
                                onPress={deleteDay}
                                color="red"
                            >
                                Delete Day
                            </Button>
                        </View>
                        <Text style={styles.explainerText}>Add exercises to this day</Text>
                        <View style={styles.content}>
                            <TextInput
                                label="Add name of day"
                                value={dayName}
                                onChangeText={name => setDayName(name)}
                                />
                            <Text style={styles.explainerText}>Optionally add name of Day E.g. Leg Day</Text>
                        </View>
                        <Button color="#008080" style={{margin: 30}} onPress={() => showExerciseModalFromDay()}>
                                Add exercise
                        </Button>
                        {exercises.map( (e, i) => (
                            <TouchableOpacity 
                                onPress={() => editExerciseActionFromDay(e)}
                                style={{flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}} 
                            >   
                                <View style={{minWidth: 320}} >
                                    <ExerciseCard exercise={e} />
                                </View>
                                <Button
                                    icon="delete" 
                                    onPress={() => deleteExercise(e)}
                                    color="red"
                                    style={{minHeight: 40, minWidth: 40}}
                                ></Button>
                            </TouchableOpacity>
                        ))}
                        { edit && 
                            <Button color="#008080" icon="camera" mode="contained" onPress={() => saveDayEdit()}>
                                Save Changes
                            </Button>
                        }
                        { !edit && 
                            <Button color="#008080" icon="camera" mode="contained" onPress={() => createNewDay()}>
                                Save Day
                            </Button>
                        }
                    </ScrollView>
                    </Modal>
                </Portal>
            </Provider>

            <Provider>
                <Portal>
                    <Modal visible={exerciseModal} onDismiss={hideExerciseModal} contentContainerStyle={exerciseContainerStyle}>
                        <ScrollView>
                            <View style={styles.modalView}>
                                { editExercise && <Title>Edit exercise</Title>}
                                { !editExercise && <Title>Add exercise</Title>}
                            </View>

                            <View style={styles.modalView}>
                                <Button 
                                    onPress={pickImageWorkout}
                                    mode="contained"
                                    color="#008080"
                                >
                                    Pick an image from camera roll
                                </Button>
                                <Text style={styles.explainerText}>Optionally select photo or video for this exercise *</Text>
                                {exImage ? <Media contentType={exImage.metadata.type} url={exImage.url} component='cover' />  : null}
                            </View>
                            <View style={styles.modalView}>
                                <TextInput
                                    label="Exercise name"
                                    defaultValue={exName}
                                    onChangeText={text => setExName(text)}
                                />
                                <Text style={styles.explainerText}>Name of exercise e.g. Incline Bench Press</Text>
                            </View>
                            <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
                                <Chip style={{margin: 3}} icon="plus" onPress={() => setShowReps(!showReps)}>Add Reps</Chip>
                                <Chip style={{margin: 3}} icon="plus" onPress={() => setShowWeight(!showWeight)}>Add Weight</Chip>
                                <Chip style={{margin: 3}} icon="plus" onPress={() => setShowDistance(!showDistance)}>Add Distance</Chip>
                                <Chip style={{margin: 3}} icon="plus" onPress={() => setShowTime(!showTime)}>Add Time</Chip>
                            </View>
                            <View style={styles.modalView}>
                                {showReps && 
                                    <View style={{marginTop: 20, marginBottom: 20}}>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: 20, marginBottom: 20}}>
                                            <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                                <NumberSelect increase={() => increaseNumberSelect('sets')} decrease={() => decreaseNumberSelect('sets')} count={exSets} handleName='sets' handleWeeks={handleNumberInput} />
                                                <Text>Sets</Text>
                                            </View>
                                            <View>
                                                <Text style={{fontSize: 24}}> X </Text>
                                            </View>
                                            <View style={{flexDirection: 'column', alignItems: 'center', maxWidth: 100}}>
                                                <NumberSelect increase={() => increaseNumberSelect('reps')} decrease={() => decreaseNumberSelect('reps')} count={exReps} handleName='reps' handleWeeks={handleNumberInput} />
                                                <Text>Reps</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.explainerText}>Enter Sets & Reps e.g. 3 x 12</Text>

                                        <View style={{marginTop: 20, marginBottom: 20, flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center',}}>
                                            <Title>Rest: </Title>
                                            <NumberSelect increase={() => increaseNumberSelect('exRest')} decrease={() => decreaseNumberSelect('exRest')} count={exRepsRest} handleName='exRest' handleWeeks={handleNumberInput} />
                                            <Subheading>seconds</Subheading>
                                        </View>
                                        <Text style={styles.explainerText}>Enter rest between sets in seconds if any</Text>
                                    </View>
                                }
                                {showWeight && 
                                    <View style={{marginTop: 10, marginBottom: 10}}>
                                        <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center'}}>
                                            <NumberSelect increase={() => increaseNumberSelect('exWeight')} decrease={() => decreaseNumberSelect('exWeight')} count={exWeight} handleName='exWeight' handleWeeks={handleNumberInput} />
                                            <Picker
                                                style={{width: 100}}
                                                selectedValue={selectedWeight}
                                                onValueChange={(itemValue, itemIndex) =>
                                                    setSelectedWeight(itemValue)
                                                }>
                                                <Picker.Item label="kg" value="kg" />
                                                <Picker.Item label="lbs" value="lbs" />
                                            </Picker>
                                        </View>
                                        <Text style={styles.explainerText}>Enter weight for exercise</Text>
                                    </View>

                                }
                                {showDistance && 
                                    <View style={{marginTop: 10, marginBottom: 10}}>
                                        <View style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: 'space-evenly', alignItems: 'center'}}>
                                            <NumberSelect increase={() => increaseNumberSelect('exDistance')} decrease={() => decreaseNumberSelect('exDistance')} count={exDistance} handleName='exDistance' handleWeeks={handleNumberInput} />
                                            <Picker
                                                style={{width: 100}}
                                                selectedValue={selectedDistance}
                                                onValueChange={(itemValue, itemIndex) =>
                                                    setSelectedDistance(itemValue)
                                                }>
                                                <Picker.Item label="km" value="km" />
                                                <Picker.Item label="mi" value="mi" />
                                            </Picker>
                                        </View>
                                        <Text style={styles.explainerText}>Enter distance for exercise</Text>
                                    </View>
                                }
                                {showTime && 
                                    <View>
                                        <TextInput
                                            style={{width: "100%"}}
                                            label="Time"
                                            placeholder="20 - 30 mins"
                                            value={exTime}
                                            onChangeText={text => setExTime(text)}
                                        />
                                        <Text style={styles.explainerText}>Enter time for exercise e.g. 10mins</Text>
                                    </View>
                                }
                            </View>
                            <View style={styles.modalView}>
                                <TextInput
                                    label="Instructions"
                                    defaultValue={exInstructions}
                                    onChangeText={text => setExInstructions(text)}
                                />
                                <Text style={styles.explainerText}>Optionally enter description of exercise</Text>
                            </View>
                            { editExercise && 
                                <Button mode="contained" color="#008080" style={{flex: 1, marginBottom: 20, marginTop: 10}} onPress={() => saveEditExercise()}>
                                    Save Changes
                                </Button>
                            }
                            { !editExercise && 
                                <Button mode="contained" color="#008080" style={{flex: 1, marginBottom: 20, marginTop: 10}} onPress={() => createNewExercise()}>
                                    Save exercise
                                </Button>
                            }
                        </ScrollView>
                    </Modal>
                </Portal>
            </Provider>

            <Snackbar
                visible={snackbarVisible}
                onDismiss={onDismissSnackBar}
                action={{
                label: 'Gotcha',
                onPress: () => {
                    // Do something
                },
                }}>
                {snackbarMessage}
            </Snackbar>

        </View>
    )
}

const styles = StyleSheet.create({

    container: {
        width: "100%",
        flex: 1
    },
    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
    },
    modalView: {
        marginBottom: 30
    },
    content: {
        marginBottom: 30,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "2%",
        textAlign: 'center'
    },
    editBtns: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        marginBottom: 30,
    },
    dayTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
        marginTop: 15
    },
    dayCtn: {
        marginBottom: 15
    },
    exerciseCtn: {
        marginBottom: 15,
    },
    footer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.1,
    },
});