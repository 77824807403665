import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import { CLEAR_DATA, USERS_DATA_STATE_CHANGE, USER_CHALLENGES_JOINED, USER_CHALLENGES_STATE_CHANGE, USER_FOLLOWING_STATE_CHANGE, USER_STATE_CHANGE, 
        USER_SUBSCRIPTIONS_STATE_CHANGE, USER_WORKOUTS_STATE_CHANGE, USER_WORKOUT_FOLLOWING_STATE_CHANGE, WORKOUTS_USER_FOLLOWING, USER_ONDEMAND_WORKOUT_STATE_CHANGE, 
        USER_ONDEMAND_WORKOUT_SAVED, ON_DEMAND_USER_FOLLOWING, FREE_WORKOUT_TIMER_WORKOUT } from '../constants/index';

export function clearData() {
    return ((dispatch) => {
        dispatch({type: CLEAR_DATA })
    })
}

export function fetchUser() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("users")
            .doc(firebase.auth().currentUser.uid)
            .get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    dispatch({ type: USER_STATE_CHANGE, currentUser: snapshot.data() })
                }
                else {
                }
            })
    })
}

export function fetchUserWorkouts() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("workouts")
            .where("user_id", "==", firebase.auth().currentUser.uid)
            .orderBy("creation", "asc")
            .get()
            .then((snapshot) => {
                let workouts = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                dispatch({ type: USER_WORKOUTS_STATE_CHANGE, workouts: workouts })
            })
    })
}

export function fetchUserOnDemandWorkouts() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("onDemandWorkout")
            .where("user_id", "==", firebase.auth().currentUser.uid)
            .orderBy("creation", "asc")
            .get()
            .then((snapshot) => {
                let onDemandWorkouts = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                dispatch({ type: USER_ONDEMAND_WORKOUT_STATE_CHANGE, onDemandWorkouts: onDemandWorkouts })
            })
    })
}

export function fetchUserFollowing() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("following")
            .doc(firebase.auth().currentUser.uid)
            .collection("userFollowing")
            .onSnapshot((snapshot) => {
                let following = snapshot.docs.map(doc => {
                    const id = doc.id;
                    return id
                })
                dispatch({ type: USER_FOLLOWING_STATE_CHANGE, following: following })
                // WARNING !!!!!!!!!!!!!!!!
                // NOT SCALABLE AT ALL
                // for (let i = 0; i < following.length; i++) {
                //     dispatch(fetchUsersData(following[i], true))
                    
                // }
            })
    })
}

export function fetchUsersData(uid, getPosts){
    return((dispatch, getState) => {
        const found = getState().usersState.users.some(el => el.uid === uid);
        if (!found) {

            firebase.firestore()
            .collection("users")
            .doc(uid)
            .get()
            .then((snapshot) => {
                if (snapshot.exists) {
                    let user = snapshot.data();
                    user.uid = snapshot.id;

                    dispatch({ type: USERS_DATA_STATE_CHANGE, user })
                }
                else {
                }
            })

            if (getPosts) {
                dispatch(fetchUsersFollowingPosts(uid))
            }
            
        }
        
    })
}

export function fetchUserWorkoutsFollowing() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("userWorkouts")
            .doc(firebase.auth().currentUser.uid)
            .collection("following")
            .onSnapshot((snapshot) => {
                let following = snapshot.docs.map(doc => {
                    const id = doc.id;
                    return id
                })
                dispatch({ type: USER_WORKOUT_FOLLOWING_STATE_CHANGE, workoutFollowing: following })
                // Get full following workouts data
                dispatch(fetchUserFollowingWorkouts(following))
            })
    })
}

export function fetchUserChallengesJoined() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("userChallenges")
            .doc(firebase.auth().currentUser.uid)
            .collection("challenges")
            .orderBy("creation", "desc")
            .onSnapshot((snapshot) => {
                let challenges = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                dispatch({ type: USER_CHALLENGES_JOINED, challengesJoined: challenges })
            })
    })
}

export function fetchUserOnDemandWorkoutSaved() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("userOnDemandWorkouts")
            .doc(firebase.auth().currentUser.uid)
            .collection("following")
            .onSnapshot((snapshot) => {
                let saved = snapshot.docs.map(doc => {
                    const id = doc.id;
                    return id
                })
                dispatch({ type: USER_ONDEMAND_WORKOUT_SAVED, ondemandWorkoutSaved: saved })
                // Get full following on demand data
                dispatch(fetchUserFollowingOnDemand(saved))
            })
    })
}

const getUserFollowingOnDemand = async(ids) => {
    // For each of the on demand ids following, get full data
    const reads = ids.map(id => firebase.firestore().collection("onDemandWorkout").doc(id).get() );
    const result = await Promise.all(reads);
    // return result.map(v => v.data())
    return result.map(doc => {
        const data = doc.data();
        const id = doc.id;
        return{id, ...data}
    })
}

export function fetchUserFollowingOnDemand(ids) {
    return ((dispatch, getState) => {
        getUserFollowingOnDemand(ids)
        .then(data => {
            dispatch({ type: ON_DEMAND_USER_FOLLOWING, onDemandUserFollowing: data})
        })
    })
}


export function fetchUserSubscriptions() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("userSubscriptions")
            .doc(firebase.auth().currentUser.uid)
            .collection("subscriptions")
            .onSnapshot((snapshot) => {
                let subs = snapshot.docs.map(doc => {
                    const id = doc.id;
                    return id
                })
                dispatch({ type: USER_SUBSCRIPTIONS_STATE_CHANGE, subscriptions: subs })
            })
    })
}

const getUserFollowingWorkouts = async(ids) => {
    // For each of the workout ids following, get full data
    const reads = ids.map(id => firebase.firestore().collection("workouts").doc(id).get() );
    const result = await Promise.all(reads);
    // return result.map(v => v.data())
    return result.map(doc => {
        const data = doc.data();
        const id = doc.id;
        return{id, ...data}
    })
}

export function fetchUserFollowingWorkouts(ids) {
    return ((dispatch, getState) => {
        getUserFollowingWorkouts(ids)
        .then(data => {
            dispatch({ type: WORKOUTS_USER_FOLLOWING, workoutsUserFollowing: data})
        })
    })
}

export function fetchUserChallenges() {
    return ((dispatch) => {
        firebase.firestore()
            .collection("challenges")
            .where("user_id", "==", firebase.auth().currentUser.uid)
            .orderBy("creation", "asc")
            .get()
            .then((snapshot) => {
                let challenges = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                dispatch({ type: USER_CHALLENGES_STATE_CHANGE, challenges: challenges })
            })
    })
}

export function setFreeWorkoutTimerWorkout(workout) {
    console.log("setFreeWorkoutTimerWorkout11 ", workout)

    // return ((dispatch) => {
    //     console.log("setFreeWorkoutTimerWorkout ", workout)
    //     dispatch({ type: FREE_WORKOUT_TIMER_WORKOUT, freeWorkoutTimerWorkout: workout })
    // })
    return () =>{
        dispatch({ type: FREE_WORKOUT_TIMER_WORKOUT, freeWorkoutTimerWorkout: {"TEST": 1} })
    }

}