import * as Linking from 'expo-linking';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { IconButton } from 'react-native-paper';

const SocialMediaLinks = () => {

  const handleLink = (social) => {
    if (social === 'discord') {
        Linking.openURL("https://discord.gg/fQr85EnJsn");
    } else if (social === 'tiktok') {
        Linking.openURL("https://www.tiktok.com/@move_io");
    } else if (social === 'twitter') {
      Linking.openURL("https://twitter.com/move_app_media");
    } else if (social === 'instagram') {
      Linking.openURL("https://www.instagram.com/move.io");
    } else {
        
    }
};
  return (
    <View style={styles.container}>
      <IconButton
        icon="twitter"
        color="#1DA1F2"
        size={30}
        onPress={() => handleLink('twitter')}
      />
      <IconButton
        icon="instagram"
        color="#E1306C"
        size={30}
        onPress={() => handleLink('instagram')}
      />
      <IconButton
        icon="discord" 
        color="#7289da" 
        size={30} 
        onPress={() => handleLink('discord')}
      />
      {/* <IconButton
        icon="tiktok" // Replace with your desired icon
        color="#000000" // Replace with your desired color
        size={30} // Replace with your desired size
        onPress={() => handleLink('tiktok')}
      /> */}
    </View>
    
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
});

export default SocialMediaLinks;
