exports.SPORTS_CATEGORIES = [
    {
        id: 'gym',
        title: 'Gym',
        icon: 'dumbbell',
    },
    {
        id: 'surfing',
        title: 'Surfing',
        icon: 'surfing',
    },
    {
        id: 'running',
        title: 'Running',
        icon: 'run',
    },
    {
        id: 'cycling',
        title: 'Cycling',
        icon: 'bike',
    },
    {
        id: 'hiking',
        title: 'Hiking',
        icon: 'walk',
    },
    {
        id: 'canoeing',
        title: 'Canoeing',
        icon: 'kayaking',
    },
    {
        id: 'kayaking',
        title: 'Kayaking',
        icon: 'kayaking',
    },
    {
        id: 'kite surfing',
        title: 'Kite Surfing',
        icon: 'kitesurfing',
    },
    {
        id: 'rock climbing',
        title: 'Rock Climbing',
        icon: 'dumbbell',
    },
    {
        id: 'rowing',
        title: 'Rowing',
        icon: 'rowing',
    },
    {
        id: 'skiing',
        title: 'Skiing',
        icon: 'ski',
    },
    {
        id: 'snowboarding',
        title: 'Snowboarding',
        icon: 'snowboard',
    },
    {
        id: 'stand up paddle',
        title: 'Stand Up Paddle',
        icon: 'surfing',
    },
    {
        id: 'swimming',
        title: 'Swimming',
        icon: 'swim',
    },
    {
        id: 'crossfit',
        title: 'Crossfit',
        icon: 'weight-lifter',
    },
    {
        id: 'weight training',
        title: 'Weight Training',
        icon: 'weight-lifter',
    },
    {
        id: 'workout',
        title: 'Workout',
        icon: 'weight-lifter',
    },
    {
        id: 'home-workout',
        title: 'Home Workout',
        icon: 'yoga',
    },
    {
        id: 'yoga',
        title: 'Yoga',
        icon: 'yoga',
    },
    {
        id: 'pilates',
        title: 'Pilates',
        icon: 'yoga',
    },
    {
        id: 'physio',
        title: 'Physio',
        icon: 'yoga',
    },
    {
        id: 'basketball',
        title: 'Basketball',
        icon: 'basketball',
    },
    {
        id: 'football',
        title: 'Football',
        icon: 'soccer',
    },
    {
        id: 'tennis',
        title: 'Tennis',
        icon: 'tennis',
    },
    {
        id: 'boxing',
        title: 'Boxing',
        icon: 'boxing-glove',
    },
    {
        id: 'climbing',
        title: 'Climbing',
        icon: 'dumbbell',
    },
    {
        id: 'cricket',
        title: 'Cricket',
        icon: 'cricket',
    },
    {
        id: 'golf',
        title: 'Golf',
        icon: 'golf',
    },
    {
        id: 'gymnastics',
        title: 'Gymnastics',
        icon: 'yoga',
    },
    {
        id: 'rugby',
        title: 'Rugby',
        icon: 'rugby',
    },
];