import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import React, { useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, View } from 'react-native';
import { Button, Snackbar, Subheading, Text, Title } from 'react-native-paper';

// Todo
// "Some cool things you can do with your workout"
// "Play workout"
// "Share with friends"
// "Create a challenge based off it for yourself or to invite friends to!"

export default function SubmitCreateWorkout({ workout, image, publicWorkout, paid, categories, username, name, description, fetchUserWorkouts, navigation }) {
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [snackbarVisible, setSnackbarVisible] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const onToggleSnackBar = () => setSnackbarVisible(!snackbarVisible);

    const onDismissSnackBar = () => setSnackbarVisible(false);

    const saveWorkout = async() => {
        setLoading(true)
        setErrorMessage(null)
        try {
            setSaving(true)
            // 1. Upload image return image URL
            const coverMedia = await prepareCoverImage()
            // 2. Upload the exercise images
            const updatedWeeksWithImages = await uploadImages()
            // 3. Upload the workout
            const workoutUpload = await uploadWorkout(coverMedia, updatedWeeksWithImages)
        }
        catch (error) {
            setLoading(false)
            setSaving(false)
            console.error(error)
            setErrorMessage("Error saving workout")
            setSnackbarVisible(true)
            setSnackbarMessage('Error saving workout')
        }
    }

    const uploadWorkout = (coverImg, weeks) => {
        firebase.firestore()
        .collection('workouts')
        .add({
            user_id: firebase.auth().currentUser.uid,
            image: coverImg,
            public: publicWorkout,
            paid: paid,
            category: categories,
            by: username,
            name: name,
            description: description,
            weeks: weeks.weeks,
            likesCount: 0,
            creation: firebase.firestore.FieldValue.serverTimestamp()
        }).then((function () {
            fetchUserWorkouts()
            setLoading(false)
            setStep(1)
        }))
        .catch((error) => {
            // Uh-oh, an error occurred!
            setLoading(false)
            setErrorMessage("Error uploading workout")
            setSnackbarVisible(true)
            setSnackbarMessage('error uploading workout')
        });
    }

    const navigateProfile = () => {
        navigation.navigate("Profile", {uid: firebase.auth().currentUser.uid})
    }

    const prepareCoverImage = async () => {
        if (image) {
            const createImg = await prepareImageUpload()
            const coverImg = await uploadCoverImage(createImg)
            const imgUrl = await coverImg.ref.getDownloadURL()
            const coverMedia = {"url": imgUrl, metadata: createImg.metadata}
            return coverMedia
        } else {
            return null
        }
    }

    const prepareImageUpload = async () => {
        // Prepares image for upload
        const uri = image.url
        const childPath = `workoutImage/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath, metadata: image.metadata}
        return coverMedia
    }

    const uploadCoverImage = async(imgData) => {
        const task = firebase
            .storage()
            .ref()
            .child(imgData.childPath)
            .put(imgData.blob)

        task.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return task
    }

    const uploadImages = async() => {
        let updatedWorkout = _.cloneDeep(workout);
        const newWeeks = await uploadExerciseImages(updatedWorkout)
        return newWeeks
    }

    const uploadExerciseImages = async(workArgs) => {
        const work = workArgs
        // Big yucky.
        // For each week in workout
        for (let i = 0; i < work.weeks.length; i++) {
            // For each Day
            for (let j = 0; j < work.weeks[i].days.length; j++) {
                // For each exercise
                for (let e = 0; e < work.weeks[i].days[j].exercises.length; e++) {
                    if (work.weeks[i].days[j].exercises[e].image && work.weeks[i].days[j].exercises[e].image.url.length > 0) {
                        // There is a saved image
                        const createImg = await prepareExerciseImage(work.weeks[i].days[j].exercises[e].image)
                        const uploadExImage = await uploadImage(createImg)
                        const imgUrl = await uploadExImage.ref.getDownloadURL()
                        const exerciseMedia = {"url": imgUrl, metadata: createImg.metadata}
                        work.weeks[i].days[j].exercises[e].image = exerciseMedia
                    }
                }
            }
        }
        return work
    }

    const prepareExerciseImage = async (imagePath) => {
        // Prepares image for upload for exercise image
        const uri = imagePath.url
        const childPath = `workoutImage/${firebase.auth().currentUser.uid}/${Math.random().toString(36)}`
        const response = await fetch(uri);
        const blob = await response.blob()
        const contentType = blob.type
        const coverMedia = {"blob": blob, "childPath": childPath, metadata: imagePath.metadata}
        return coverMedia
    }

    const uploadImage = async(imgData) => {
        // Create a root reference
        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef
                            .child(imgData.childPath)
                            .put(imgData.blob)
        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            console.error(error)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                return downloadURL
            });
        }
        );
        return uploadTask
    }

    return (
        <View>
            {
                loading ? (
                    <View style={styles.createLoading}>
                        <Title style={{marginBottom: 20}}>Creating workout</Title>
                        <Subheading>Uploading lots of images & videos may take a few minutes</Subheading>
                        <Text style={styles.explainerText}>Please don't close screen yet</Text>
                        <ActivityIndicator size="large" color="#008080" />
                    </View>
                ):(
                    <View>
                        {
                            workout !== null ? (
                                {
                                    0: 
                                    <View>
                                        <Button
                                            icon="content-save-outline" 
                                            mode="contained"
                                            onPress={()=> saveWorkout()}
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            Create Workout
                                        </Button>
                                        { errorMessage && <Text style={{marginTop: 10, color: 'red'}}>{errorMessage}</Text>}
                                    </View>
                                    ,
                                1: 
                                    <View style={styles.stepCtn}>
                                            <Image
                                                style={{ width: 100, height: 100, marginBottom: 20 }}
                                                source={require('../../../assets/tick-white-background.png')}
                                                /> 
                                            <Title style={{marginBottom: 20}}>Workout created!</Title>
                                            <Button
                                                mode="contained"
                                                onPress={()=> navigateProfile()}
                                            >See workouts</Button>
                                    </View>
                                    
                                }[step]
                                ):(
                                    <ActivityIndicator size="large" color="#008080" />
                            )}
                    </View>
                )
            }
            <Snackbar
                visible={snackbarVisible}
                onDismiss={onDismissSnackBar}
                action={{
                label: 'Gotcha',
                onPress: () => {
                    // Do something
                },
                }}>
                {snackbarMessage}
            </Snackbar>
        </View>
    )
}

const styles = StyleSheet.create({

    explainerText: {
        marginTop: 5,
        color: "#9e9e9e",
        marginBottom: 20,
        justifyContent: 'center'
    },
    createLoading: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    stepCtn: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
});