export const USER_STATE_CHANGE = 'USER_STATE_CHANGE'
export const USER_POSTS_STATE_CHANGE = 'USER_POSTS_STATE_CHANGE'
export const USER_WORKOUTS_STATE_CHANGE = 'USER_WORKOUTS_STATE_CHANGE'
export const USER_ONDEMAND_WORKOUT_STATE_CHANGE = 'USER_ONDEMAND_WORKOUT_STATE_CHANGE'
export const USER_FOLLOWING_STATE_CHANGE = 'USER_FOLLOWING_STATE_CHANGE'
export const USER_WORKOUT_FOLLOWING_STATE_CHANGE = 'USER_WORKOUT_FOLLOWING_STATE_CHANGE'
export const WORKOUTS_USER_FOLLOWING = 'WORKOUTS_USER_FOLLOWING'
export const USER_CHALLENGES_STATE_CHANGE = 'USER_CHALLENGES_STATE_CHANGE'
export const USER_SUBSCRIPTIONS_STATE_CHANGE = 'USER_SUBSCRIPTIONS_STATE_CHANGE'
export const USER_CHALLENGES_JOINED = 'USER_CHALLENGES_JOINED'
export const USER_ONDEMAND_WORKOUT_SAVED = 'USER_ONDEMAND_WORKOUT_SAVED'
export const ON_DEMAND_USER_FOLLOWING = 'ON_DEMAND_USER_FOLLOWING'
export const FREE_WORKOUT_TIMER_WORKOUT = 'FREE_WORKOUT_TIMER_WORKOUT'


export const USERS_DATA_STATE_CHANGE = 'USERS_DATA_STATE_CHANGE'
export const USERS_POSTS_STATE_CHANGE = 'USERS_POSTS_STATE_CHANGE'
export const USERS_LIKES_STATE_CHANGE = 'USERS_LIKES_STATE_CHANGE'

export const CLEAR_DATA = 'CLEAR_DATA'