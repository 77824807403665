import * as Linking from 'expo-linking';
import React, { useEffect } from 'react';
import { ActivityIndicator, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';
import { WebView } from 'react-native-webview';

export default function CreateStripeAccWebView(props) {

    useEffect(() => {
        (async () => {
            if (Platform.OS === 'web') {
                Linking.openURL(props.route.params.uri).catch(err => console.error('An error occurred', err));
            } else {
            }
        })();

    }, []);
    
    return (
        <View style={styles.container}>
            <ScrollView style={styles.content}>
                <Title style={{marginBottom: 30}}>Please wait. Setting up paid stripe account</Title>
                <ActivityIndicator style={{marginBottom: 30}} size="large" color="#008080"  />
                <WebView 
                    style={styles.container}
                    source={{ uri: props.route.params.uri }}
                />
            </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({ 
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    content: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
}); 