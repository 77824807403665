
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Searchbar, Subheading, Title } from 'react-native-paper';
import ChallengeListCard from '../challenges/ChallengeListCard';
import WorkoutListCard from '../workouts/WorkoutListCard';

export default function Find(props) {

    const [searchQuery, setSearchQuery] = useState('');
    const [category, setCategory] = useState('workouts');
    const [searchResponse, setSearchResponse] = useState(null);
    const [workouts, setWorkouts] = useState(null);
    const [challenges, setChallenges] = useState(null);
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchInitialWorkouts()
    }, [])

    const onChangeSearch = query => (setSearchQuery(query));

    const onCategoryChange = query => {
        // Todo have to reset for now
        setSearchResponse(null)
        setCategory(query)
    };

    const navigateWorkout = (id) => (props.navigation.navigate(
        'Content', {
            screen: 'Workout',
            params: { workoutId: id },
        }
    ))

    const navigateChallenge = (id) => (props.navigation.navigate('Content', {
        screen: 'Challenge',
        params: { challengeId: id },
    }
))

    const navigateUser = (id) => ( props.navigation.navigate('Home', {
        screen: 'Main',
        params: {
            screen: 'Profile',
            params: {
                uid: id,
            },
        },
    }))

    const fetchInitialWorkouts = () => {
        firebase.firestore()
            .collection("workouts")
            .where("public", "==", true)
            .orderBy("creation", "asc")
            .limit(10)
            .get()
            .then((snapshot) => {
                let workoutsData = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setSearchResponse(workoutsData)
                setLoading(false)
            })
    }

    const fetchSearchQuery = () => {
        setLoading(true)
        firebase.firestore()
            .collection(category)
            .where('name', '>=', searchQuery)
            .limit(20)
            .get()
            .then((snapshot) => {
                let resp = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const id = doc.id;
                    return{id, ...data}
                })
                setSearchResponse(resp)
                setLoading(false)
            })
    }

    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ScrollView style={styles.content}>
                <View style={styles.btnCtn}>
                    {
                        category === 'workouts' && 
                        <Button
                            style={styles.btn}
                            // style={{width: 100}}
                            color="#008080" 
                            icon="checkbox-marked-circle"
                            mode="contained"
                            onPress={()=> onCategoryChange('workouts')}
                        >Workouts</Button>
                    }
                    {
                        category !== 'workouts' && 
                        <Button
                            style={styles.btn}
                            icon="checkbox-blank-circle-outline"
                            onPress={()=> onCategoryChange('workouts')}
                        >Workouts</Button>
                    }
                    {
                        category === 'challenges' && 
                        <Button
                            style={styles.btn}
                            color="#008080" 
                            icon="checkbox-marked-circle"
                            mode="contained"
                            onPress={()=> onCategoryChange('challenges')}
                        >Challenges</Button>
                    }
                    {
                        category !== 'challenges' && 
                        <Button
                            style={styles.btn}
                            icon="checkbox-blank-circle-outline"
                            onPress={()=> onCategoryChange('challenges')}
                        >Challenges</Button>
                    }
                    {
                        category === 'users' && 
                        <Button
                            style={styles.btn}
                            color="#008080" 
                            icon="checkbox-marked-circle"
                            mode="contained"
                            onPress={()=> onCategoryChange('users')}
                        >User</Button>
                    }
                    {
                        category !== 'users' && 
                        <Button
                            style={styles.btn}
                            icon="checkbox-blank-circle-outline"
                            onPress={()=> onCategoryChange('users')}
                        >User</Button>
                    }
                </View>
                <View style={styles.searchCtn}>
                    <Searchbar
                        placeholder="Search"
                        onChangeText={onChangeSearch}
                        value={searchQuery}
                        style={{marginBottom: 20}}
                    />
                    <Button
                        mode="contained"
                        onPress={()=> fetchSearchQuery()}
                        disabled={loading}
                        loading={loading}
                    >Search</Button>
                </View>
                {
                    loading ? (
                        <View style={styles.loadingCtn}>
                            <ActivityIndicator size="large" color="#008080" style={{ marginBottom: 20 }} />
                            <Subheading>🏃‍♀️ Running off to get your info. Back soon! </Subheading>
                        </View>
                    ):(
                        <ScrollView>
                            {
                                searchResponse === null ? (
                                    <View>
                                        <Title>😢 Sorry no {category} of '{searchQuery}' found</Title>
                                    </View>
                                ): (
                                    <View>
                                        {
                                            category === 'workouts' && 
                                                <View>
                                                    {
                                                        searchResponse.map((w, idx) => (
                                                            <TouchableOpacity
                                                                onPress={() => navigateWorkout(w.id)}
                                                                style={styles.responseCtn}
                                                            >
                                                                <WorkoutListCard workout={w} />
                                                            </TouchableOpacity>
                                                            )
                                                        )
                                                    }
                                                </View>
                                        }
                                        {
                                            category === 'challenges' && 
                                                <View>
                                                    {
                                                        searchResponse.map((c, idx) => (
                                                            <TouchableOpacity
                                                                onPress={() => navigateChallenge(c.id)}
                                                                style={styles.responseCtn}
                                                            >
                                                                <ChallengeListCard challenge={c} />
                                                            </TouchableOpacity>
                                                            )
                                                        )
                                                    }
                                                </View>
                                        }
                                        {
                                            category === 'users' && 
                                                <View>
                                                    {
                                                        searchResponse.map((l, i) => (
                                                            <TouchableOpacity 
                                                                style={styles.responseCtn}
                                                                onPress={() => navigateUser(l.id)}
                                                            >
                                                                <View style={styles.card}>
                                                                    <Title>{l.name}</Title>
                                                                </View>
                                                            </TouchableOpacity>
                                                            
                                                        ))
                                                    }
                                                </View>
                                        }
                                    </View>
                                )
                            }
                        </ScrollView>
                    )
                }
            </ScrollView>
        </View>
    )
}

const styles = StyleSheet.create({
    content: {
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    btnCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 20
    },
    btn: {
        transform: [{ scaleX: .8 }, { scaleY: .8 }]
    },
    searchCtn: {
        marginBottom: 30
    },
    loadingCtn: {
        marginTop: '25%',
        justifyContent: 'center', 
        alignItems: 'center'
    },
    responseCtn: {
        marginBottom: 20
    },
    card: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "5%",
        justifyContent: "space-between"
    },
})