import { useNavigation, useRoute } from '@react-navigation/native';
import { Video, Audio } from 'expo-av';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Button, Paragraph, Subheading, Title } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchUsersData } from '../../redux/actions';
import Paywall from '../main/Paywall';
import Share from '../share/index';
import { useFocusEffect } from '@react-navigation/native';



function OnDemandWorkout(props) {
    const navigation = useNavigation();
    const route = useRoute();
    const video = React.useRef(null);
    const [workout, setWorkout] = useState(null)
    const [subscribedWorkout, setSubscribedWorkout] = useState(true)
    const [paidData, setPaidData] = useState(null)
    const [usersWorkout, setUsersWorkout] = useState(false)
    const [following, setFollowing] = useState(false)
    const [liked, setLiked] = useState(false)
    const [imageLoading, setImageLoading] = useState(true)
    const [url, setUrl] = useState(true)
    const [playVideo, setPlayVideo] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {

        if (props.route.params) {

            // Needed for video audio to still play in silent mode IOS
            Audio.setAudioModeAsync({
                playsInSilentModeIOS: true,
            });

            // Need to check if route is there first
            if(props.ondemandWorkoutSaved.indexOf(props.route.params.workoutId) > -1 ){
                setFollowing(true)
            } else {
                setFollowing(false)
            }
    
            firebase.firestore()
                .collection("onDemandWorkout")
                .doc(props.route.params.workoutId)
                .get()
                .then((snapshot) => {
                    const workoutData = snapshot.data()
                    checkIfPremiumContent(workoutData)
                    setWorkout(workoutData)
                })
        }

    }, [props.ondemandWorkoutSaved, props.subscriptions, props.currentUser, props.route])

    useFocusEffect(() => {
        return () => {
            if (isPlaying) {
            video.current.pauseAsync();
            }
        };
        });

    const checkIfPremiumContent = (workoutData) => {
        // 1. Check if user exsists
        if (props.currentUser) {
            // 2. Check if users workout
            if (workoutData.user_id === firebase.auth().currentUser.uid) {
                setUsersWorkout(true)
                setFollowing(true)
                setPlayVideo(true)
            } else {
                // Not users workout
                // 3. Check if paid workout
                if (workoutData.paid) {
                    // 4. Check if subscribed
                    if (props.subscriptions.indexOf(workoutData.user_id) > -1 ) {
                        // The current user is subscribed to this premium content
                        setSubscribedWorkout(true)
                        setPlayVideo(true)
                    } else {
                        // the user is not subscribed
                        // Get the subscription data
                        setSubscribedWorkout(false)
                        getPaidData(workoutData.user_id)
                    }
                } else {
                    // Show non premium content
                    setSubscribedWorkout(true)
                    setPlayVideo(true)
                }
            }
        } else {
            // 5. Check if paid workout
            if (workoutData.paid) {
                setSubscribedWorkout(false)
                getPaidData(workoutData.user_id)
            } else {
                // Show non premium content
                setSubscribedWorkout(true)
                setPlayVideo(true)
            }
        }
    }

    const getPaidData = (user_id) => {
        firebase.firestore()
            .collection("userStripeAccount")
            .doc(user_id)
            .get()
            .then((snapshot) => {
                setPaidData(snapshot.data())
            })
            .catch(error => {
                console.error(error)
            })
    }

    const onFollow = () => {
        const { currentUser  } = props;
        if (!currentUser) {
            navigation.navigate("Home", {
                screen: 'Login'
            })
        } else {
            firebase.firestore()
            .collection("userOnDemandWorkouts")
            .doc(firebase.auth().currentUser.uid)
            .collection("following")
            .doc(props.route.params.workoutId)
            .set({})
            .then((docRef) => {
                // Fetch the ondemand user is following
                // fetchuserOnDemandWorkouts()
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }
    
    const onUnfollow = () => {
        firebase.firestore()
        .collection("userOnDemandWorkouts")
        .doc(firebase.auth().currentUser.uid)
        .collection("following")
        .doc(props.route.params.workoutId)
        .delete()
    }

    const handleSubscribe = () => {
        navigation.navigate('Home', {
            screen: 'Main',
            params: {
                screen: 'Profile',
                params: { uid: workout.user_id },
            },
        });
    }

    const navigateUserProfile = () => {
        video.current.pauseAsync();
        navigation.navigate('Home', {
            screen: 'Main',
            params: {
                screen: 'Profile',
                params: {
                uid: workout.user_id,
                },
            },
        })
    }


    return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            { workout !== null ? (
                <ScrollView style={styles.container}>
                    <View>
                        <Video
                            ref={video}
                            source={{uri: `https://stream.mux.com/${workout.playback_id}.m3u8`,}}
                            useNativeControls={playVideo}
                            resizeMode={Video.RESIZE_MODE_CONTAIN}
                            style={{ maxWidth: Dimensions.get('window').width * 1, height: Dimensions.get('window').height * 0.3}}
                            shouldPlay={playVideo}
                            onPlaybackStatusUpdate={(status) => {
                                setIsPlaying(status.isPlaying);
                            }}
                        />
                    </View>
                    <View>
                        {
                            !subscribedWorkout ? (
                                <View style={styles.subscribeBtn}>
                                    {paidData ? (
                                        <Button
                                            mode="contained"
                                            onPress={() => handleSubscribe()}
                                        >
                                            Subscribe to play {paidData.currency_symbol} {paidData.price} per month
                                        </Button>
                                    ):(
                                        <Button
                                            mode="contained"
                                            loading={true}
                                            disabled={true}
                                        >
                                            Subscribe
                                        </Button>
                                    )}
                                </View>
                            ):(
                                null
                            )
                        }
                        <Title style={{marginBottom: 20}}>{workout.title}</Title>
                        <TouchableOpacity
                            style={{marginBottom: 20}}
                            onPress={navigateUserProfile}
                        >
                            <Subheading style={{color: "#9e9e9e"}}>Created by {workout.by}</Subheading>
                        </TouchableOpacity>
                        <View style={styles.statsCtn}>
                            <View style={styles.iconRow}>
                                { following ? (
                                    <Button
                                        icon="playlist-check"
                                        mode="outlined"
                                        color="#008080" 
                                        onPress={() => onUnfollow()}
                                    >Saved</Button>
                                ) : (
                                    <Button
                                        icon="playlist-check"
                                        mode="contained"  
                                        onPress={() => onFollow()}
                                    >Save</Button>
                                )}
                                <TouchableOpacity
                                    style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginRight: 5}}
                                >
                                    <Share
                                        params={`/on-demand-workout?workoutId=${props.route.params.workoutId}`}
                                        message={"Check out this Workout"}
                                        title={`${workout.title} by ${workout.by}`}
                                        image={null}   
                                    />
                                    <Subheading>Share</Subheading>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={styles.description}>
                            <Paragraph>{workout.description}</Paragraph>
                        </View>
                    </View>

                    <View style={styles.exerciseSection}>
                        { subscribedWorkout ? (
                            null
                        ):(
                            <View>
                                <Paywall 
                                    creatorName={workout.by} 
                                    workoutPrice={paidData} 
                                    uid={workout.user_id} 
                                    navigation={navigation} 
                                />
                            </View>
                        )}
                    </View>
                </ScrollView>
            ):(
                <View style={styles.loadingCtn}>
                    <ActivityIndicator size="large" color="#008080" />
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 700,
        width: "100%",
        padding: 10
    },
    infoContainer: {
        backgroundColor: 'white',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        padding: 15
    },
    statsCtn: {
        marginBottom: 20
    },
    iconRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
    },
    description: {
        marginTop: 15
    },
    exerciseCtn: {
        marginBottom: 15,
    },
    dayTitle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    loadingCtn: {
        width: '90%',
        height: '90%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    exerciseSection: {
        marginTop: 15,
        padding: 15
    },
    dayCtn: {
        marginBottom: 15
    },
    mediaCtn: {
        maxWidth: Dimensions.get('window').width * 1,
        height: "auto",
        justifyContent: 'center'
    },
    subscribeBtn: {
        marginTop: 15,
        marginBottom: 15,
    }
})

const mapStateToProps = (store) => ({
    users: store.usersState.users,
    ondemandWorkoutSaved: store.userState.ondemandWorkoutSaved,
    currentUser: store.userState.currentUser,
    subscriptions: store.userState.subscriptions,
    workouts: store.userState.workouts,
})
const mapDispatchProps = (dispatch) => bindActionCreators({ fetchUsersData }, dispatch);

export default connect(mapStateToProps, mapDispatchProps)(OnDemandWorkout);
