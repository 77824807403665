import * as Analytics from 'expo-firebase-analytics';
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Subheading, Title } from 'react-native-paper';
import AppStoreBadges from '../appstoreBadge';
import BlogCard from './BlogCard';

const { width, height } = Dimensions.get('window');

let fontSize = 20; // default font size

// adjust font size based on screen width
if (width < 400) {
    fontSize = 28;
} else if (width >= 400 && width < 600) {
    fontSize = 30;
} else if (width >= 600 && width < 850) {
    fontSize = 32;
} else {
    fontSize = 40;
}

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect( () => {
    (async() => {
      await Analytics.logEvent('[Blogs]: Viewed');
    })();
    getBlogs()
  }, []);

  const getBlogs = async() => {
    const db = getFirestore();
    const q = query(collection(db, "blogs"), where("active", "==", true), limit(25));
    const querySnapshot = await getDocs(q);
    let resp = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const id = doc.id;
      return{id, ...data}
    })
    setBlogs(resp);
    setLoading(false);
  }

  return (

    <ScrollView>
    <View style={styles.root}>
        <View style={styles.setCtnWidth}>
          <View style={styles.sectionCtn}>
            <Title style={{fontWeight: "bold", fontSize: fontSize, textAlign: 'center', marginBottom: 30}}>Move Fitness Blog</Title>
          </View>
          <View style={styles.sectionCtn}>
            <View style={[Dimensions.get('window').width > 800 ? styles.blogsCtnDesktop : styles.blogsCtn]}>
              {loading ? (
                <View style={styles.loadingCtn}>
                  <ActivityIndicator size="large" color="#008080" />
                </View>
              ) : (
                blogs.map((blog, index) => (
                  <View style={styles.blogCtn} key={index}>
                    <BlogCard blog={blog} />
                    {/* <Title>{blog.title}</Title> */}
                  </View>
                ))
              )}
            </View>
          </View>
          <View style={styles.sectionCtn}>
            <View style={styles.footer}>
              <View style={styles.footerCtn}>
                  <View style={{marginBottom: 30, marginTop: 30}}>
                      <Title style={{textAlign: 'center'}}>Made with ❤️ by 
                          <Title> </Title>
                          <Title
                              onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                              style={{textDecorationLine: 'underline', textAlign: 'center'}}
                          >Move Fitness App</Title>
                      </Title>
                  </View>
                  <View style={{marginBottom: 15}}>
                      <Subheading style={{textAlign: 'center'}}>Easiest subscription platform for fitness creators and businesses</Subheading>
                  </View>
                  <View style={{marginBottom: 15}}>
                      <Subheading style={{textAlign: 'center'}}>Workouts • Challenges • On Demand videos</Subheading>
                  </View>
                  <View style={{marginBottom: 30, marginTop: 30}}>
                      <AppStoreBadges />
                  </View>
              </View>
            </View>
          </View>
          <View style={styles.sectionCtn}>
            <AppStoreBadges />
          </View>
        </View>
    </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
},
setCtnWidth: {
    maxWidth: 1200,
    width: "100%",
    padding: 20,
    
},
sectionCtn: {
  marginTop: 40,
  marginBottom: 40,
},
divider: {
    marginTop: 100,
    marginBottom: 100
},
sectionHeaderDesktop: {
    marginTop: 30,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
},
headlineTitle: {
    fontSize: 30,
    marginBottom: 30,
},
headlineTitleDesktop: {
    fontSize: 50,
    marginBottom: 35,
},
  titleCtn: {
    background: 'white',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: Dimensions.get('window').height * 0.3,

  },
  blogsCtnDesktop: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  blogsCtn: {
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  blogCtn: {
    margin: 20
  },
  footer: {
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: 'white',
    backgroundColor: "#E8ECF2",
},
footerCtn: {
    maxWidth: Dimensions.get('window').width * 0.75,
    flexDirection: 'column',
    alignItems: 'center',
},
  loadingCtn: {
    height: Dimensions.get('window').height * 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
})
