import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView } from 'react-native';
import { Button, Subheading, Title } from 'react-native-paper';
import { connect } from 'react-redux';

function Add(props) {

    const handleAdd = (route) => {

        if (!props.currentUser) {
            props.navigation.navigate("Login")
        } else {
            if (route === 'workout') {
                props.navigation.navigate('Content', {screen: 'New Workout'})
            } else if (route === 'demand') {
                props.navigation.navigate('Content', {screen: 'New Ondemand'})
            } else {
                props.navigation.navigate('Content', {screen: 'New Challenge'})
            }
        }
    }

    return (
    <ScrollView style={styles.container}>
        <View style={styles.title}>
            <Title style={{ textAlign: "center"}}>Create New</Title>
        </View>
        <View style={styles.btnGroupCtn}>
            <TouchableOpacity 
                style={styles.addCtn}
                onPress={() => handleAdd('demand')}
            >
                <Image
                    style={{ width: 50, height: 50, marginBottom: 5 }}
                    source={require('../../assets/addIcons/add-ondemandworkout.png')}
                />
                <Subheading style={{ marginBottom: 5}}>OnDemand</Subheading> 
                <Subheading>Workout</Subheading> 
            </TouchableOpacity>
            <TouchableOpacity 
                style={styles.addCtn}
                onPress={() => handleAdd('challenge')}
            >
                <Image
                    style={{ width: 50, height: 50, marginBottom: 5 }}
                    source={require('../../assets/addIcons/add-challenge.png')}
                />
                <Subheading>Challenge</Subheading> 
            </TouchableOpacity>
            <TouchableOpacity 
                style={styles.addCtn}
                onPress={() => handleAdd('workout')}
            >
                <Image
                    style={{ width: 50, height: 50, marginBottom: 5 }}
                    source={require('../../assets/addIcons/add-workout-program.png')}
                />
                <Subheading style={{ marginBottom: 5}}>Workout</Subheading> 
                <Subheading>Program</Subheading> 
            </TouchableOpacity>
        </View>
    </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {

    },
    btnGroupCtn: {
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap"
    },
    addCtn: {
        backgroundColor: 'white',
        height: 185,
        width: 185,
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        padding: 10,
    },
    title: {
        marginTop: 20,
        marginBottom: 20
    }
})


const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
})


export default connect(mapStateToProps)(Add);
