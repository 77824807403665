import * as Analytics from 'expo-firebase-analytics';
import { Image } from 'expo-image';
import { useEffect } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Divider, Subheading, Text, Title } from 'react-native-paper';
import CalculateEarning from "../landing/CalculateEarnings";
import MoveComparisonTable from "../Marketing/MoveComparisonTable";
import SocialMediaLinks from "../Socials/SocialMediaLinks";

const blurhash =
    '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

const { width, height } = Dimensions.get('window');

  let fontSize = 20; // default font size

    // adjust font size based on screen width
    if (width < 400) {
        fontSize = 28;
    } else if (width >= 400 && width < 600) {
        fontSize = 30;
    } else if (width >= 600 && width < 850) {
        fontSize = 32;
    } else {
        fontSize = 40;
    }

export default function Pricing(props) {

    useEffect(() => {
        (async() => {
            await Analytics.logEvent(`[Pricing]: viewed`);
        })();
    }, []);

    return (
        <ScrollView>
            <View style={styles.root}>
                <View style={styles.setCtnWidth}>
                    <View style={styles.imgCtn}>
                        <Image
                            style={styles.image}
                            source={require('../../assets/moveIcons/move_icon_teal_bground.png')}
                            placeholder={blurhash}
                            contentFit="cover"
                            transition={1000}
                        />
                    </View>
                    <View style={styles.sectionCtn}>
                        <Title style={{fontWeight: "bold", fontSize: fontSize, textAlign: 'center', marginBottom: 60}}>Simple Pricing</Title>
                        <Title style={{fontWeight: "bold", fontSize: fontSize, textAlign: 'center', marginBottom: 40}}>Creators keep 80%.</Title>
                        <Text style={{textAlign: 'center'}}>We don't make a cent until you make money</Text>
                    </View>
                    <Divider style={styles.divider} />
                    {/* Todo
                    <View style={styles.sectionCtn}>
                        <Title style={{fontWeight: "bold", fontSize: "x-large"}}>How it compares</Title>
                        <Subheading style={{fontWeight: "bold", fontSize: "x-large"}}>Cameo</Subheading>
                    </View> */}
                    <View style={styles.sectionCtn}>
                        <Title style={{fontWeight: "bold", fontSize: fontSize, textAlign: 'center', marginBottom: 40}}>Why use Move</Title>
                        <MoveComparisonTable />
                    </View>
                    <Divider style={styles.divider} />
                    <View style={styles.sectionCtn}>
                        <Title style={{fontWeight: "bold", fontSize: fontSize, textAlign: 'center', marginBottom: 40}}>How much could I earn?</Title>
                        <CalculateEarning navigation={props.navigation} />
                    </View>
                    <Divider style={styles.divider} />
                    <View style={styles.marketing}>
                        
                        <Title style={{marginBottom: 30, fontSize: fontSize, textAlign: 'center'}}>Looking to make more money selling fitness content online?</Title>
                        <Subheading style={{marginBottom: 30, textAlign: 'center'}} >Move Fitness is a free online fitness content marketplace for fitness creators. Earn more online and grow your audience with Move</Subheading>
                        <Button 
                        onPress={() => props.navigation.navigate("SellFitnessContentOnline")}
                        mode="contained"
                        style={{marginBottom: 30}}
                        >
                            Find out more
                        </Button>
                    </View>
                    <View style={styles.socials}>
                        <SocialMediaLinks />
                    </View>
                </View>
            </View>
        </ScrollView>    
    )
}

const styles = StyleSheet.create({
    root: {
        backgroundColor: 'white',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    setCtnWidth: {
        maxWidth: 800,
        width: "100%",
        padding: 20,
        height: '100%'
        
    },
    divider: {
        marginTop: 100,
        marginBottom: 100
    },
    sectionCtn: {
        marginTop: 40,
        marginBottom: 40,
    },
    text: {
        fontWeight: "bold", 
        fontSize: fontSize, 
        textAlign: 'center'
    },
    imgCtn: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 40,
        marginBottom: 40,
    },
    image: {
        width: '100%',
        width: 200,
        height: 200,
    },
    marketing: {
        marginTop: 30,
        marginBottom: 30,
    },
    socials: {
        marginTop: 30,
        marginBottom: 30,
    },

})
