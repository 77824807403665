import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import DefaultImage from './defaultImage'
import Photo from './photo'
import Video from './video'

export default function media({ contentType, url, component}) {
    const [type, setType] = useState(null);

    useEffect(() => {
        
        const imagePattern = new RegExp(/image/i)
        const videoPattern = new RegExp(/video/i)

        try {
            if (imagePattern.test(contentType)) {
                setType('image')
            } else if (videoPattern.test(contentType)) {
                setType('video')
            } else {
                setType('error')
            }
        } catch (error) {
            setType('error')
        }
    }, [])


    return (
        <View style={styles.container}>
            {type === 'image' && <Photo url={url} component={component} />}
            {type === 'video' && <Video url={url} component={component} />}
            {type === 'error' && <DefaultImage component={component} />}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        
    }
})
