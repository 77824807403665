import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Text, TextInput, Title } from 'react-native-paper';

export default function CreateWorkoutDescription({ description, setDescription }) {
    return (
        <View>
            <Title>Workout Description? *</Title>
            <Text style={styles.explainerText}>Optionally add description of workout</Text>
            <TextInput
                value={description}
                onChangeText={text => setDescription(text)}
                multiline={true}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    explainerText: {
        marginTop: 5,
        marginBottom: 10,
        color: "#9e9e9e",
    },
});