import { Video } from 'expo-av';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Title } from 'react-native-paper';

export default function OnDemandWorkoutCard({workout}) {
    const video = React.useRef(null);

    return (
        <View style={styles.container}>
            <View style={styles.image}>
                <Video
                    ref={video}
                    source={{uri: `https://stream.mux.com/${workout.playback_id}.m3u8`,}}
                    useNativeControls
                    shouldPlay={false}
                    // resizeMode={Video.RESIZE_MODE_CONTAIN}
                    resizeMode={Video.RESIZE_MODE_COVER}
                    style={{ minWidth: 100, minHeight: 100, maxHeight: 100, maxWidth: 100 }}
                />
            </View>
            <View style={styles.name}>
                <Title>{workout.title}</Title>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: "center",
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "2%"
    },
    name: {
        flex: 2
    },
    image: {
        flex: 1,
    },
})
