import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import PlatformSpecificSubscribeComponent from './PlatformSpecificSubscribeComponent'

function Subscribe(props) {

    useEffect(() => {

    }, [])

    return (
        <View style={styles.container}>
            {props.route.params ? (
                <PlatformSpecificSubscribeComponent
                    creator={props.route.params.creator}
                    subscribed_user_uid={props.route.params.subscribed_user_uid}
                    subscribing_user_uid={props.route.params.subscribing_user_uid}
                    creator_uid={props.route.params.creator_uid}
                    sub_status={props.route.params.sub_status}
                    params={props.route.params}
                />
            ): null}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    content: {
        padding: "5%",
        maxWidth: 600,
        width: "100%",
        padding: 20
    },
    subCtn: {
        minHeight: "50%",
        backgroundColor: 'white',
        padding: '5%',
        flexDirection: "column",
        justifyContent: "space-around",
        borderRadius: 10,
        width: "90%"
    },
    subFailed: {
        padding: "5%"
    },
    subSuccess: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }
})

const mapStateToProps = (store) => ({
    currentUser: store.userState.currentUser,
    following: store.userState.following,
    workouts: store.userState.workouts,
    challenges: store.userState.challenges,
    subscriptions: store.userState.subscriptions,
})

export default connect(mapStateToProps, null)(Subscribe);